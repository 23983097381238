import { IFilter } from '@/types/assets';

export const usageFilters:IFilter[] = [
	{
		category: 'usage',
		filter: 'In use',
	},
	{
		category: 'usage',
		filter: 'In stock',
	},
	{
		category: 'usage',
		filter: 'Spare',
	},
	{
		category: 'usage',
		filter: 'No stock',
	},
];