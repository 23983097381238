import React, { PropsWithChildren } from 'react';
import * as Tabs from '@radix-ui/react-tabs';
import { AssetType } from '@/types/assets';

interface CustomTabsProps {
	tabsOptions: {
		option: string,
		icon?: JSX.Element
	}[];
	onTabSelect: (value: AssetType) => void;
	selectedTab: string;
}

const CustomTabs: React.FC<PropsWithChildren<CustomTabsProps>> = ({
	tabsOptions,
	children,
	onTabSelect,
	selectedTab
}) => {
	const defaultValue = tabsOptions[0].option;

	const tabsListContent = tabsOptions.map((tabsOption, index) => (
		<Tabs.Trigger
			key={tabsOption.option+index}
			className="inline-flex border border-b-0 ml-1.5 p-2 border-border rounded-t-lg text-xs tracking-wide bg-blue text-white hover:bg-grey hover:text-blue focus:bg-white focus:text-blue data-[state=active]:bg-white data-[state=active]:text-blue"
			value={tabsOption.option}
		>
			{tabsOption.icon?tabsOption.icon:''}
			{tabsOption.option}
		</Tabs.Trigger>
	));
	const tabsContent = tabsOptions.map((tabsOption, index) => (
		<Tabs.Content key={tabsOption.option+index} value={tabsOption.option}></Tabs.Content>
	));

	return (
		<>
			<Tabs.Root
				defaultValue={defaultValue}
				onValueChange={(newValue) => onTabSelect(newValue as AssetType)}
				value={selectedTab}
			>
				<Tabs.List className="flex shrink-0" aria-label="Manage your content">
					{tabsListContent}
				</Tabs.List>
				{tabsContent}
			</Tabs.Root>
			{children}
		</>
	);
};

export default CustomTabs;
