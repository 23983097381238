
import { useSelectedPathText } from '@/lib/useSelectedPathText';
import WhiteContainer from "@/components/ui/WhiteContainer"

const Benchmark = () => {

	const selectedPathTitle = useSelectedPathText();

	return (
		<div className="max-h-[calc(100%-2rem)] w-full py-4">
			<h1 className="mb-6 text-blue capitalize text-2xl tracking-tight">{selectedPathTitle}</h1>
			<WhiteContainer styles="h-full box-border p-7">
				<WhiteContainer styles="flex-1 w-full overflow-y-auto h-auto min-h-[33vh] max-h-[calc(100%-.4rem)] box-border p-6">
					<h2 className="text-lg font-semibold tracking-wide text-content-grey mt-1 mb-5">{'Benchmark'}</h2>
				</WhiteContainer>
			</WhiteContainer>
		</div>
	)
}
export default Benchmark;