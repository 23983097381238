import React, { useState } from 'react';
import { ChevronDown, ChevronRight } from 'lucide-react';
import { Checkbox } from '@/components/shadcn-ui/checkbox';
import { IFilter } from '@/types/assets';

// interface Option {
//     id: number;
//     name: string | null;
//     description: string | null;
// }

interface FilterOptionProps {
	option: IFilter;
	isChecked: boolean;
	handleSelect: (item:IFilter) => void;
}

const FilterOption = ( {option, isChecked, handleSelect } : FilterOptionProps ) => {
	return (
		<div className="flex items-center space-x-2">
			<Checkbox
				id={option.filter || undefined}
				className="data-[state=checked]:bg-dark-blue"
				checked={isChecked}
				onCheckedChange={() => handleSelect(option)}
			/>
			<label
				htmlFor={option.filter || undefined}
				className="text-sm font-normal leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70  cursor-pointer"
			>
				{option.filter}
			</label>
		</div>
	);
}

interface FilterProps {
	category: string;
	items: IFilter[];
	selectedItems: IFilter[];
	handleSelect: (item:IFilter) => void;
}

const Filter = ( {category, items, selectedItems, handleSelect} : FilterProps ) => {
	const [ close, setClose ] = useState(false);


	return (
		<>
			<div
			className='flex items-center mt-6 space-x-2 py-2 text-dark-blue cursor-pointer'
			onClick={() => setClose(!close)}>
				{ close ?
				<ChevronRight className='h-4 w-4' /> :
				<ChevronDown className='h-4 w-4' /> }
				<p className="text-base font-medium">
					{category}
				</p>
			</div>
			{close ?
				null :
				<div className='flex flex-col space-y-2 pl-6'>
				{
					items.map((item, index) => {
						const selItemIndex = selectedItems.findIndex( selItem => selItem.filter === item.filter );
						const isChecked = selItemIndex > -1;
						return (
							<FilterOption
								key={`${item.filter}`}
								option={item}
								isChecked={ isChecked }
								handleSelect={handleSelect}
							/>
						)
					})
				}
				</div>
			}
		</>
	);
}

export default Filter;