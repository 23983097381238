const audits: any = {
	"data": [
		{
			"id": 1,
		},
		{
			"id": 2,
		},
		{
			"id": 3,
		},
		{
			"id": 4,
		},

	],
	"count": 4,
	"success": true,
	"message": "Successfully retreived assets."
}

export default audits;