import { useEffect, useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { RouterProvider, createHashRouter } from 'react-router-dom';

import api from '@/middleware/api';

import { companiesAtom } from './state/companies';
import { companiesTreeStructuresAtom } from './state/hierarchy-tree';
import { Company, SiteStructure, TreeStructureWebResponse } from './middleware/GeneratedClient';
import { CompanyTreeStructures } from './types/tree-structure';
import { buildLocationNodesStructurePerCompany } from './lib/tree-utils';

import Login from '@/pages/Login';
import DashboardRoot from '@/pages/DashboardRoot';
import Register from '@/pages/Register';
import Terms from '@/pages/Terms';
import Privacy from '@/pages/Privacy';

import Vulnerabilities from '@/pages/dashboard-outlet/Vulnerabilities';
import Assets from '@/pages/dashboard-outlet/Assets';
import Risks from '@/pages/dashboard-outlet/Risks';
import Benchmark from '@/pages/dashboard-outlet/Benchmark';
import OTCommunity from '@/pages/dashboard-outlet/OTCommunity';
import Recovery from '@/pages/dashboard-outlet/Recovery';
import ICTExpertise from '@/pages/dashboard-outlet/ICTExpertise';
import Ingestion from '@/pages/Ingestion';
import AuditsList from '@/pages/ingestion-outlet/AuditsList';
import Survey from '@/pages/Survey';
import { ProtectedRoute } from './ProtectedRoute';
import { useIsAuthenticated } from '@azure/msal-react';

const router = createHashRouter([
	{
		path: '/',
		index: true,
		element: <Login />,
		errorElement: <Login />,
	},
	{
		path: '/ingestion',
		element: <ProtectedRoute><Ingestion /></ProtectedRoute>,
		children: [
			{
				path: 'audit/:auditId',
				element: <AuditsList />,
				index: true,
			},
			{
				path: 'audit/:auditId/survey/:surveyId',
				element: <Survey />
			}
		],
	},
	{
		path: '/dashboard',
		element: <ProtectedRoute><DashboardRoot /></ProtectedRoute>,
		children: [
			{
				path: 'vulnerabilities',
				element: <Vulnerabilities />,
				index: true,
			},
			{
				path: 'assets',
				element: <Assets />,
			},
			{
				path: 'risks',
				element: <Risks />,
			},
			{
				path: 'benchmark',
				element: <Benchmark />,
			},
			{
				path: 'ot-community',
				element: <OTCommunity />,
			},
			{
				path: 'recovery',
				element: <Recovery />,
			},
			{
				path: 'ict-expertise',
				element: <ICTExpertise />,
			},
		],
	},
	{
		path: '/register',
		element: <Register />,
	},
	{
		path: '/terms-of-service',
		element: <Terms />,
	},
	{
		path: '/privacy-policy',
		element: <Privacy />,
	},
	{
		path: '/unauthorized',
		element: <p>401: Not authorized. Trying to log in.</p>
	}
]);

function Router() {
	const setCompanies = useSetRecoilState( companiesAtom );
	const setCompaniesTreeStructures = useSetRecoilState( companiesTreeStructuresAtom );

	const isAuthenticated = useIsAuthenticated();

	const fetchAllCompaniesTrees = useCallback( async ( promises:Promise<TreeStructureWebResponse>[] ) => {
		let companyTreeStructures:CompanyTreeStructures[] = [];
		try {
			(await Promise.allSettled(promises)).forEach( (result) => {
				let data:SiteStructure[] = [];
				if ( result.status === 'fulfilled' && result.value.data?.sitesStructure ) {	
					data = result.value.data.sitesStructure;
					// console.log( `result.value ${index}`, data );

					const companyStructure = buildLocationNodesStructurePerCompany(data);
					companyTreeStructures = [ ...companyTreeStructures, companyStructure ];
				}	
			} );
		}
		catch ( error ) {
			console.log( 'promise.allSettled error', error );
		}
		finally {
			return companyTreeStructures;
		}
	}, []);

	useEffect( () => { 
		const fetchCompanies = async () => {
			let companies:Company[] = [];
			let companyTreeStructures:CompanyTreeStructures[] = [];

			try {
				let data = (await api.companies()).data;		
				if ( data ) {
					companies = [ ...data ];
					let treeStructurePromises:Promise<TreeStructureWebResponse>[] = [];		
					data.forEach( (company) => {
						if ( company.id ) {
							treeStructurePromises.push(api.getTreeStructure(company.id));
						}
					});

					companyTreeStructures = await fetchAllCompaniesTrees(treeStructurePromises);
				}
			}
			catch ( error ) {
				console.log( 'Could not fetch companies', error );
			}
			finally {
				setCompanies( companies );
				setCompaniesTreeStructures( companyTreeStructures );
			}	
		}
		if (isAuthenticated) {
			fetchCompanies();
		}
	}, [setCompanies, setCompaniesTreeStructures, fetchAllCompaniesTrees, isAuthenticated]);

	return (
		<RouterProvider router={router} />
	);
}

export default Router;
