import React, { useState } from 'react';
import { Label } from '@/components/shadcn-ui/label';
import { Input } from '@/components/shadcn-ui/input';
import { Checkbox } from '@/components/shadcn-ui/checkbox';
import { Slider } from '@/components/shadcn-ui/slider';
import { Play } from 'lucide-react';

const VulnerabilitiesFilter = () => {
	const [range, setRange] = useState([0, 7]);
	return (
		<div className="mx-3 mt-4 mb-2 py-6 overflow-hidden h-auto w-[200px] min-w-[200px] border-[2px] border-grey rounded-md p-2">
			<h3 className="text-lg font-semibold leading-none tracking-tight text-content-grey w-full pb-6">
				{'Action overview'}
			</h3>
			<Label
				className="text-lg font-semibold leading-none tracking-tight text-content-grey w-full mb-2"
				htmlFor="filters"
			>
				{'Filters'}
			</Label>
			<Input
				id="filters"
				className="border-2 mb-4 w-[140px] italic"
				placeholder="find vulnerability"
			/>
			<Play size={16} className="inline rotate-90 text-blue fill-current" />
			<Label
				className="text-lg font-semibold leading-none tracking-tight text-content-blue w-full mb-2 ml-2"
				htmlFor="filters"
			>
				{'action'}
			</Label>
			<div className="flex flex-col space-y-2 space-x-2  mb-4">
				<div className="pl-2 flex flex-row items-center space-x-2">
					<Checkbox id="all" className="rounded-none" />
					<label
						htmlFor="all"
						className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
					>
						{'All'}
					</label>
				</div>
				<div className="flex flex-row items-center space-x-2">
					<Checkbox id="undecided" className="rounded-none" />
					<label
						htmlFor="undecided"
						className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
					>
						{'Undecided'}
					</label>
				</div>
				<div className="flex flex-row items-center space-x-2">
					<Checkbox id="Action required" className="rounded-none" />
					<label
						htmlFor="Action required"
						className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
					>
						{'Action required'}
					</label>
				</div>
				<div className="flex flex-row items-center space-x-2">
					<Checkbox id="dismissed" className="rounded-none" />
					<label
						htmlFor="dismissed"
						className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
					>
						{'Dismissed'}
					</label>
				</div>
				<div className="flex flex-row items-center space-x-2">
					<Checkbox id="solved" className="rounded-none" />
					<label
						htmlFor="solved"
						className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
					>
						{'Solved'}
					</label>
				</div>
			</div>
			<Play size={16} className="inline rotate-90 text-blue fill-current" />
			<Label
				className="text-lg font-semibold leading-none tracking-tight text-content-blue w-full mb-2 ml-2 "
				htmlFor="filters"
			>
				{'Status updated'}
			</Label>
			<span className="flex text-xs mb-8 ml-2 font-semibold">days ago</span>
			<span className="flex text-xs mb-8 mx-2 font-semibold">
				<Slider
					max={48}
					min={0}
					step={1}
					value={range}
					onValueChange={() => {
						setRange(range);
					}}
				/>
			</span>
		</div>
	);
};
export default VulnerabilitiesFilter;
