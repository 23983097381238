import config from '@/config';
import { Client } from './GeneratedClient';
import auth from './auth';
import { DefaultConstant } from '@/constants';


let getApiKey = async () => {
	const apiKey = await auth.getApiKey();
	// save key
	localStorage.setItem( DefaultConstant.API_KEY_STORAGE_NAME, apiKey );
    return apiKey;
};

let globalFetch = fetch;

const client = new Client( config.clientApiUrl, {
	fetch: async ( url, init ) => {
		// get accessToken and add it to headers when authentication is integrated with the api
		const accessToken = await auth.getAccessToken();

		// get key
		let apiKey = localStorage.getItem( DefaultConstant.API_KEY_STORAGE_NAME ) ? 
			localStorage.getItem( DefaultConstant.API_KEY_STORAGE_NAME ) : null;
		
		// no key in storage
		if ( !apiKey ) {
			console.log( 'no key in the storage. fetching...' );
			apiKey = await getApiKey();
			localStorage.setItem( DefaultConstant.API_KEY_STORAGE_NAME, apiKey );
		}

		// TODO mido: 
		// Later on add `Bearer ${accessToken}` as "Authorization" header
		let extendedInit = {
			...init,
			headers: {
				...( init?.headers || {} ),
				Authorization: `Bearer ${accessToken}`,
				StorageId: localStorage.getItem( DefaultConstant.API_KEY_STORAGE_ID ) || config.storageId,
				ApplicationId: config.applicationId,
				UserId: config.userId,
				ApiKey: apiKey,
			}
		};
 
		let response = await globalFetch( url, extendedInit );

		//expired apiKey
		if ( response.statusText === 'Unauthorized' ) {
			console.log( 'expired api key. fetching...' );
			apiKey = await getApiKey();

			extendedInit = {
				...extendedInit,
				headers: {
					...extendedInit.headers,
					ApiKey: apiKey
				}
			};

			return globalFetch( url, extendedInit );
		}

		return response;
	},
})

export const api = client;

export default api;
