
import  React, { PropsWithChildren, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { request } from '@/middleware/authConfig';
import { auth } from '@/middleware/auth';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

export const ProtectedRoute: React.FC<PropsWithChildren> = ({ children }) => {
	const { instance, accounts } = useMsal();
	
	useEffect( () => {
		const performSilentLogin = async () => {
			try {
				await instance.ssoSilent(request);
			} catch ( err ) {
				if ( err instanceof InteractionRequiredAuthError ) {
					console.log( 'err', err );
					await auth.signIn();					
				} else {
					// handle error
					console.log( 'ssoSilent login error', err )
				}
			}
		};

		if ( accounts.length > 0) {
			instance.setActiveAccount(accounts[0]);
		}
		else {
			performSilentLogin();
		}
	}, [ instance, accounts ])

	// if (!user) {
	//   return <Navigate to='/unauthorized' replace />;
	// }
  
	return <>{children}</>;
  };