import { LifecycleStatus } from '@/middleware/GeneratedClient';

export type Category =
| 'deviceType'
| 'manufacturer'
| 'usage'
| 'lifecycleStatus'
| 'msp'
| 'equipmentType';

export type IFilter = {
	category: Category;
	filter: string;
}

export interface ComplexFilter {
	hardware: IFilter[],
	software: IFilter[]
}

export interface Filters {
	deviceTypes: IFilter[];
	hwManufacturers: IFilter[];
	swManufacturers: IFilter[];
	usage: IFilter[];
	lifecycleStatus: IFilter[];
	hwMsps: IFilter[];
	swMsps: IFilter[];
	equipmentType: IFilter[];
}

export type SimplifiedAssetsItemHw = {
	[key: number]: {
		deviceType: string | undefined | null;
		manufacturer: string | undefined | null;
		lifecycleStatus: string | undefined | null;
		usage: string | undefined | null;
		msp: string | undefined | null;
	};
};
export type SimplifiedAssetsItemSw = {
	[key: number]: {
		equipmentType: string | undefined | null;
		manufacturer: string | undefined | null;
		lifecycleStatus: LifecycleStatus | undefined | null | string;
		msp: string | undefined | null;
	};
};

export type FilterOptions = {
	deviceType?: string[];
	manufacturer?: string[];
	lifecycleStatus?: string[];
	usage?: string[];
	hwMsp?: string[];
	swMsp?: string[];
	equipmentType?: string[];
};

export interface SearchParams {
	filters: IFilter[],
	tab: string
}

export type AssetType = 'Hardware' | 'Software';
export const hwAssetType:AssetType = 'Hardware';
export const swAssetType:AssetType = 'Software'