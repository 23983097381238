import React from 'react';
import { X } from 'lucide-react';
import { Button } from '@/components/shadcn-ui/button';
import { IFilter } from '@/types/assets';

interface FilterItemProps {
	item: IFilter,
	handleRemove: (item:IFilter) => void;
	categoryHint: string;
}

const FilterItem = ( { item, handleRemove, categoryHint } : FilterItemProps ) => {
	return (
		<div className='flex items-center text-xs border rounded bg-background text-blue pt-0.5 pl-2 mt-1.5 border-blue'>
			<p className='pr-2 pb-0.5'>{categoryHint}: {item.filter}</p>
			<Button onClick={ () => { handleRemove(item) } } variant="ghost" className='p-1 mr-0.5 h-6'>
				<X className='h-3 w-3'/>
			</Button>
		</div>
	);
}

export default FilterItem;