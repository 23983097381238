const defaultConfig = {
	clientDebug: true,
	clientApiUrl: 'https://api.test.continuoty.com/service',
	authApiUrl: 'https://bcp-ot-mvp-securitytokenserviceapi.azurewebsites.net/api/V1',
	storageId: '20e2e49682a7',
	applicationId: 'app',
	userId: 'user',
	companyName: 'ICT',
	auth_clientId: '41e1ead7-2f89-4a98-8e94-ec14dcc2ed4b',
	auth_authority:'https://bcpcontinuoty.b2clogin.com/bcpcontinuoty.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN',
	auth_knownAuthorities: ['https://bcpcontinuoty.b2clogin.com'],
	auth_scopes: [
		'https://bcpcontinuoty.onmicrosoft.com/e784f574-a9af-4b45-b9a3-527049264ecc/ServiceApi.Use',
		'openid',
	],
};

const isLocal = (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1")

const config = {
	debug: isLocal ? defaultConfig.clientDebug : window.config?.clientDebug,
	clientApiUrl: isLocal ? defaultConfig.clientApiUrl : window.config?.clientApiUrl,
	authApiUrl: isLocal ? defaultConfig.authApiUrl : window.config?.authApiUrl,

	storageId: isLocal ? defaultConfig.storageId : window.config?.storageId,
	applicationId: isLocal ? defaultConfig.applicationId : window.config?.applicationId,
	userId: isLocal ? defaultConfig.userId : window.config?.userId,

	companyName:  isLocal ? defaultConfig.companyName : window.config?.companyName,

	auth_clientId:  isLocal ? defaultConfig.auth_clientId : window.config?.auth_clientId,
	auth_authority:  isLocal ? defaultConfig.auth_authority : window.config?.auth_authority,
	auth_knownAuthorities: isLocal ? defaultConfig.auth_knownAuthorities : window.config?.auth_knownAuthorities,
	auth_scopes:  isLocal ? defaultConfig.auth_scopes : window.config?.auth_scopes,
};

export default config;
