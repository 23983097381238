import React, { ReactElement } from 'react';
import { NavLink } from "react-router-dom";
import { cn } from '@/lib/styling-utils';
import { Icons } from '@/components/shadcn-ui/icons';
import {
  NavigationMenu,
  NavigationMenuList,
  NavigationMenuItem,
  navigationMenuTriggerStyle
} from '@/components/shadcn-ui/navigation-menu';

interface MenuItemProps {
	name: string,
	link: string,
	icon: ReactElement,
	activeIcon?: ReactElement,
	styles?: string
}
const MenuItems:MenuItemProps[] = [
	{
		name: 'Vulnerabilities',
		link: '/dashboard/vulnerabilities',
		icon: <Icons.Vulnerabilities />,
		activeIcon: <Icons.ActVulnerabilities />
	},
	{
		name: 'Risks',
		link: '/dashboard/risks',
		icon: <Icons.Risks />,
		activeIcon: <Icons.ActRisks />
	},
	{
		name: 'Assets',
		link: '/dashboard/assets',
		icon: <Icons.Assets />,
		activeIcon: <Icons.ActAssets />
	},
	{
		name: 'Benchmark',
		link: '/dashboard/benchmark',
		icon: <Icons.Benchmark />
	},
	{
		name: 'OT Community',
		link: '/dashboard/ot-community',
		icon: <Icons.Community />
	},
	{
		name: 'Recovery',
		link: '/dashboard/recovery',
		icon: <Icons.Recovery />,
		styles: 'border-t-yellow border-r-yellow border-l-yellow border-2 border-b-0'
	},
	{
		name: 'ICT Expertize',
		link: '/dashboard/ict-expertise',
		icon: <Icons.IctExpertise />
	}
]; 

const MenuItem: React.FC<MenuItemProps> = ({
  icon,
  activeIcon,
  name,
  link,
  styles
}) => {
  const navLinksClasses = navigationMenuTriggerStyle();
  const customStyles = styles ? cn([navLinksClasses, styles]) : navLinksClasses;
  const navLinksClassesActive = cn([customStyles, 'bg-grey', 'text-blue']);

  return (
    <NavigationMenuItem>
      <NavLink
        to={link}
        className={({ isActive }) => {
          return isActive ? navLinksClassesActive : customStyles;
        }}
      >
		{({ isActive }) => {
			const iconToDisplay = isActive && activeIcon ? activeIcon : icon;
			return <>{React.cloneElement( iconToDisplay, {
				className: 'mr-4 h-5 w-4'
			} )} {name}</>
		}}
      </NavLink>
    </NavigationMenuItem>
  );
};

const MainNav = () => {
	return (
		<NavigationMenu className="justify-start">
			<NavigationMenuList className='space-x-3'>
				{
					MenuItems.map( (item, index) => {
						return (
							<MenuItem 
								key={`item.name-${index}`}
								link={item.link}
								icon={item.icon}
								activeIcon={item.activeIcon}
								name={item.name}
								styles={item.styles || ''}
							/>
						)
					} )
				}
			</NavigationMenuList>
		</NavigationMenu>
	);
}

export default MainNav;