import { atom } from 'recoil';
import { HardwareAssetDto, SoftwareAsset } from '@/middleware/GeneratedClient';
import { Filters } from '@/types/assets';
import { usageFilters } from '@/mocks/data/assetsFilters';

export const hwAssetsByTreeNodeAtom = atom<HardwareAssetDto[]>({
	key: 'hwAssetsByTreeNode',
	default: []
});

export const swAssetsByTopLocationAtom = atom<SoftwareAsset[]>({
	key: 'swAssetsByTopLocation',
	default: []
});

export const filtersAtom = atom<Filters>({
	key: 'filters',
	default: {
		deviceTypes: [],
		hwManufacturers: [],
		swManufacturers: [],
		usage: usageFilters,
		lifecycleStatus: [],
		hwMsps: [],
		swMsps: [],
		equipmentType: []
	}
})