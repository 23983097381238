import React, { useRef, useEffect } from 'react';
import {
	Chart as ChartJS,
	LinearScale,
	PointElement,
	Tooltip,
	Legend,
	ChartOptions,
	Plugin,
	ChartData,
	ActiveElement,
	ChartEvent
} from 'chart.js';
import { Bubble } from 'react-chartjs-2';
import { RisksBubbleDataPoint } from '@/pages/dashboard-outlet/Risks';
import { IFilter, SearchParams } from '@/types/assets';
import { useNavigate, createSearchParams } from 'react-router-dom';

ChartJS.register(LinearScale, PointElement, Tooltip, Legend);

export const externalOptions: ChartOptions<'bubble'> = {
	plugins: {
		legend: {
			onClick: (e, legendItem, legend) => { }, // disable charts removal functionality settled by default
			position: 'bottom',
			align: 'end',
			labels: {
				usePointStyle: false,
				boxWidth:0 // pointStyle: 'circle',
			}
		},
		tooltip: {
			callbacks: {
				label: function (context) {
					const dataIndex = context.dataIndex;
					const datasetIndex = context.datasetIndex;
					const chartData = context.chart.data.datasets[datasetIndex].data[dataIndex] as RisksBubbleDataPoint;
					return chartData.msp;
				}
			}
		}
	},
	scales: {
		x: {
			beginAtZero: false,
			position: 'top',
			reverse: true,
			ticks: {
				callback(tickValue, index, ticks) {
					let xLabel: string | null = null;
					switch (tickValue) {
						case 4: xLabel = 'Likely'; break;
						case 3: xLabel = 'Possible'; break;
						case 2: xLabel = 'Unlikely'; break;
						case 1: xLabel = 'Rare'; break;
					}
					return xLabel;
				},
				padding: 16
			},
			grid: {
				tickLength: 16
			},
			border: {
				display: false
			},
			title: {
				display: true,
				text: 'Likelihood',
				color: '#8F8F8F',
				font: {
					size: 16,
					weight: 'bolder',
					family: 'sans-serif'
				},
				padding: {
					top: 10,
					bottom: 15
				}
			}
		},
		y: {
			beginAtZero: true,
			suggestedMin: 1,
			suggestedMax: 5,
			ticks: {
				callback(tickValue, index, ticks) {
					let yLabel: string | null = null;
					switch (tickValue) {
						case 1: yLabel = 'Negligible'; break;
						case 2: yLabel = 'Minor'; break;
						case 3: yLabel = 'Moderate'; break;
						case 4: yLabel = 'Major'; break;
						case 5: yLabel = 'Catastrophic'; break;
					}
					return yLabel;
				},
				padding: 16,
			},
			grid: {
				tickLength: 16
			},
			border: {
				display: false
			}
		},

	},
	elements: {
		point: {
			radius(ctx, options) {
				const pointData = ctx.dataset.data[ctx.dataIndex] as RisksBubbleDataPoint;
				if (pointData) {
					return pointData.r;
				}
			},
			backgroundColor(ctx, options) {
				const pointRawData = ctx.dataset.data[ctx.dataIndex] as RisksBubbleDataPoint;
				if (pointRawData) {
					return `rgba(255, 54, 36, ${pointRawData.x * pointRawData.y / 20})`;
				}
			},
		}
	}
};

const plugin: Plugin<'bubble'> = {
	id: 'riskGraph',
	afterDatasetsDraw: (chart) => {
		const ctx = chart.ctx;
		
		chart.data.datasets.forEach((dataset, i) => {
			var meta = chart.getDatasetMeta(i);
			if (!meta.hidden) {
				meta.data.forEach((element, index) => {
					
					const currentBubble = dataset.data[index] as RisksBubbleDataPoint;
					ctx.fillStyle = '#000';
					const dataString = currentBubble.msp.length.toString();
					ctx.font = "normal 1rem system-ui";
					ctx.textAlign = "center";
					ctx.textBaseline = "middle";
					var position = element.tooltipPosition(true);

					if ( (currentBubble.x * currentBubble.y) >= 12 ) {
						ctx.lineWidth = 1;
						ctx.strokeStyle = '#000';
						ctx.fillStyle = '#fff';
						ctx.strokeText(dataString, position.x, position.y);
					}
					ctx.fillText(dataString, position.x, position.y);
				});
			}
		});
	},
	afterDraw: (chart) => { 
		const ctx = chart.ctx;
		const { chartArea } = chart;
		ctx.fillStyle = '#8F8F8F';
		ctx.font = `bolder 1rem sans-serif`;
		ctx.textAlign = "left";
		ctx.textBaseline = "top";
		ctx.fillText('Consequence', chartArea.left - 135, chartArea.top - 35); // second title was drawn i.e., 'Consequence'
	}
};

interface RiskAnalysisChartProps {
	chartData: RisksBubbleDataPoint[]
}

const BubbleChart: React.FC<RiskAnalysisChartProps> = ({
	chartData
}) => {
	const navigate = useNavigate();
	const chartRef = useRef<ChartJS<'bubble'> | null>(null);
	useEffect(() => {
		if (chartRef.current) {
		  const chartInstance = chartRef.current;
		  const { chartArea } = chartInstance;
		  
			const chartWidth = chartArea.right - chartArea.left;
			const chartHeight = chartArea.bottom - chartArea.top;
			const numberOfBubbles = chartInstance.data.datasets[0].data.length;
			const maxRadius = Math.min(chartWidth, chartHeight) / (3 * Math.sqrt(numberOfBubbles)); // calculate the maximum allowable radius for each bubble
			
			
			chartInstance.data.datasets[0].data.forEach((dataPoint, index) => {
				const point = dataPoint as RisksBubbleDataPoint;
				point.r = Math.min( point.r * point.msp.length, maxRadius);  // cap the radius at maxRadius

			});

		  chartInstance.update(); // Force chart to update
		}
	  }, [chartData]);

	const onClick = (
		event: ChartEvent,
		elements: ActiveElement[],
		chart: ChartJS<'bubble', RisksBubbleDataPoint[], unknown>
	) => {
		if (event.type === 'click' || event.type === 'dblclick') {
			if (elements[0]) {
				const activeElementIndex = elements[0].index;
				const activeData = chart.data.datasets[0].data[activeElementIndex] as RisksBubbleDataPoint;

				const filtersToSend: IFilter[] = activeData.msp.map(item => {
					return {
						category: 'msp',
						filter: item
					}
				});
				const data: SearchParams = {
					filters: filtersToSend,
					tab: ''
				}
				navigate({
					pathname: '/dashboard/assets',
					search: createSearchParams({
						data: JSON.stringify(data)
					}).toString()
				})
			}
		}
	};

	const options: ChartOptions = {
		...externalOptions,
		onClick,
		aspectRatio: 7 / 8,
		maintainAspectRatio: true,
		layout: {
			padding: {
				right: 50,
				bottom: 50,
				left: 25
			}
		}
	};

	const data: ChartData<'bubble', RisksBubbleDataPoint[]> = {
		datasets: [
			{
				data: chartData,
				label: '*all numbers are representitive of MSPs',
				borderWidth: 0
			},
			
		],
	}

	return (
		<>
			{chartData.length > 0 ? (
				<Bubble ref={chartRef} className="mx-8 mb-8" options={options} data={data} plugins={[plugin]} /> 
			) : (
				<div className="mx-8 mb-8">No data available</div>
			)}
		</>
	);
}

export default BubbleChart;