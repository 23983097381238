export const lifecycleStatuses = [
	'active',
	'classic',
	'limited',
	'obsolete',
	'unknown',
] as const;

export type LifecycleStatus =
	| 'active'
	| 'classic'
	| 'limited'
	| 'obsolete'
	| 'unknown';

export const getLifecycleChartColor = (color?: LifecycleStatus) => {
	switch (color) {
		case 'active':
			return 'rgba(5, 150, 105, 1)';
		case 'classic':
			return 'rgb(251 191 36)';
		case 'limited':
			return 'rgb(231, 126, 35)';
		case 'obsolete':
			return 'rgb(159 18 57)';
		case 'unknown':
		default:
			return 'rgb(141, 67, 173)';
	}
};
