import { filterAssets } from '@/lib/filter-utils';
import { extractCompanyIdSiteIdLocationIdFromPath, getAssetsForInstallationAndItsChildren, getChildrenAssets } from '@/lib/tree-utils';
import { HardwareAssetStructure, SoftwareAssetStructure } from '@/middleware/GeneratedClient';
import hardwareAssets from '@/mocks/data/hardwareAssets';
import { AssetType, IFilter, hwAssetType, swAssetType } from '@/types/assets';
import { CompanyTreeStructures, NodeAssets, TreeLocationNodeType } from '@/types/tree-structure';
import { INode } from 'react-accessible-treeview';
import { atom, selector, selectorFamily } from 'recoil';

export const companiesTreeStructuresAtom = atom<CompanyTreeStructures[] | null>({
	key: 'companiesTreeStructures',
	default: null
});

export const selectedPathAtom = atom<INode[]>({
	key: 'selectedPath',
	default: [],
});

// Return the selected companyId
export const selectedCompanyIdSelector = selector<number|undefined>({
	key: 'selectedCompanyId',
	get: ({get}) => {
		const path = get(selectedPathAtom);
		if ( path.length ) {
			const companyNodeIndex = path.findIndex( node => {
				return node.id.toString().includes( 'company_' )
			} );
			if ( companyNodeIndex === -1 ) {
				return undefined;
			}
			const companyNode = path[companyNodeIndex];
			return +companyNode.id.toString().split('company_')[1]
		}

		return undefined;
	},	
});

// Return the id of the installation
export const currentInstallationIdSelector = selector({
	key: 'currentInstallationId',
	get: ({get}) => {
		const path = get(selectedPathAtom);
		
		if ( path.length ) {
			const pathDetails = extractCompanyIdSiteIdLocationIdFromPath( path );
			if ( pathDetails.installationId ) {
				return pathDetails.installationId;
			}
		}

		return undefined;
	},
});

// Return the id of the location
export const currentLocationIdSelector = selector({
	key: 'currentLocationIdSelector',
	get: ({get}) => {
		const path = get(selectedPathAtom);
		if ( path.length ) {
			const pathDetails = extractCompanyIdSiteIdLocationIdFromPath( path );
			if ( pathDetails.locationId ) {
				return pathDetails.locationId;
			}
		}

		return undefined;
	},
});

export const getNodeAssetsSelector = selector<NodeAssets>({
	key: 'getNodeAssets',
	get: ({get}) => {
		const path = get(selectedPathAtom);
		const companiesTreeStructures = get(companiesTreeStructuresAtom);

		let hwAssets:HardwareAssetStructure[] = [];
		let swAssets:SoftwareAssetStructure[] = [];

		if ( path.length && companiesTreeStructures ) {
			const pathDetails = extractCompanyIdSiteIdLocationIdFromPath( path );
			const pathItems = path[path.length - 1].id.toString().split( '_' );
			const nodeType = pathItems[0] as TreeLocationNodeType;

			const companyIndex = companiesTreeStructures?.findIndex( item => item.company?.id === pathDetails.companyId );
			const companyWithStructure = companiesTreeStructures[companyIndex];
			const site = pathDetails.siteId ? companyWithStructure.siteStructures.find( item => item.site?.id === pathDetails.siteId ) : null;
			const installation = site && pathDetails.installationId ? site.locationNodes.find( item => item.id === pathDetails.installationId ) : null;

			switch( nodeType ) {
				case 'site':
					// map site.locationNodes, add the hw/sw assets of each installation
					// map childLocationNodes of each installation, add the hw/sw assets of each child
					if ( site ) {
						site.locationNodes.forEach( installation => {
							const assets = getAssetsForInstallationAndItsChildren( installation );
							hwAssets = [ ...hwAssets, ...assets.hwAssets ];
							swAssets = [ ...swAssets, ...assets.swAssets ];
						} );
					}

					break;
				case 'installation':
					// add the hw/sw assets of the installation
					// map its childLocationNodes, add the hw/sw assets of each child
					if ( installation ) {
						const assets = getAssetsForInstallationAndItsChildren( installation );
						hwAssets = [ ...hwAssets, ...assets.hwAssets ];
						swAssets = [ ...swAssets, ...assets.swAssets ];
					}
					break;
				case 'location': 
					// add the hw/sw assets of the current location and its children if available
					const nodeId = +pathItems[1];
					const location = installation && pathDetails.locationId ? installation.childLocationNodes.find( item => item.id === pathDetails.locationId ) : null;
					if ( location ) {
						if ( location.hardwareAssetStructure ) {
							hwAssets = [ ...hwAssets, ...location.hardwareAssetStructure ];
						}

						if ( location.softwareAssetStructure ) {
							swAssets = [ ...swAssets, ...location.softwareAssetStructure ]
						}

						// Add children assets
						if ( installation && location.childrenIds.length ) {
							const childrenAssets = getChildrenAssets( installation.childLocationNodes, nodeId );
							hwAssets = [ ...hwAssets, ...childrenAssets.childrenHwAssets ];
							swAssets = [ ...swAssets, ...childrenAssets.childrenSwAssets ];
						}
					}
			}
		}
		console.log( {hwAssets, swAssets} );

		return {
			hardwareAssets: hwAssets,
			softwareAssets: swAssets
		};
	}
})

type FilterArgs = {
	filters: Array<IFilter>,
	tab?: AssetType
}

export const filterAssetsByNodeAndTab = selectorFamily<NodeAssets, FilterArgs>({
	key: 'filterAssetsByNodeAndTab',
	get: ( {filters, tab} ) => ({get}) => {
		const assets = get(getNodeAssetsSelector);
		let filteredSwAssets:SoftwareAssetStructure[] = [];
		let filteredHwAssets:HardwareAssetStructure[] = [];
console.log( 'selector', filters );
		if ( filters.length > 0 ) {
			if ( tab === swAssetType ) {
				filteredSwAssets = filterAssets(filters, assets.softwareAssets, swAssetType)
			}
			else {
				filteredHwAssets = filterAssets( filters, assets.hardwareAssets, hwAssetType );
			}

			return {
				softwareAssets: filteredSwAssets,
				hardwareAssets: filteredHwAssets
			}
		}

		return assets;
	}
})