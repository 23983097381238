import Router from './Router';
import { RecoilRoot } from 'recoil';
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";

type AppProps = {
    pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {
	return (
		<MsalProvider instance={pca}>
			<div className="h-screen bg-background font-sans antialiased">
				<RecoilRoot>
					<Router />
				</RecoilRoot>
			</div>
		</MsalProvider>
	);
}

export default App;
