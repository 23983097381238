import { Link } from 'react-router-dom';
import { formatTimestamp } from '@/utils/utils';

interface IngestionItemProps {
	itemId: string;
	itemIdFromURL?: string | undefined;
	itemLabel?: string;
	itemIsLink?: boolean;
	linkType?: 'internal' | 'external';
	linkLabel?: string;
	linkTarget?: string;
	timeStamp?: number;
}

const IngestionItem = ({
	itemId,
	itemIdFromURL,
	itemLabel = '',
	itemIsLink = false,
	linkType = 'internal',
	linkLabel = '',
	linkTarget = '',
	timeStamp,
}: IngestionItemProps) => {
	let dateOfCreation;
	if (timeStamp) {
		dateOfCreation = formatTimestamp(timeStamp);
	}

	const labelContent = (
		<span>
			{itemLabel}
			<span className="text-xs italic">
				{dateOfCreation ? ' (' + dateOfCreation + ')' : ''}
			</span>
		</span>
	);

	return (
		<div className="text-content-grey">
			<div
				className={`mb-2 border-solid border-2 mx-auto rounded tracking-wide hover:bg-blue/20 px-2 py-1 w-full float-left ml-0 relative ${
					itemIdFromURL && itemId.toString() === itemIdFromURL
						? 'bg-blue/20'
						: ''
				}`}
			>
				{itemIsLink === true ? (
					<Link
						to={`/ingestion/audit/${itemId}`}
						className="flex items-center "
					>
						{labelContent}
					</Link>
				) : (
					<div className="flex items-center ">{labelContent}</div>
				)}
				{linkType === 'internal' ? (
					<Link
						to={linkTarget}
						className="absolute right-1 top-1/2 transform -translate-y-1/2 bg-blue/80 rounded text-xs tracking-wide text-white hover:bg-grey hover:text-blue focus:bg-white focus:text-blue px-2 py-1 w-30 "
					>
						{linkLabel}
					</Link>
				) : (
					<a
						href={linkTarget}
						className="absolute right-1 top-1/2 transform -translate-y-1/2 bg-blue/80 rounded text-xs tracking-wide text-white hover:bg-grey hover:text-blue focus:bg-white focus:text-blue px-2 py-1 w-30 "
					>
						{linkLabel}
					</a>
				)}
			</div>
		</div>
	);
};

export default IngestionItem;
