import { PropsWithChildren, forwardRef } from 'react';

interface WhiteContainerProps {
    styles?: string;
    id?: string | undefined;
}

const WhiteContainer = forwardRef<HTMLDivElement, PropsWithChildren<WhiteContainerProps>>(
    ({ children, id, styles }, ref) => {
        return (
            <div id={id}
                ref={ref}
                className={`${styles || null
                    } rounded-lg border bg-card text-card-foreground shadow-sm`}
            >{children}
            </div>
        );
    });
export default WhiteContainer;