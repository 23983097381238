import {
	getLifecycleChartColor,
	lifecycleStatuses,
} from '@/lib/lifecycle-utils';

export const LifecycleChartsRowHeader = () => {
	return (
		<div className="flex flex-row">
			<div className="w-32"></div>
			{lifecycleStatuses.map((status, index) => {
				return (
					<div
						key={ `header-${index}` }
						className="w-[76px] mr-1 capitalize text-center"
						style={{
							color: getLifecycleChartColor(status),
						}}
					>
						{status}
					</div>
				);
			})}
		</div>
	);
};
