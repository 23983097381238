import { HardwareComponent } from '@/middleware/GeneratedClient';
import { Card, CardContent } from '@/components/shadcn-ui/card';
import { Icons } from './shadcn-ui/icons';
import { hwAssetType } from '@/types/assets';

interface SurveyDataListProps {
    surveyId: string;
    currentTab: string;
    dataList: HardwareComponent[];
    selectedItemId: number | null;
    clickHandler: (id: number) => void;
}

const SurveyDataLists: React.FC<SurveyDataListProps> = ({ surveyId, currentTab, dataList, selectedItemId, clickHandler }) => {
    return (
        <>
            {currentTab === hwAssetType ? (
                <Card className="min-w-[15rem] h-auto min-h-[33vh] box-border border-none">
                    <CardContent className="p-2 text-sm w-full h-auto min-h-[33vh] max-h-[calc(100%)] box-border">
                        {dataList.length > 0 ? (
                            dataList.map((hwComponent) => (
                                <div className={`flex flex-wrap gap-3 justify-between border rounded my-1 p-4 group hover:bg-accent
                                                                ${hwComponent.id === 3 ? ' border-l-2 border-l-[#FF3624]' : ''}
                                                                ${hwComponent.id === 1232 || hwComponent.id === 4 ? ' border-l-2 border-l-[#f0c722]' : ''}
                                                                ${(
                                                    hwComponent.id !== undefined && selectedItemId === hwComponent.id
                                                ) ? "bg-accent" : ""}`}
                                    key={hwComponent.id}
                                    onClick={() => {
                                        if (hwComponent.id !== undefined) {
                                            clickHandler(hwComponent.id);
                                        }
                                    }}
                                >
                                    {(() => {
                                        let displayFields = [
                                            { label: <Icons.Hash className="mr-1 h-3.5 w-3.5 inline-flex" />, value: hwComponent.typeNumber, key: "typeNumber", classes: "flex flex-shrink items-center" },
                                            { label: <Icons.Manufacturer className="mr-1 h-3.5 w-3.5 inline-flex cursor-help" title="Manufacturer name" />, value: hwComponent.manufacturer?.name, key: "manufacturer", classes: "flex flex-shrink items-center" },
                                            { label: <Icons.Info className="mr-1 h-3.5 w-3.5 inline-flex" />, value: hwComponent.deviceDescription, key: "deviceDescription", classes: "flex-grow w-full" },
                                        ];
                                        return displayFields.map(field => (
                                            <div className={`group-hover:bg-accent ${field.classes}`} key={`${hwComponent.id}-${field.key}`}>
                                                <span className={`flex-shrink-0 text-content-grey group-hover:bg-accent ${field.classes}`}>{field.label}</span>
                                                <span className={`pl-1 font-normal flex-auto text-right group-hover:bg-accent ${field.classes}`}>{field.value}</span>
                                            </div>
                                        ));
                                    })()}
                                </div>
                            ))
                        ) : (
                            <div className="m-auto text-center text-gray-500 italic text-xs tracking-wide"><em>Missing hardware component data.</em></div>
                        )}
                    </CardContent>
                    <span className='flex'>

                    </span>
                </Card>
            ) : (
                <Card className="flex overflow-hidden min-w-[15rem] mt-3 border-none">
                    <CardContent className="pl-2 text-sm w-full overflow-y-auto max-h-[70vh]">
                        <span className='m-auto text-center text-gray-500 italic text-xs tracking-wide'><small>you have visited surveyId: {surveyId}</small></span>
                        <div className="m-auto text-center text-gray-500 italic text-xs tracking-wide">No Software data.</div>
                    </CardContent>
                </Card>
            )}
        </>
    );
};
export default SurveyDataLists;