import api from '@/middleware/api';
import { 
	DeviceTypeIEnumerableWebResponse,
	EquipmentTypeIEnumerableWebResponse,
	ManufacturerGroupingListWebResponse,
	LifecycleStatusIEnumerableWebResponse,
	MspPerComponentWebResponse,
	LifecycleStatus,
	DeviceType,
	EquipmentType,
	GroupedManufacturer,
	ManufacturerGrouping,
	SoftwareAssetStructure,
	HardwareAssetStructure
} from '@/middleware/GeneratedClient';
import { 
	AssetType,
	Category, 
	ComplexFilter, 
	FilterOptions, 
	IFilter, 
	SimplifiedAssetsItemHw, 
	SimplifiedAssetsItemSw, 
	swAssetType
} from '@/types/assets';

export const getFilter = async (
	category: Category
) => {
	let filterData: 
		| DeviceTypeIEnumerableWebResponse
		| LifecycleStatusIEnumerableWebResponse
		| EquipmentTypeIEnumerableWebResponse = {
		data: undefined,
		count: undefined,
		message: undefined,
		success: undefined,
		init: () => undefined,
		toJSON: () => undefined
	};

	try {
		switch ( category ) {
			case 'deviceType': 
				filterData = await api.deviceTypes(); break;
			case 'equipmentType': 
				filterData = await api.equipmentTypes(); break;
			case 'lifecycleStatus': 
				filterData = await api.lifecycleStatuses(); break;
		}
		return getCategoryFilter( filterData.data, category );
	}
	catch ( error ) {
		console.log( 'error', `${category}-${error}` );
		return [];
	}
}

export const getComplexFilter = async (
	category: Category,
	companyId?: number
) => {
	let filterData: 
		| ManufacturerGroupingListWebResponse
	    | MspPerComponentWebResponse;
	let result:ComplexFilter = {
		hardware: [],
		software: []
	}

	try {
		switch ( category ) {
			case 'manufacturer': 
				filterData = await api.getListOfGroupedManufacturers();
				filterData.data?.forEach( ( item:ManufacturerGrouping ) => {
					if ( item.manufacturers ) {
						if ( item.groupedManufacturer === GroupedManufacturer.BelongsToHardwareComponent ) {
							result.hardware = getCategoryFilter( item.manufacturers, category );
						}
	
						if ( item.groupedManufacturer === GroupedManufacturer.BelongsToSoftwareComponent ) {
							result.software = getCategoryFilter( item.manufacturers, category );
						}
					}
	
				} ); break;
			case 'msp':
				if ( !companyId ) {
					return result;
				}
				filterData = (await api.getMspsForHardwareSoftwareComponents( companyId ));
				const msps = filterData.data;
				if ( msps 
					&& msps.mspPerHardwareComponent
					&& msps.mspPerSoftwareComponent 
				) {
					result.hardware = msps.mspPerHardwareComponent
						.sort()
						.map( (msp) => {
							return { category: 'msp', filter: msp };
						} );
					result.software = msps.mspPerSoftwareComponent
						.sort()
						.map( (msp) => {
							return { category: 'msp', filter: msp };
						} );
				}
		}
	}
	catch ( error ) {
		console.log( 'error', `${category}-${error}` );
	}
	return result;
}

const getCategoryFilter = ( data: (
	| DeviceType
	| EquipmentType
	| LifecycleStatus)[]
	| null
	| undefined,
	category: Category 
) => {
	let filters:string[] = [];

	if (data) {
		data.forEach((filterObject) => {
			if (
				filterObject.name &&
				filterObject.name !== null &&
				filterObject.name !== ''
			)
				filters.push(filterObject.name);
		});

	}
	const categoryFilters: IFilter[] = filters
		.sort()
		.map((filter) => {
			return { category: category, filter: filter };
		});
	return categoryFilters;
}


export const getAssetsSimplifiedArrayHw = (assets: HardwareAssetStructure[]) => {
	const simplifiedAssetsArray: SimplifiedAssetsItemHw[] = [];
	assets.forEach((singleAsset) => {
		if (singleAsset.id) {
			const id = singleAsset.id;
			const deviceType = singleAsset.hardwareComponentStructure?.deviceTypeStructure?.name;
			const manufacturer = singleAsset.hardwareComponentStructure?.manufacturerStructure?.name;
			const lifecycleStatus = singleAsset.hardwareComponentStructure?.productLifeCycleStatusStructure?.name;
			const usage = singleAsset.inUseOrSpare;
			const msp = singleAsset.mainSystemPart;
			const newItem: SimplifiedAssetsItemHw = {
				[id]: {
					deviceType,
					manufacturer,
					lifecycleStatus,
					usage,
					msp,
				},
			};
			simplifiedAssetsArray.push(newItem);
		}
	});
	return simplifiedAssetsArray;
}

export const getAssetsSimplifiedArraySw = (assets: SoftwareAssetStructure[]) => {
	const simplifiedAssetsArray: SimplifiedAssetsItemSw[] = [];
	assets.forEach((singleAsset) => {
		if (singleAsset.id) {
			const id = singleAsset.id;
			const equipmentType = singleAsset.equipmentTypeStructure?.name;
			const manufacturer = singleAsset.softwareComponentStructure?.manufacturer?.name;
			const lifecycleStatus =
				singleAsset.softwareComponentStructure?.productLifeCycle?.name;
			const msp = singleAsset.mainSystemPart;
			const newItem: SimplifiedAssetsItemSw = {
				[id]: {
					equipmentType,
					manufacturer,
					lifecycleStatus,
					msp,
				},
			};
			simplifiedAssetsArray.push(newItem);
		}
	});
	return simplifiedAssetsArray;
}

export const filterAssets = (
	selectedFilters: IFilter[],
	assetsToFilter: (HardwareAssetStructure | SoftwareAssetStructure)[],
	activeTab?: AssetType
) => {
	let assetsSimplified: (
		| SimplifiedAssetsItemHw
		| SimplifiedAssetsItemSw
	)[] = ( activeTab && activeTab === swAssetType ) ? 
		getAssetsSimplifiedArraySw( assetsToFilter )
		: 
		getAssetsSimplifiedArrayHw( assetsToFilter );

	const selectedFiltersGroupedByCategory = selectedFilters.reduce(
		(result: FilterOptions | any, item: IFilter) => {
			//Remove any
			const { category, filter } = item;

			if (!result[category]) {
				result[category] = [];
			}
			result[category].push(filter);

			return result;
		},
		{}
	);

	const selectedFiltersGroupedByCategoryArray = Object.entries(
		selectedFiltersGroupedByCategory
	).map(([key, value]) => {
		const obj: any = {}; //Remove any
		obj[key] = value;
		return obj;
	});

	function matchesFilters(item: any) {
		//Remove any
		return selectedFiltersGroupedByCategoryArray.every((filter) => {
			return Object.entries(filter).every(([key, values]) => {
				return (values as number[]).includes(item[key]);
			});
		});
	}

	const filteredAssets = assetsSimplified.filter((item) => {
		return matchesFilters(Object.values(item)[0]);
	});

	const keysArray = filteredAssets.map((item) => {
		return Object.keys(item)[0];
	});
	console.log( 'keysArray', keysArray );

	const filteredFinalAssets = assetsToFilter.filter((item) => {
		return keysArray.includes(item.id?.toString() as string);
	});

	return filteredFinalAssets;
};

export const checkArePassedFiltersPartOfSelectedFilters = ( 
	passedFilters:IFilter[], 
	selectedFilters:IFilter[] 
) => {
	let result = false;
	if ( selectedFilters.length > 0 ) {
		result = true;
		passedFilters.forEach( ( passedFilter ) => {
			if ( result ) {
				const foundFilter = selectedFilters.find( selectedFilter => selectedFilter.filter === passedFilter.filter );
				result = foundFilter ? true : false
			}
		} );
	}

	return result
}