import { atom } from 'recoil';

export const surveyFormState = atom({
    key: 'surveyFormState',
    default: {
        typeNumber: '',
        deviceTypeName: '',
        firmwareVersion: '',
        productLifecycle: '',
        endLifecyle: '',
        successorType: '',
        remarks: '',
        additionalInfo: ''
    },
});