import { useState, useEffect, useCallback } from 'react';
import { useSearchParams, createSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { Card, CardContent } from '@/components/shadcn-ui/card';
import { Input } from '@/components/shadcn-ui/input';
import { Icons } from '@/components/shadcn-ui/icons';
import FilterItem from '@/components/ui/FilterItem';
import Filter from '@/components/ui/Filter';
import CustomTabs from '@/components/ui/CustomTabs';
import AssetsTable from '@/components/AssetsTable';

import {HardwareAssetStructure, SoftwareAssetStructure} from '@/middleware/GeneratedClient';
// import hardwareAssets from '@/mocks/data/hardwareAssets';
// import softwareAssets from '@/mocks/data/softwareAssets';

import { filterAssetsByNodeAndTab, getNodeAssetsSelector } from '@/state/hierarchy-tree';
import { 
	IFilter, 
	SearchParams,
	hwAssetType,
	AssetType,
	swAssetType
} from '@/types/assets';
import { useSelectedPathText } from '@/lib/useSelectedPathText';
import { 
	checkArePassedFiltersPartOfSelectedFilters
} from '@/lib/filter-utils';
import useGetAllFilterItems from '@/lib/useGetAllFilterItems';

const categoryFacets = {
	deviceType: 'DT',
	manufacturer: 'M',
	usage: 'U',
	lifecycleStatus: 'LS',
	msp: 'MSP',
	equipmentType: 'ET',
}
const filterCategories = {
	deviceType: 'Device Type',
	manufacturer: 'Manufacturer',
	usage: 'Usage',
	lifecycleStatus: 'Lifecycle status',
	msp: 'MSP',
	equipmentType: 'Equipment Type',
};

const Assets = () => {
	const assetsPerNode = useRecoilValue(getNodeAssetsSelector);
	const hwAssets = assetsPerNode.hardwareAssets;
	const swAssets = assetsPerNode.softwareAssets;

	const filters = useGetAllFilterItems();
	const [selectedFilters, setSelectedFilters] = useState<IFilter[]>([]);
	const [filteredAssets, setFilteredAssets] =
		useState<HardwareAssetStructure[]>(hwAssets);
	const [filteredAssetsSw, setFilteredAssetsSw] =
		useState<SoftwareAssetStructure[]>(swAssets);
	const [filteredAssetsAfterSearch, setFilteredAssetsAfterSearch] =
		useState<HardwareAssetStructure[]>();
	const [filteredAssetsAfterSearchSw, setFilteredAssetsAfterSearchSw] =
		useState<SoftwareAssetStructure[]>();

	const [currentTab, setCurrentTab] = useState<AssetType>(hwAssetType);
	const [searchedPhrase, setSearchedPhrase] = useState('');

	const [ searchParams, setSearchParams ] = useSearchParams();
	const JSONdata = searchParams.get('data');
	const searchParamsData: SearchParams|null = JSONdata ? 
	JSON.parse(JSONdata) : null;
	const filteredAssetsByNodeAndTab = useRecoilValue(filterAssetsByNodeAndTab({filters:selectedFilters, tab: currentTab}));

	const removeFilterFromQueryParams = useCallback( ( selectedFilterIndex: number ) => {
		if ( selectedFilterIndex > -1 && searchParamsData ) {	
			const foundPassedFilterIndex = searchParamsData.filters.findIndex(
				passedFilter => passedFilter.filter === selectedFilters[selectedFilterIndex].filter && passedFilter.category === selectedFilters[selectedFilterIndex].category
			)

			if ( foundPassedFilterIndex > -1 ) {
				setSearchParams( prevState => {
					const prevJSONData = prevState.get('data');
					if ( prevJSONData ) {
						const prevData:SearchParams = JSON.parse( prevJSONData );
						const updatedFiltersFromQueryData = [
							...prevData.filters.slice(0, foundPassedFilterIndex),
							...prevData.filters.slice(foundPassedFilterIndex + 1),
						];

						if ( updatedFiltersFromQueryData.length > 0 ) {
							const updatedData:SearchParams = {
								filters: updatedFiltersFromQueryData,
								tab: prevData.tab
							};
							return createSearchParams({
								data: JSON.stringify(updatedData)
							}).toString();
						}
						else {
							return createSearchParams(undefined);
						}
					}
					return prevState;
				} )
			}
		}
	}, [selectedFilters, searchParamsData, setSearchParams]);

	const handleSelect = useCallback(
		(item: IFilter) => {
			const foundIndex = selectedFilters.findIndex(
				(selFil) => selFil.filter === item.filter
			);

			// remove the filter from the query params if available
			removeFilterFromQueryParams(foundIndex)

			const updatedSelectedFilters =
				foundIndex > -1
					? [
						...selectedFilters.slice(0, foundIndex),
						...selectedFilters.slice(foundIndex + 1),
					]
					: [...selectedFilters, item];

			setSelectedFilters(updatedSelectedFilters);
		},
		[selectedFilters, removeFilterFromQueryParams]
	);

	useEffect( () => {
		// If filters and tab query params are available,
		// set them to component's state
		// thus filterAssetsByNodeAndTab will fire again 
		if ( searchParamsData?.filters ) {
			const arePassedFiltersPartOfSelected = checkArePassedFiltersPartOfSelectedFilters(searchParamsData.filters, selectedFilters );
			if ( !arePassedFiltersPartOfSelected ) {
				setSelectedFilters( searchParamsData.filters );

				if ( searchParamsData?.tab ) {
					const queryTab = searchParamsData.tab as AssetType;
					if ( currentTab !== queryTab ) {
						setCurrentTab( queryTab );
					}
				}
			}
		}

	}, [searchParamsData, currentTab, selectedFilters] );

	useEffect( () => {
		// when there is change in the selected node, selectedFilters 
		// or currentTab, save filteredAssets to component state
		setFilteredAssets(filteredAssetsByNodeAndTab.hardwareAssets);
		setFilteredAssetsSw(filteredAssetsByNodeAndTab.softwareAssets);
	}, [filteredAssetsByNodeAndTab] );
	
	const selectedPathTitle = useSelectedPathText();

	function handleSelectedTab(selectedTab: AssetType) {
		setCurrentTab(selectedTab);

		if ( !searchParamsData ) {
			setSelectedFilters([]);
			setFilteredAssets(hwAssets);
			setFilteredAssetsSw(swAssets);
		}
		else if ( searchParamsData?.filters ) {
			// leave only the filters from the Risks screen
			// required by Gabriel
			setSelectedFilters(searchParamsData.filters);
		}

		setSearchedPhrase('');
	}

	const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchedPhrase(event.target.value);
	}

	useEffect( () => {
		const searchedPhraseTrimmed = searchedPhrase.trim().toLowerCase();
		if (searchedPhraseTrimmed!==''){
			if (currentTab === hwAssetType){	
				const result = filteredAssets.filter((item) => {
					const description:string = item.hardwareComponentStructure?.deviceDescription?.toString().toLowerCase() || '';
					const name:string = item.hardwareComponentStructure?.manufacturerStructure?.name?.toString().toLowerCase() || '';					
					return (
					  description.includes(searchedPhraseTrimmed) || name.includes(searchedPhraseTrimmed)
					);
				  });
				  setFilteredAssetsAfterSearch(result);
			}
			else {	
				const result = filteredAssetsSw.filter((item) => {
					const description:string = item.softwareComponentStructure?.description?.toString().toLowerCase() || '';
					const name:string = item.softwareComponentStructure?.manufacturer?.name?.toString().toLowerCase() || '';					
					return (
					  description.includes(searchedPhraseTrimmed) || name.includes(searchedPhraseTrimmed)
					);
				  });
				  setFilteredAssetsAfterSearchSw(result);
			}
		}

	}, [searchedPhrase, filteredAssets, filteredAssetsSw, currentTab] )

	const filtersContent =
		currentTab === hwAssetType ? (
			<Card className="overflow-hidden mt-[3.8rem] min-w-[11.5rem] w-[11.5rem] max-w-fit">
				<CardContent className="pl-2 text-sm overflow-y-auto max-h-[50vh]">
					{hwAssets.length ? (
						<>
							<Filter
								category={filterCategories.deviceType}
								items={filters.deviceTypes}
								selectedItems={selectedFilters}
								handleSelect={handleSelect}
							/>
							<Filter
								category={filterCategories.manufacturer}
								items={filters.hwManufacturers}
								selectedItems={selectedFilters}
								handleSelect={handleSelect}
							/>
							<Filter
								category={filterCategories.lifecycleStatus}
								items={filters.lifecycleStatus}
								selectedItems={selectedFilters}
								handleSelect={handleSelect}
							/>
							<Filter
								category={filterCategories.usage}
								items={filters.usage}
								selectedItems={selectedFilters}
								handleSelect={handleSelect}
							/>
							<Filter
								category={filterCategories.msp}
								items={filters.hwMsps}
								selectedItems={selectedFilters}
								handleSelect={handleSelect}
							/>
						</>
					) : (
						<div className="p-4 py-8">No data to filter.</div>
					)}
				</CardContent>
			</Card>
		) : (
			<Card className="overflow-hidden mt-[3.8rem] min-w-[11.5rem] w-[11.5rem] max-w-fit">
				<CardContent className="pl-2 text-sm overflow-y-auto max-h-[50vh]">
					{swAssets.length ? (
						<>
							<Filter
								category={filterCategories.equipmentType}
								items={filters.equipmentType}
								selectedItems={selectedFilters}
								handleSelect={handleSelect}
							/>
							<Filter
								category={filterCategories.manufacturer}
								items={filters.swManufacturers}
								selectedItems={selectedFilters}
								handleSelect={handleSelect}
							/>
							<Filter
								category={filterCategories.lifecycleStatus}
								items={filters.lifecycleStatus}
								selectedItems={selectedFilters}
								handleSelect={handleSelect}
							/>
							<Filter
								category={filterCategories.msp}
								items={filters.swMsps}
								selectedItems={selectedFilters}
								handleSelect={handleSelect}
							/>
						</>
					) : (
						<div className="p-4 py-8">No data to filter.</div>
					)}
				</CardContent>
			</Card>
		);

	const tableContent =
		currentTab === hwAssetType ? (
			<AssetsTable
				data={(searchedPhrase.trim() !=='' && filteredAssetsAfterSearch) ? filteredAssetsAfterSearch : filteredAssets}
				className={`${selectedFilters.length ? 'mt-[3.9rem]' : 'mt-[.9rem]'}`}
				type={hwAssetType}
			/>
		) : (
			<AssetsTable
				data={(searchedPhrase.trim() !=='' && filteredAssetsAfterSearchSw) ? filteredAssetsAfterSearchSw : filteredAssetsSw}
				className={`${selectedFilters.length ? 'mt-[3.9rem]' : 'mt-[.9rem]'}`}
				type={swAssetType}
			/>
		);

	return (
		<>
			<div className="max-h-[calc(100%-2rem)] w-full py-4 scroll-m-20">
				<h1 className="mb-6 text-blue capitalize text-2xl tracking-tight">{selectedPathTitle}</h1>
				<div className="flex flex-1 flex-col h-full box-border">
					<CustomTabs
						tabsOptions={[
							{
								option: hwAssetType,
								icon: <Icons.Hardware className="mr-2 h-4 w-4" />,
							},
							{
								option: swAssetType,
								icon: <Icons.Software className="mr-2 h-4 w-4" />,
							},
						]}
						onTabSelect={(selectedTab) => {
							handleSelectedTab(selectedTab);
						}}
						selectedTab={currentTab}
					>
						<div className="flex flex-row relative h-full box-border"> {/* space-x-6  */}
							<div className="rounded-l-lg border border-t-0 border-r-0 bg-card text-card-foreground shadow-sm pl-3">
								<h2 className="p-5 pb-2 text-lg font-semibold tracking-wide text-content-grey">
									{'Filters'}
								</h2>
								<div className="flex-grow p-y-2 max-w-fit">
									<div className="flex items-center my-2 absolute z-10">
										<div className='flex items-center  min-w-[11.5rem] w-[11.5rem]'>
											<Input
												id="AssetSearchTool"
												className="w-[11.5rem] max-w-fit mt-1.5 mr-5 pr-6"
												placeholder="search here..."
												autoCapitalize="none"
												autoCorrect="off"
												onChange={handleSearch}
												value={searchedPhrase}
											/>
											<button className="-ml-10 text-sm mt-1 opacity-80" onClick={()=>setSearchedPhrase('')}>x</button>
										</div>
										<div className='flex items-center pl-6 space-x-1 '>
											{selectedFilters.map((item, index) => (
												<FilterItem
													key={`${index}-${item}`}
													item={item}
													handleRemove={handleSelect}
													categoryHint={categoryFacets[item.category]}
												/>
											))}
										</div>
									</div>
								</div>
								{filtersContent}
							</div>
							{tableContent} 
						</div>
					</CustomTabs>
				</div>
			</div>
		</>
	);
};
export default Assets;
