import { atom } from 'recoil';

export const selectedItemsState = atom<number | null>({
    key: 'selectedItemsState',
    default: null,
});

export const defaultVilocifyState = atom({
    key: 'defaultVilocifyState',
    default: { id: 0, top: 0 }
});