import { useRecoilValue } from 'recoil';
import { surveyFormState } from '@/state/surveysaveform';
import { Card, CardContent } from '@/components/shadcn-ui/card';
import { Label } from '@/components/shadcn-ui/label';
import { Input } from './shadcn-ui/input';
import { Button } from '@/components/shadcn-ui/button';
import { Textarea } from '@/components/shadcn-ui/textarea';

const SurveySaveForm = () => {
    const surveyFormData = useRecoilValue(surveyFormState);

    return (
        <Card className="min-w-[15rem] h-auto min-h-[33vh] box-border border-none">
            <CardContent className="p-4 py-0 text-sm w-full h-auto min-h-[33vh] max-h-[calc(100%)] box-border">
                <div className='sticky top-0 z-1 mb-3 py-5 bg-white shadow-sm'>
                <h2 className="text-md font-semibold tracking-wide text-content-grey">
                        {'New component details'}<span className='font-semibold tracking-tight text-content-grey/90'><small>{' # '}</small></span><span className="bg-accent px-3">{surveyFormData.typeNumber}</span>
                </h2>
                </div>
                <Label htmlFor="deviceTypeName" className="text-content-grey/90">
                    {'Device type'}
                </Label>
                <Input
                    id="deviceTypeName"
                    placeholder='Device type name...'
                    className='mb-3.5 placeholder:italic placeholder:text-slate-400'
                    defaultValue={surveyFormData.deviceTypeName}
                />
                <Label htmlFor="firmwareVersion" className="text-content-grey/90">
                    {'Firmware version'}
                </Label>
                <Input
                    id="firmwareVersion"
                    className="mb-3.5 placeholder:italic placeholder:text-slate-400"
                    placeholder="Firmware version..."
                    autoCapitalize="none"
                    autoCorrect="off"
                    defaultValue={surveyFormData.firmwareVersion}
                />
                <Label htmlFor="ProductLifecycle" className="text-content-grey/90">
                    {'Product Lifecycle'}
                </Label>
                <Input
                    id="ProductLifecycle"
                    className="mb-3.5 placeholder:italic placeholder:text-slate-400"
                    placeholder="Product Lifecycle"
                    autoCapitalize="none"
                    autoCorrect="off"
                    defaultValue={surveyFormData.productLifecycle}
                />
                <Label htmlFor="EndOfLife" className="text-content-grey/90">
                    {'End of Life'}
                </Label>
                <Input
                    id="EndOfLife"
                    className="mb-3.5 placeholder:italic placeholder:text-slate-400"
                    placeholder="End of Life"
                    autoCapitalize="none"
                    autoCorrect="off"
                    defaultValue={surveyFormData.endLifecyle}
                />
                <Label htmlFor="successorType" className="text-content-grey/90">
                    {'Successor type'}
                </Label>
                <Input
                    id="successorType"
                    className="mb-3.5 placeholder:italic placeholder:text-slate-400"
                    placeholder="Successor type..."
                    autoCapitalize="none"
                    autoCorrect="off"
                    defaultValue={surveyFormData.successorType}
                />
                <Label htmlFor="remarks" className="text-content-grey/90">
                    {'Remarks'}
                </Label>
                <Textarea
                    id="remarks"
                    className="mb-3.5 placeholder:italic placeholder:text-slate-400"
                    placeholder="Remarks"
                    autoCapitalize="none"
                    autoCorrect="off"
                    defaultValue={surveyFormData.remarks}
                />
                <Label htmlFor="additionalInfo" className="text-content-grey/90">
                    {'Additional information'}
                </Label>
                <Textarea
                    id="additionalInfo"
                    className="mb-3.5 placeholder:italic placeholder:text-slate-400"
                    placeholder="Additional information..."
                    autoCapitalize="none"
                    autoCorrect="off"
                    defaultValue={surveyFormData.additionalInfo}
                />
                <div className="mt-4 mb-2">
                </div>
                <Button
                    id='SaveNewComponentDetails'
                    variant="secondary"
                    size="sm"
                    className='border rounded bg-white text-blue text-xs py-1 w-30 tracking-wide font-normal hover:bg-grey hover:text-blue focus:bg-white focus:text-white'
                    onClick={() => {
                        // adapt errors and alerts and reordering once the API is ready, Save the new component details
                    }}
                >
                    {'Save details'}
                </Button>
            </CardContent>
        </Card>
    );
}

export default SurveySaveForm;