import { useState } from 'react';
import Surveys from '@/mocks/data/surveys';
import { useParams } from 'react-router-dom';
import { Button } from '@/components/shadcn-ui/button';
import WhiteContainer from '@/components/ui/WhiteContainer';
import IngestionItem from '@/components/ui/IngestionItem';
import Modal from '@/components/ui/Modal';
import { Label } from '@/components/shadcn-ui/label';
import { Input } from '@/components/shadcn-ui/input';

type ModalType = 'createSurvey' | 'finalizeAudit';

const SurveysList = () => {
	const { auditId } = useParams();
	const [isCreateSurveyModalOpen, setIsCreateSurveyModalOpen] = useState(false);
	const [isFinalizeAuditModalOpen, setIsFinalizeAuditModalOpen] = useState(false);
	const openModal = ( type:ModalType ) => {
		if ( type === 'createSurvey' ) {
			setIsCreateSurveyModalOpen(true);
		}
		else {
			setIsFinalizeAuditModalOpen(true);
		}
		
	};
	const closeModal = ( type:ModalType ) => {
		if ( type === 'createSurvey' ) {
			setIsCreateSurveyModalOpen(false);
		}
		else {
			setIsFinalizeAuditModalOpen(false);
		}
	};

	const surveyData = Surveys.data;
	let surveyContent = (
		<div className="mt-2 text-content-grey">
			<p>No surveys to display</p>
		</div>
	);
	if (surveyData.length > 0) {
		surveyContent = surveyData.map((survey: any) => {
			return (
				<IngestionItem
					key={survey.id}
					itemId={survey.id}
					itemLabel={`Survey ${survey.id}`}
					linkType="internal"
					linkLabel="View details"
					linkTarget={`/ingestion/audit/${auditId}/survey/${survey.id}`}					
					timeStamp={Date.now()}//To be modified
				/>
			);
		});
	}

	return (
		<WhiteContainer styles="flex flex-col w-2/3 overflow-y-auto h-auto h-auto min-h-[33vh] max-h-[calc(100%-.4rem)] box-border p-6 relative">
			<Modal isOpen={isCreateSurveyModalOpen} onClose={() => closeModal('createSurvey')}>
				<h3 className="my-3 pb-7 text-lg font-semibold tracking-wide text-content-grey">{'Create survey for audit name'}</h3>
				<Label className="mb-2" htmlFor="surveyName">
					Survey name
				</Label>
				<Input id="surveyName" className="border-2 mb-4" />
				<Label className="mb-2" htmlFor="userDescription">
					User description
				</Label>
				<Input id="userDescription" className="border-2 mb-4" />
			</Modal>
			<Modal 
				isOpen={isFinalizeAuditModalOpen} 
				onClose={() => closeModal('finalizeAudit')}
				confirmActionLabel='Yes'
				rejectActionLabel='No'
			>
				<h3 className="mb-3 py-7 text-lg font-semibold leading-normal tracking-tight max-w-md">
					The finalization of an audit is an irreversible action and the audit will disappear from the audit list if this action is confirmed.
				</h3>
				<h3 className="mb-3 text-lg font-semibold leading-normal tracking-tight max-w-md">
					Are you sure?
				</h3>
			</Modal>
			<h2 className="sticky top-0 z-1 bg-white mt-1 mb-3 pb-7 text-lg font-semibold tracking-wide text-content-grey">{'Surveys'}</h2>
			<div className="overflow-y-auto max-h-[80%]">{surveyContent}</div>
			<Button
				variant="secondary"
				size="sm"
				className="w-30 ml-0 mt-4 absolute bottom-4 rounded bg-blue text-white text-xs py-1 w-30 tracking-wide font-normal hover:bg-grey hover:text-blue focus:bg-white focus:text-white"
				onClick={() => openModal( 'createSurvey' )}
			>
				{'Create survey'}
			</Button>
			<Button
				onClick={ () => openModal('finalizeAudit')}
				size="sm"
				className="mx-auto bg-red/80 rounded text-xs tracking-wide text-white hover:bg-grey hover:text-blue focus:bg-white focus:text-blue px-3 py-2 h-9 float-left ml-0 mt-4 absolute bottom-4 right-6 leading-5 "
			>
				Finalize audit
			</Button>
		</WhiteContainer>
	);
};
export default SurveysList;
