import { useParams } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import Audits2 from '@/mocks/data/audits2'; // hardcoded response structured by: /api/V1/Customers/{customerId}/Sites/{siteId}/Locations/{locationId}/Audits
import { HardwareComponent, HardwareAsset, VilocifyComponent } from '@/middleware/GeneratedClient';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { selectedItemsState, defaultVilocifyState } from '@/state/survey';
import { surveyFormState } from '@/state/surveysaveform';
import SurveyDataLists from '@/components/SurveyDataLists';
import SurveySaveForm from '@/components/SurveySaveForm';
import WhiteContainer from '@/components/ui/WhiteContainer';
import { Icons } from '@/components/shadcn-ui/icons';
import CustomTabs from '@/components/ui/CustomTabs';
import SurveyVilocifyLists from '@/components/SurveyVilocifyLists';
import { hwAssetType, swAssetType } from '@/types/assets';

const Survey = () => {
	const [selectedItemId, setSelectedItemId] = useRecoilState(selectedItemsState);
	const { id, top } = useRecoilValue(defaultVilocifyState);
	const setVilocifyComponentId = useSetRecoilState(defaultVilocifyState);
	const setSurveyFormData = useSetRecoilState(surveyFormState);
	const { auditId, surveyId } = useParams();
	const [currentTab, setCurrentTab] = useState('Hardware');
	const [hwComponents, setHwComponents] = useState<HardwareComponent[]>([]);
	const [vilocifyComponents, setVilocifyComponents] = useState<VilocifyComponent[]>([]);
    const scrollableWrapperRef = useRef<HTMLDivElement>(null);

	const clickHandler = (id: number) => {
		setSelectedItemId(id);
	};

	function handleSelectedTab(selectedTab: string) {
		setCurrentTab(selectedTab);
	}

	useEffect(() => {
		const hwAssetsData = Audits2.data[0].location.hardwareAssets as HardwareAsset[];
		const hardwareComponents: HardwareComponent[] = [];
		const vilocifyComponents: VilocifyComponent[] = [];

		hwAssetsData.forEach((hardwareAsset) => {
			if (hardwareAsset.hardwareComponent) {
				const hwComponent = HardwareComponent.fromJS(hardwareAsset.hardwareComponent);
				const vilocifyComponent = VilocifyComponent.fromJS(hwComponent.vilocifyComponent);
				hardwareComponents.push(hwComponent);
				if (hwComponent.id !== undefined && vilocifyComponent.componentId) {
					vilocifyComponent.componentId = hwComponent.id;
					vilocifyComponents.push(vilocifyComponent);
				}
			}
		});

		setHwComponents(hardwareComponents);
		setVilocifyComponents(vilocifyComponents);
		if (hardwareComponents[0].id !== undefined && !selectedItemId && hardwareComponents[0].vilocifyComponent?.id !== undefined) {
			setSelectedItemId(hardwareComponents[0].id);
			setVilocifyComponentId({ id: hardwareComponents[0].vilocifyComponent.id, top: 0 }); // nearest match is considered to be first listed Vilocify component here
		}
	}, [selectedItemId, setSelectedItemId, setVilocifyComponentId]);

	useEffect(() => {
		const selectedHwComponent = hwComponents.find(hw => hw.id === selectedItemId);

		if (selectedHwComponent) {
			setSurveyFormData(prevSurveyData => ({
				...prevSurveyData,
				typeNumber: selectedHwComponent?.typeNumber ?? '',
				deviceTypeName: selectedHwComponent?.deviceType?.name ?? '',
				firmwareVersion: selectedHwComponent?.latestAvailableFirmwareVersion ?? '',
				productLifecycle: selectedHwComponent?.productLifecycle?.name ?? '',
				endLifecyle: selectedHwComponent?.endLifecyle?.name ?? '',
				successorType: selectedHwComponent?.successorType ?? '',
				remarks: selectedHwComponent?.remarks ?? '',
				additionalInfo: selectedHwComponent?.additionalInfo ?? ''
			}));
		}
	}, [selectedItemId, hwComponents, setSurveyFormData]);

	
    useEffect(() => {
        if (id && scrollableWrapperRef.current) {
			const wrapper = scrollableWrapperRef.current;
			const getStickyHeader = document.getElementById("stickyHeaderDiv");
			wrapper.scrollTo({
				top: (top - wrapper.offsetTop + wrapper.scrollTop) - getStickyHeader!.offsetHeight,
				behavior: 'smooth',
			});
		}
    }, [id, top]);

	const selectedPathTitle = `Ingestion / Audit / ${auditId} / Survey / ${surveyId}`

	return (
		<div className="bg-slate-200 h-screen flex flex-col overflow-hidden">
			<div className="flex flex-1 bg-slate-200 p-4 h-full overflow-hidden">
				<div className="max-h-[calc(100%-4rem)] w-full py-4">
					<h1 className="mb-6 text-blue capitalize text-2xl tracking-tight">{selectedPathTitle}</h1>
					<div className="flex flex-col h-full box-border">
						<CustomTabs
							tabsOptions={[{
								option: hwAssetType,
								icon: <Icons.Hardware className="mr-2 h-4 w-4" />,
							},
							{
								option: swAssetType,
								icon: <Icons.Software className="mr-2 h-4 w-4" />,
							},]}
							onTabSelect={(selectedTab) => {
								handleSelectedTab(selectedTab);
							}}
							selectedTab={currentTab}
						>
							<WhiteContainer styles="flex flex-row h-full box-border p-7 border-t-0">
								<WhiteContainer styles='py-3 pl-3 flex-initial w-[10%] h-auto min-h-[33vh] max-h-[calc(100%-.4rem)] box-border border-none shadow-none'>
									<div className='mx-auto bg-[#8a44a7] rounded-l-lg text-xs tracking-wide text-white p-2 w-full my-1'>
										{'New components'}
									</div>
									<div className='mx-auto bg-slate-200/40 rounded-l-lg text-xs tracking-wide text-slate-400/90 p-2 w-full mt-3 mb-10'>
										{'Assets'}
									</div>
									<div className='p-4'>
										<p className='text-content-grey/90'>{'Company:'}</p><span className="font-normal">{Audits2.data[0].location.site.customer.name}</span>
									</div>
									<div className='p-4'>
										<p className='text-content-grey/90'>{'Auditor:'}</p><span className="font-normal">{`${Audits2.data[0].auditer}`}</span>
									</div>
								</WhiteContainer>
								{surveyId ? (<>
									<WhiteContainer styles='flex-initial w-[25%] overflow-y-auto h-auto min-h-[33vh] max-h-[calc(100%)] box-border'>
										<SurveyDataLists
											surveyId={surveyId}
											dataList={hwComponents}
											clickHandler={clickHandler}
											selectedItemId={selectedItemId}
											currentTab={currentTab} />
									</WhiteContainer>
									<WhiteContainer styles='flex-auto w-[25%] overflow-y-auto h-auto min-h-[33vh] max-h-[calc(100%)] box-border'>
										<SurveySaveForm />
									</WhiteContainer>
									<WhiteContainer ref={scrollableWrapperRef} styles='flex-auto w-[40%] overflow-y-auto h-auto min-h-[33vh] max-h-[calc(100%)] box-border'>
										<SurveyVilocifyLists currentTab={currentTab} vilocifyComponents={vilocifyComponents} />
									</WhiteContainer>
								</>
								) : (
									<div className="m-auto text-center text-gray-500 italic text-xs tracking-wide">Missing Survey Id: Please select a Survey.</div>
								)}
							</WhiteContainer>
						</CustomTabs>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Survey;