import api from '@/middleware/api';
import { INode } from 'react-accessible-treeview';
import config from '@/config';
import { ICompanyStructure, SiteStructure, LocationNodeMainInfo, HardwareAssetStructure, SoftwareAssetStructure } from '@/middleware/GeneratedClient';
import { ChildNode, CompanyTreeStructures, InstallationLevelNode, Site, SiteStructureWithNodes, TreeLocationNodeType } from '@/types/tree-structure';
import { TestChildNode } from '@/mocks/data/testTreeStructure';

const companyType: TreeLocationNodeType = 'company';
const siteType: TreeLocationNodeType = 'site';
const installationType: TreeLocationNodeType = 'installation';
const locationType:TreeLocationNodeType = 'location';


const buildSiteTreeOld = async (companyId: number) => {
	const companySites = await api.sites(companyId);
	const treeNodes: INode[] = [];
	const sitesNodesIds: (string | number)[] = [];

	if (companySites.data) {
		for (let site of companySites.data) {
			console.log({ site })
			if (site && site.id) {
				const currentSiteNode: INode = {
					name: site.name ?? 'Unknown Site',
					id: `site_${site.id}`,
					children: [],
					parent: `company_${companyId}`,
					isBranch: true,
				};


				// Add current node
				treeNodes.push(currentSiteNode);
				sitesNodesIds.push(currentSiteNode.id);
			}
		}
	}

	return {
		tree: treeNodes,
		directChildrenIds: sitesNodesIds,
	};
};

export const buildCompaniesTreeOld = async () => {
	const actualRoot: INode = {
		name: '',
		id: 0,
		children: [1],
		parent: null,
	};

	const companyRoot: INode = {
		name: config.companyName,
		id: 1,
		children: [],
		parent: 0,
		isBranch: true,
	};

	const treeData = [actualRoot, companyRoot];
	try {
		let companies = await api.companies();
		config.debug && console.log({ companies });

		if (companies.data) {
			for (let company of companies.data) {
				if (company && company.id) {
					const currentCompanyNode: INode = {
						name: company.name ?? 'Unknown Company',
						id: `company_${company.id}`,
						children: [],
						parent: 1,
						isBranch: true,
					};
					const subTreeData = await buildSiteTreeOld(company.id);
					const subTreeIds = subTreeData.directChildrenIds;

					// Add current item
					companyRoot.children.push(currentCompanyNode.id);
					treeData.push(currentCompanyNode);

					// Add the current item sub-tree
					currentCompanyNode.children = subTreeIds;
					treeData.push(...subTreeData.tree);
				}
			}
		}
	} catch (e) {
		console.warn({ e });
	}

	config.debug && console.log({ treeData });

	return treeData;
};

const buildLocationTree = ( locationNodes: ChildNode[], installationLabel:string ) => {
	const treeNodes: INode[] = [];
	const directChildrenOfInstallation: (string | number)[] = [];

	for (let location of locationNodes) {
		const parentLabel = location.parentId ? 
			`${locationType}_${location.parentId}` 
			: 
			installationLabel;

		const currentLocationNode: INode = {
			name: location.description ?? 'Unknown location',
			id: `${locationType}_${location.id}`,
			children: [],
			parent: parentLabel,
			isBranch: true,
		};

		// Add current node
		treeNodes.push(currentLocationNode);
		if ( location.parentId === null ) {
			directChildrenOfInstallation.push(currentLocationNode.id)
		}

		// Add direct children to current node
		currentLocationNode.children = location.childrenIds.map( id => `location_${id}` );

		if (location.childrenIds.length === 0) {
			currentLocationNode.isBranch = false;
		}
		// console.log( 'currentLocationNode', currentLocationNode );
	}

	return {
		tree: treeNodes,
		directChildrenIds: directChildrenOfInstallation,
	};	
}

const buildInstallationTree = ( installations:InstallationLevelNode[], siteId:number ) => {
	const treeNodes: INode[] = [];
	const installationNodesIds: (string | number)[] = [];

	for (let installation of installations) {
		const installationName = installation.installationName ? 
			installation.installationName
			:  ( installation.description ?? 'Unknown Installation' );
		const locationNodes = installation.childLocationNodes;
		// direct children of a site can be either installation with children location nodes or areas/units/other type of locations.
		// name the id with the proper node type either installation or location
		// this is important for the fetching of the equipment/device type matrix data for Risks screen
		const nodeType = !installation.installationName && installation.locationType ? locationType : installationType;
		const nodeLabel = `${nodeType}_${installation.id}`;

		if (installation.id) {
			const currentInstallationNode: INode = {
				name: installationName,
				id: nodeLabel,
				children: [],
				parent: `${siteType}_${siteId}`,
				isBranch: true,
			};

			const subTreeData = buildLocationTree(locationNodes, nodeLabel);

			// Add current node
			treeNodes.push(currentInstallationNode);
			installationNodesIds.push(currentInstallationNode.id);

			// Add subtree
			currentInstallationNode.children = subTreeData.directChildrenIds;
			treeNodes.push(...subTreeData.tree);

			if (subTreeData.directChildrenIds.length === 0) {
				currentInstallationNode.isBranch = false;
			}
		}
	}

	return {
		tree: treeNodes,
		directChildrenIds: installationNodesIds,
	};
}

const buildSiteTree = (
	siteStructures:SiteStructureWithNodes[], 
	companyId: number
) => {
	const treeNodes: INode[] = [];
	const sitesNodesIds: (string | number)[] = [];

	for (let structure of siteStructures) {
		const site = structure.site;
		const locationNodes = structure.locationNodes;

		if (site && site.id) {
			const currentSiteNode: INode = {
				name: site.name ?? 'Unknown Site',
				id: `${siteType}_${site.id}`,
				children: [],
				parent: `${companyType}_${companyId}`,
				isBranch: true,
			};

			const subTreeData = buildInstallationTree(locationNodes, site.id);

			// Add current node
			treeNodes.push(currentSiteNode);
			sitesNodesIds.push(currentSiteNode.id);

			// Add subtree
			currentSiteNode.children = subTreeData.directChildrenIds;
			treeNodes.push(...subTreeData.tree);

			if (subTreeData.directChildrenIds.length === 0) {
				currentSiteNode.isBranch = false;
			}
		}
	}

	return {
		tree: treeNodes,
		directChildrenIds: sitesNodesIds,
	};
};

export const buildCompaniesTree = ( companiesTreeStructures: CompanyTreeStructures[] ) => {
	const actualRoot: INode = {
		name: '',
		id: 0,
		children: [1],
		parent: null,
	};

	const companyRoot: INode = {
		name: config.companyName,
		id: 1,
		children: [],
		parent: 0,
		isBranch: true,
	};

	const treeData = [actualRoot, companyRoot];

	companiesTreeStructures.forEach( structure => {
		const company = structure.company;
		const siteStructures = structure.siteStructures;
		if ( company && company.id ) {
			const currentCompanyNode: INode = {
				name: company.name ?? 'Unknown Company',
				id: `${companyType}_${company.id}`,
				children: [],
				parent: 1,
				isBranch: true,
			};
			const subTreeData = buildSiteTree(siteStructures, company.id);
			const subTreeIds = subTreeData.directChildrenIds;

			// Add current item
			companyRoot.children.push(currentCompanyNode.id);
			treeData.push(currentCompanyNode);

			// Add the current item sub-tree
			currentCompanyNode.children = subTreeIds;
			treeData.push(...subTreeData.tree);

			if (subTreeData.directChildrenIds.length === 0) {
				currentCompanyNode.isBranch = false;
			}
		}
	} );
	
	config.debug && console.log({ companiesTreeStructures });
	config.debug && console.log({ treeData });

	return treeData;

}

const findNodeById = (id: string | number, tree: INode[]) => {
	return tree.find((item) => item.id === id);
};

export const getPathToNode = (node: INode, tree: INode[]) => {
	let currentParentId = node.parent;
	let currentPrettyPath = [node];

	while (currentParentId !== null) {
		let currentParentNode = findNodeById(currentParentId, tree);

		if (!currentParentNode) {
			break;
		}

		currentPrettyPath.push(currentParentNode);
		currentParentId = currentParentNode.parent;
	}

	console.log({ currentPrettyPath });

	return currentPrettyPath.reverse();
};

export const extractCompanyIdSiteIdLocationIdFromPath = ( path:INode[] ) => {
	let companyId = 0;
	let siteId = 0;
	let installationId = 0;
	let locationId = 0;

	path.forEach( node => {
		let id = node.id.toString();
		if ( id.includes( 'company_' ) ) {
			companyId = +(id.split('company_')[1]);
		}

		if ( id.includes( 'site_' ) ) {
			siteId = +(id.split( 'site_' )[1]);
		}

		if ( id.includes( 'location_' ) ) {
			locationId = +(id.split( 'location_' )[1]);
		}

		if ( id.includes( 'installation_' ) ) {
			installationId = +(id.split( 'installation_' )[1]);
		}

	}  );

	return {
		companyId,
		siteId,
		locationId,
		installationId
	}
}


const buildInternalNode = (node:LocationNodeMainInfo, parentId: number | null) => {
	let childNodeList: ChildNode[] = [];	
	recursiveChildrenDrill( node, parentId );

	function recursiveChildrenDrill(node:LocationNodeMainInfo, parentId: number | null) {
		const directNodeChildren:number[] = [];
		if ( node.childLocationNodes ) {

			node.childLocationNodes.forEach( ( childNode ) => {
				if ( node.id && childNode.id ) {
					directNodeChildren.push( childNode.id );
					recursiveChildrenDrill(childNode, node.id);
				}	
			});			
		}

		if ( node.id ) {
			childNodeList.push(
				{
					id: node.id,
					description: node.description,
					locationType: node.locationType,
					hardwareAssetStructure: node.hardwareAssetStructure,
					softwareAssetStructure: node.softwareAssetStructure,	
					parentId: parentId,
					childrenIds: directNodeChildren		
				}
			);	
		}
	}

	// console.log('childNodeList', childNodeList);
	return childNodeList;
}

export const getChildrenAssets = ( structure: ChildNode[], nodeId: number ) => {
	const nodeChildIds = structure.find( node => node.id === nodeId )?.childrenIds;
	let descendentIds:number[] = [];
	let childrenHwAssets: HardwareAssetStructure[] = [];
	let childrenSwAssets: SoftwareAssetStructure[] = [];
	if ( nodeChildIds ) {
		recursiveAdd( nodeChildIds );
	}
	
	function recursiveAdd ( childrenIds:number[] ) {
		descendentIds = [ ...descendentIds, ...childrenIds ];
		childrenIds.forEach( id => {
			const node = structure.find( node => node.id === id );
			if ( node?.childrenIds ) {
				if ( node.hardwareAssetStructure ) {
					childrenHwAssets = [ ...childrenHwAssets, ...node.hardwareAssetStructure ]
				}

				if ( node.softwareAssetStructure ) {
					childrenSwAssets = [ ...childrenSwAssets, ...node.softwareAssetStructure ]
				}
				recursiveAdd( node.childrenIds );
			}
		} )
	}

	// console.log( 'descendentIds', descendentIds );
	// console.log( 'childrenHwAssets', childrenHwAssets );
	// console.log( 'childrenSwAssets', childrenSwAssets );
	return {
		childrenHwAssets,
		childrenSwAssets
	}
}

const flattenChildLocationNodesStructure = (
	installationTopChildren:LocationNodeMainInfo[] | null | undefined
) => {
	let newChildStructure:ChildNode[] = []
	if ( installationTopChildren ) {
		// console.log( 'installationTopChildren', installationTopChildren );

		installationTopChildren.forEach( node => {
			const internalNode = buildInternalNode(node, null);
			newChildStructure = [ ...newChildStructure, ...internalNode ];
		} )
	}
	
	return newChildStructure;
}

export const buildLocationNodesStructurePerCompany = (data:SiteStructure[]):CompanyTreeStructures => {
	let company:ICompanyStructure | undefined = undefined;
	let siteWithNodes:SiteStructureWithNodes[] = [];
	let site:Site | null = null;

	data.forEach( ( item ) => {
		if ( !company ) {
			company = {
				id: item.companyStructure?.id,
				name: item.companyStructure?.name,
				description: item.companyStructure?.description
			}
		}
		site = {
			id: item.id,
			name: item.name,
			description: item.description,
		}
		let installations:InstallationLevelNode[] = [];
		if ( item.locationNodesStructure ) {

			installations =	item.locationNodesStructure.map( ( structure ) => {
				
				const installation = structure.locationNodeMainInfo;
				const flatChildNodes = flattenChildLocationNodesStructure( installation?.childLocationNodes);

				return {
					id: installation?.id,
					installationName: installation?.installationName,
					installationNumber: installation?.installationNumber,
					description: installation?.description,
					locationType: installation?.locationType ? installation.locationType : 'Installation',
					childLocationNodes: flatChildNodes,
					hardwareAssetStructure: installation?.hardwareAssetStructure,
					softwareAssetStructure: installation?.softwareAssetStructure
				};
			})
		}

		siteWithNodes.push({
			site: site,
			locationNodes: installations
		});
	} )

	return {
		company: company,
		siteStructures: siteWithNodes
	};
}

export const getAssetsForInstallationAndItsChildren = ( installation:InstallationLevelNode ) => {
	let hwAssets:HardwareAssetStructure[] = [];
	let swAssets:SoftwareAssetStructure[] = [];
	// add the assets on installation level
	if ( installation.hardwareAssetStructure ) {
		hwAssets = [ ...hwAssets, ...installation.hardwareAssetStructure ];
	}

	if ( installation.softwareAssetStructure ) {
		swAssets = [ ...swAssets, ...installation.softwareAssetStructure ];
	}

	// add the assets attached to the children of the installation
	if ( installation.childLocationNodes ) {
		installation.childLocationNodes.forEach( child => {
			if ( child.hardwareAssetStructure ) {
				hwAssets = [ ...hwAssets, ...child.hardwareAssetStructure ]
			}

			if ( child.softwareAssetStructure ) {
				swAssets = [ ...swAssets, ...child.softwareAssetStructure ]
			}
		} )
	}

	return {
		hwAssets,
		swAssets
	}
}