import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { buildCompaniesTree } from '@/lib/tree-utils';
import { INode } from 'react-accessible-treeview';
import { Icons } from './shadcn-ui/icons';
import { Tree } from './ui/Tree';
import CollapsibleDemo from '@/components/ui/CollapsibleDemo';
import { companiesTreeStructuresAtom, selectedPathAtom } from '@/state/hierarchy-tree';

export function LeftSidebar() {
	const [treeData, setTreeData] = useState<INode[]>([]);
	const companiesTreeStructures = useRecoilValue(companiesTreeStructuresAtom)
	const selectedPath = useRecoilValue(selectedPathAtom);
	const selectedIds = selectedPath.map(node => node.id);
	const initialState = {
		selected: selectedIds.slice(-1),
		expanded: selectedIds, 
	};

	useEffect(() => {
		if ( treeData.length === 0 && companiesTreeStructures ) {
			const hierarchyTree = buildCompaniesTree( companiesTreeStructures );
			setTreeData( hierarchyTree );
		}
	}, [companiesTreeStructures, treeData]);

	return (
		<CollapsibleDemo>
			{treeData.length ? (
				<Tree data={treeData} initialState={initialState} />
			) : (
				<Icons.Spinner className="mx-4 h-4 w-4 animate-spin" />
			)}
		</CollapsibleDemo>
	);
}
