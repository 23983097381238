import config from '@/config';
import { Configuration, LogLevel } from '@azure/msal-browser';
// 'https://bcpcontinuoty.b2clogin.com/bcpcontinuoty.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN/oauth2/v2.0/authorize',
// 'https:/login.microsoftonline.com/5f2be725-b942-433d-9ac5-1246bceca61d',
// 'https://login.microsoftonline.com/common/oauth2/v2.0/token'

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
	auth: {
		clientId: config.auth_clientId,
		authority: config.auth_authority,
		knownAuthorities: config.auth_knownAuthorities,
		redirectUri: '/',
		postLogoutRedirectUri: '/',
	},
	system: {
		allowNativeBroker: false, // Disables WAM Broker
		loggerOptions: {
			loggerCallback: (
				level: LogLevel,
				message: string,
				containsPii: boolean
			) => {
				console.log('LOGGER: ', message);
			},
			logLevel: LogLevel.Info,
		},
	},
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const request = {
	scopes: config.auth_scopes,
};

// Add here the endpoints for MS Graph API services you would like to use.
// export const graphConfig = {
// 	graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
// };
