import React, { useState } from 'react';
import {
	Card,
	CardHeader,
	CardTitle,
	CardContent,
} from '@/components/shadcn-ui/card';
import AssetStatus from '@/components/ui/AssetStatus';
import UpdatesTable from '@/components/UpdatesTable';
import AssetDetails from '@/components/AssetDetails';
import { useSelectedPathText } from '@/lib/useSelectedPathText';
import {
	updatesTableData,
	updatesTableDecidedData,
} from '@/mocks/data/updatesTableData'; //updatesHistoryTableData,
import CustomTabs from '@/components/ui/CustomTabs';
import WhiteContainer from '@/components/ui/WhiteContainer';
import { Icons } from '@/components/shadcn-ui/icons';
// import ActionStatus from '@/components/ui/ActionStatus';
// import UpdatesHistoryTable from '@/components/UpdatesHistoryTable';
import VulnerabilitiesFilter from '@/components/ui/VulnerabilitiesFilter';

const Vulnerabilities = () => {
	function handleSelectedTab(selectedTab: string) {
		setCurrentTab(selectedTab);
	}

	const [currentTab, setCurrentTab] = useState('Undecided');
	const selectedPathTitle = useSelectedPathText();

	return (
		<div className="flex flex-col overflow-hidden w-full py-4 text-2xl tracking-tight">
			<h1 className="mb-6 text-blue capitalize">{selectedPathTitle}</h1>
			<CustomTabs
				tabsOptions={[
					{
						option: 'Undecided',
						icon: <Icons.Undecided className="mr-2 h-4 w-4" />,
					},
					{ option: 'All', icon: <Icons.Decided className="mr-2 h-4 w-4" /> },
				]}
				onTabSelect={(selectedTab) => {
					handleSelectedTab(selectedTab);
				}}
				selectedTab={currentTab}
			>
				<div className="flex overflow-hidden rounded-lg border border-t-0 bg-white h-full">
					{currentTab === 'All' && <VulnerabilitiesFilter />}
					<div className="h-full w-full py-4">
						<div className="h-1/2 flex flex-col mx-3 p-y-2 w-auto overflow-auto">
							<UpdatesTable
								data={
									currentTab === 'Undecided'
										? updatesTableData
										: updatesTableDecidedData
								}
								currentTab={currentTab}
							/>
						</div>
						<div className="h-1/2 flex flex-row mx-3 mt-2 overflow-auto">
							{currentTab === 'Undecided' ? (
								<div className="flex-auto w-1/6 mr-4">
									<Card className="h-full overflow-auto ">
										<CardHeader>
											<CardTitle className="text-content-grey">
												Number of {currentTab} vulnerabilities
											</CardTitle>
										</CardHeader>
										<CardContent className="p-6 pt-0 text-sm overflow-auto">
											<AssetStatus
												name="Critical"
												value={4}
												className="text-purple"
											/>
											<AssetStatus
												name="High"
												value={15}
												className="text-red"
											/>
											<AssetStatus
												name="Medium"
												value={6}
												className="text-orange"
											/>
											<AssetStatus
												name="Low"
												value={7}
												className="text-yellow"
											/>
										</CardContent>
									</Card>
								</div>
							) : (
								''
							)}

							<div className="flex-auto w-5/6">
								<AssetDetails />
							</div>
						</div>
					</div>
				</div>
			</CustomTabs>
		</div>
	);
};
export default Vulnerabilities;
