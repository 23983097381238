import { useState, useEffect, useCallback } from 'react';
import BubbleChart from '@/components/ui/BubbleChart';
import { useRecoilValue } from 'recoil';
import { LifecycleChartsRowHeader } from '@/components/ui/LifecycleChartsRowHeader';
import { LifecycleChartsRow } from '@/components/ui/LifecycleChartsRow';
import { BubbleDataPoint } from 'chart.js';
import api from '@/middleware/api';
import { useSelectedPathText } from '@/lib/useSelectedPathText';
import { 
	RiskAnalysisResponseIEnumerableWebResponse,
	LifeCycleMatrixHardwareComponent,
	LifeCycleMatrixSoftwareComponent
} from '@/middleware/GeneratedClient';
import { currentLocationIdSelector, currentInstallationIdSelector } from '@/state/hierarchy-tree';
import WhiteContainer from '@/components/ui/WhiteContainer';

export interface RisksBubbleDataPoint extends BubbleDataPoint {
	msp: string[];
	xTickLabel: string | undefined;
	yTickLabel: string | undefined;
}

const getRiskApiData = async (
	apiMethod: Promise<RiskAnalysisResponseIEnumerableWebResponse>
) => {
	try {
		const apiRiskData = await apiMethod;
		return { data: apiRiskData.data, error: null };
	} catch (error) {
		console.error("An error occurred while fetching data:", error);
		return { data: null, error: error || "An error occurred" };
	}
};

const Risks = () => {

	const [RiskAnalysisResponse, setRiskAnalysisResponse] = useState<RisksBubbleDataPoint[]>([]);
	const currentInstallationId = useRecoilValue( currentInstallationIdSelector );
	const [ swMatrixData, setSwMatrixData ] = useState<LifeCycleMatrixSoftwareComponent[] | null | undefined>(null);
	const currentLocationId = useRecoilValue( currentLocationIdSelector );
	const [ hwMatrixData, setHwMatrixData ] = useState<LifeCycleMatrixHardwareComponent[] | null | undefined>(null);

	const getYTickValue = useCallback((consequence?: string) => {
		let y: number;
		switch (consequence) {
			case "Negligible": y = 1; break;
			case "Minor": y = 2; break;
			case "Moderate": y = 3; break;
			case "Major": y = 4; break;
			case "Catastrophic": y = 5; break;
			default: y = 0; break
		}
		return y;
	}, []);

	const getXTickValue = useCallback((likelihood?: string) => {
		let x: number;
		switch (likelihood) {
			case "Likely": x = 1; break;
			case "Possible": x = 2; break;
			case "Unlikely": x = 3; break;
			case "Rare": x = 4; break;
			default: x = 0; break
		}
		return x;
	}, []);

	useEffect(() => {
		const fetchRiskData = async () => {
			// TODO: introduce dynamic value for auditId
			// '9e714a37-90fb-4dd5-9d37-d198a55fb5e1'  works only for dev and test env
			const responseData = await getRiskApiData(api.riskAnalysis('9e714a37-90fb-4dd5-9d37-d198a55fb5e1')); // AuditIdМ: temporary hardcoded to get the data 	
			
			let chartData: RisksBubbleDataPoint[] = [];
			responseData.data?.forEach(item => {
				item.details?.forEach((details) => {
					if (details.riskValue !== null && details.riskValue !== undefined && details.riskValue &&
						details.msp !== null && details.msp !== undefined && details.msp.length > 0) {
							
						chartData.push({
							x: getXTickValue(details.likelihood),
							y: getYTickValue(item.consequence),
							r: details.riskValue,
							msp: details.msp,
							xTickLabel: details.likelihood,
							yTickLabel: item.consequence
						});
						
					}
				});
			});

			setRiskAnalysisResponse(chartData);
		}

		fetchRiskData();	
	}, [getXTickValue, getYTickValue]);

	useEffect( () => {
		const getEquipmentTypeMatrixData = async() => {
			try {
				const response = await api.lifeCycleMatrixSoftwareComponent( currentInstallationId );
				setSwMatrixData(response.data);
			}
			catch (error) {
				// alert( 'Could not fetch equipment type matrix for location with id ' + currentInstallationId );
				console.log( 'error', error );
				setSwMatrixData(null);
			}
		}
		const getDeviceTypeMatrixData = async() => {
			try {
				const response = await api.lifeCycleMatrixHardwareComponent( currentLocationId );
				console.log( 'data', response.data );
				setHwMatrixData(response.data);
			}
			catch (error) {
				// alert( 'Could not fetch device type matrix for location with id ' + currentLocationId );
				console.log( 'error', error );
				setHwMatrixData(null);
			}
		}

		// direct children of a site can be either installation with children location nodes or locations of type areas/process cell/unit/other.
		// when we have an installation, its id is 'installation_{id}' to perform getEquipmentTypeMatrixData
		// when we have location of any type, its id is 'location_{id}' to perform getDeviceTypeMatrixData

		// when the selected node is a location => getDeviceTypeMatrixData
		if ( currentLocationId ) {
			getDeviceTypeMatrixData();
		}
		else {
			setHwMatrixData(null);
		}
		// when the selected node is an installation => getEquipmentTypeMatrixData
		if ( currentInstallationId &&  !currentLocationId ) {
			getEquipmentTypeMatrixData();
		}

	}, [ currentLocationId, currentInstallationId ] )

	const selectedPathTitle = useSelectedPathText();

	return (
		<div className="max-h-[calc(100%-2rem)] w-full py-4">
			<h1 className="mb-6 text-blue capitalize text-2xl tracking-tight">{selectedPathTitle}</h1>
			<WhiteContainer styles="flex flex-row space-x-6 h-full box-border p-7">
				<WhiteContainer styles="flex-1 w-1/2 overflow-y-auto h-auto min-h-[33vh] max-h-[calc(100%-.4rem)] box-border p-6">
					<h2 className="sticky top-0 z-1 bg-white mt-1 mb-3 pb-3 text-lg font-semibold tracking-wide text-content-grey">
						{'Lifecycle matrix for Company'}
					</h2>
					<h3 className="text-lg font-semibold leading-none tracking-tight text-gray-700">Hardware</h3>
					<div className="flex-auto">
					{
						!hwMatrixData ? 
							<div className='mt-2'>
								<p>Choose an area or process cell from the hierarchy.</p>
							</div> 
						: 
							<>
								<LifecycleChartsRowHeader />
								{
									hwMatrixData.map( ( row, index ) => {
										return (
											<LifecycleChartsRow 
												key={`hwMatrixRow-${index}`}
												type='deviceType'
												title={ row.deviceTypeName || 'Unknown' }
												details={ row.productLifeCycleDetails || [] } />
										)
									} )
								}
							</>
					}
					</div>
					<h3 className="my-4 text-lg font-semibold leading-none tracking-tight text-gray-700">Software</h3>
					<div className="flex-auto">
						{
						!swMatrixData ? 
							<div className='mt-2'>
								<p>Choose a top location from the hierarchy.</p>
							</div> 
						: 
							<>
								<LifecycleChartsRowHeader />
								{ 
									swMatrixData.map( ( row, index ) => {
										return (
												<LifecycleChartsRow 
													key={`swMatrixRow-${index}`}
													type='equipmentType'
													title={ row.equipmentTypeName }
													details={ row.productLifeCycleDetails || [] } />
											
										)
									} )
								}
							</>
					}
					</div>
				</WhiteContainer>
				<WhiteContainer styles='flex-1 w-1/2 overflow-y-auto h-auto min-h-[33vh] max-h-[calc(100%-.4rem)] box-border p-6'>
					<h2 className="sticky top-0 z-1 bg-white mt-1 mb-3 pb-3 text-lg font-semibold tracking-wide text-content-grey">
						{'Risk analysis for Company'}
					</h2>
					<BubbleChart chartData={RiskAnalysisResponse} />
				</WhiteContainer>
			</WhiteContainer>
		</div>

	);
};
export default Risks;
