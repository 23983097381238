import { Outlet } from 'react-router-dom';
import DashboardHeader from '@/components/DashboardHeader';

const Ingestion = () => {
	return (
		<div className="bg-slate-200 h-screen flex flex-col overflow-hidden">
			<DashboardHeader />
			<Outlet />
		</div>
	);
};
export default Ingestion;