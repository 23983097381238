import { useNavigate, createSearchParams } from 'react-router-dom';
import { lifecycleStatuses } from '@/lib/lifecycle-utils';
import LifecycleAreaChart from './LifecycleAreaChart';
import { ProductLifeCycleDetail } from '@/middleware/GeneratedClient';
import { Category, IFilter, SearchParams, hwAssetType, swAssetType } from '@/types/assets';

export interface LifecycleChartRowProps {
	title?: string | null;
	details: ProductLifeCycleDetail[],
	type: Category
}

export const LifecycleChartsRow: React.FC<LifecycleChartRowProps> = ({
	title,
	details,
	type
}) => {
	const navigate = useNavigate();

	return (
		<div className="flex flex-row h-16">
			<div className="w-32">
				<h3>{title || 'Unknown'}</h3>
			</div>
			{lifecycleStatuses.map((status, index) => {
				// const emptyItems = [1, 2, 3, 4, 5];
				// const values = emptyItems.map(() => Math.floor(Math.random() * 10));
				// console.log({ values });

				const specificLifecycleDetail = details.filter( detail => detail.productLifeCycleName?.toLocaleLowerCase() === status )[0];
				let chartValues = [ 0, 0, 0, 0 ];

				if ( specificLifecycleDetail && specificLifecycleDetail.historyDetails ) {
					chartValues = [1, 2, 3, 4].map( (_, i) => {
						if (  
							specificLifecycleDetail.historyDetails && specificLifecycleDetail.historyDetails[i] &&
							specificLifecycleDetail.historyDetails[i].productLifeCycleStatusCount
						) {
							return specificLifecycleDetail.historyDetails[i].productLifeCycleStatusCount as number;
						}
						else {
							return 0;
						}
					})
				}
				return (
					<div key={`${title}-${index}`} className="w-[76px] h-12 mr-1 bg-slate-100 cursor-pointer">
						<LifecycleAreaChart 
							values={chartValues.reverse()} 
							status={status} 
							onClick={ () => { 
								const filtersToSend:IFilter[] = [
									{ 
										category: 'lifecycleStatus',
										filter: specificLifecycleDetail.productLifeCycleName as string
									},
									{
										category: type,
										filter: title as string
									}
								];
								const data:SearchParams = {
									filters: filtersToSend,
									tab: type === 'deviceType' ? hwAssetType : swAssetType
								}
								navigate({
									pathname: '/dashboard/assets', 
									search: createSearchParams({
										data: JSON.stringify(data)
									}).toString()
								})
							} } />
					</div>
				);

				// return (
				// 	<div key={index} className="w-[76px] h-12 mr-1 bg-slate-100">
				// 		<LifecycleAreaChart values={values} status={status} />
				// 	</div>
				// );
			})}
		</div>
	);
};
