import config from "@/config";
import { msalInstance } from '@/index';
import { request } from "@/middleware/authConfig";
import { DefaultConstant } from "@/constants";


export const auth = {
	getApiKey: async () => {
		const apiKey = await fetch( `${ config.authApiUrl }/Security/GetApiKey?userKey=user&applicationKey=app`, {
			headers: {
				StorageId: localStorage.getItem(DefaultConstant.API_KEY_STORAGE_ID) ||  config.storageId
			}
		} )
		const apiKeyJson = await apiKey.json();
		const result = apiKeyJson?.data?.apiKey as number;

		return result.toString();
	},
	getAccessToken: async () => {
		let accessToken: string | null = null;
		try {
			const authResult = await msalInstance.acquireTokenSilent(request);
			// Do something with the tokenResponse
			accessToken = authResult.accessToken;
			console.log( 'acquireTokenSilent', accessToken );
			// return accessToken
		}
		catch ( error ) {
			// To acquire a token outside of a React component you can call 
			// acquireTokenSilent but shouldn't fall back to interaction if it
			// fails. All interactions should take place underneath the
			// msalProvider component in your component tree
			// src: https://shorturl.at/LPVW9
			console.log( 'acquireTokenSilent error', error );
		}
		finally {
			return accessToken;
		}
	},
	signIn: async () => {
		try {
			// sessionStorage.setItem( 'bcpLastRoute', window.location.href );
			await msalInstance.loginRedirect(request);
		}
		catch ( error ) {
			console.log( 'login error', error )
		}
	},
	signOut: async () => {
		await msalInstance.logoutRedirect();
	},
	restoreRouteAfterRedirect: () => {
		const bcpLastRoute = sessionStorage.getItem( 'bcpLastRoute' );
		sessionStorage.removeItem( 'bcpLastRoute' );
	
		if ( bcpLastRoute ) {
			window.history.replaceState( undefined, '', bcpLastRoute );
		}
	}
};

export default auth;
