const Surveys: any = {
	"data": [
		{
			"id": 1,
			"surveyName": "Survey 1",
			"surveyStatus": "some status",
		},
		{
			"id": 2,
			"surveyName": "Survey 2",
			"surveyStatus": "some status 2",
		},
		{
			"id": 3,
			"surveyName": "Survey 3",
			"surveyStatus": "some status 3",
		},
		
	],
	"count": 3,
	"success": true,
	"message": "Successfully retreived surveys."
}

export default Surveys;