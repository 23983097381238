import React from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { LifecycleStatus, getLifecycleChartColor } from '@/lib/lifecycle-utils';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend
);

export const chartOptions = {
	responsive: true,
	plugins: {
		legend: {
			display: false,
		},
		tooltip: {
			enabled: false,
		},
	},
	elements: {
		point: {
			pointStyle: 'line',
		},
	},
	scales: {
		x: {
			border: {
				display: false,
			},
			grid: {
				display: false,
			},
			display: false,
		},
		y: {
			border: {
				display: false,
			},
			grid: {
				display: false,
			},
			display: false,
		},
	},
	layout: {
		padding: 0,
		autoPadding: false,
	},
	aspectRatio: 3,
};

export interface LifecycleAreaChartProps {
	values: number[];
	status?: LifecycleStatus;
	onClick?: () => void;
}

const LifecycleAreaChart: React.FC<LifecycleAreaChartProps> = ({
	status: color,
	values,
	onClick
}) => {
	let chartColor = getLifecycleChartColor(color);

	const data = {
		labels: values.map(() => ''),
		datasets: [
			{
				fill: true,
				data: values,
				borderColor: 'transparent',
				backgroundColor: chartColor,
			},
		],
	};

	const currentValue = values[values.length - 1];

	return (
		<div
			onClick={ onClick }
		>
			<div
				className="text-right"
				style={{
					color: chartColor,
				}}
			>
				{currentValue !== 0 ? currentValue : '-'}
			</div>
			<Line options={chartOptions} data={data} />
		</div>
	);
};

export default LifecycleAreaChart;
