import { useRecoilState } from 'recoil';
import { defaultVilocifyState } from '@/state/survey';
import { VilocifyComponent } from '@/middleware/GeneratedClient';
import { Input } from '@/components/shadcn-ui/input';
import { Icons } from './shadcn-ui/icons';

interface SurveyVilocifyElProps {
    vilocifyComponents: VilocifyComponent[];
}

const SurveyVilocifyInput: React.FC<SurveyVilocifyElProps> = ({ vilocifyComponents }) => {

    const [selectedVcfyComponentId, setSelectedVcfyComponentId] = useRecoilState(defaultVilocifyState);

    const clickHandler = (id: number ) => {
        const element = document.getElementById(id.toString());
        if (element && id ) {
          const topPos = element.getBoundingClientRect().top;
          setSelectedVcfyComponentId({ id, top: topPos });
        }
    };

    return (
        <>{vilocifyComponents.map(component => {
            const isSelected = component.id !== undefined && selectedVcfyComponentId.id === component.id;
            return (
                <div
                    id={component.id?.toString()}
                    className={`flex items-center border rounded my-1 p-3 group hover:bg-accent ${isSelected ? "bg-accent" : ""}`}
                    key={component.id}
                    onClick={() => {
                        if (component.id) {
                            clickHandler(component.id);
                        }
                    }}
                >
                    <Input
                        type="radio"
                        className="checked:bg-red h-4 w-4"
                        name="status"
                        required
                        checked={component.id === selectedVcfyComponentId.id}
                        onChange={(e) => {
                            const elementId = component.id === selectedVcfyComponentId.id ? component.id : 0;
                            const element = document.getElementById(elementId.toString());
                            if (element) {
                              const rect = element.getBoundingClientRect();
                              setSelectedVcfyComponentId({
                                id: Number(elementId),
                                top: rect.top
                              });
                            }}}
                        {...(selectedVcfyComponentId !== null ? {
                            value: selectedVcfyComponentId.id,
                        } : {})}
                        onClick={() => {
                            if (component.id) {
                                clickHandler(component.id);
                            }
                        }}
                    />
                    <div className="flex flex-wrap gap-3 justify-between px-2">
                        {(() => {
                            let displayFields = [
                                { label: 'Ver:', value: component.version, key: "version", classes: "pr-1 flex flex-shrink items-center" },
                                {
                                    label: 'End-of-Life:', value: <>
                                        {component.eolReached ?
                                            <Icons.BadgeX className="mx-1 h-auto w-4 stroke-2 text-red cursor-help" title="Component's usage period ended." /> :
                                            <Icons.BadgeCheck className="mx-1 h-auto w-4 stroke-2 text-green cursor-help" title="Component's lifecycle active." />}
                                        {new Date(`${component.eolDate}`).toLocaleDateString(
                                            "en-GB", { day: '2-digit', month: 'short', year: 'numeric' }
                                        )} </>
                                    , key: "eolDate", classes: "flex flex-shrink items-center"
                                },
                                { label: <Icons.Notification className="mr-1 h-4 w-4 inline-flex cursor-help" title="Total notifications for that Vilocify component." />, value: '(' + component.vilocifyNotifications?.length + ')', key: "vilocifyNotifications", classes: "flex flex-shrink items-end" },
                                { label: 'Vendor:', value: component.vendor, key: "vendor", classes: "pr-1 flex flex-shrink items-center" },
                                { label: null, value: component.componentName, key: "componentName", classes: "flex-grow w-full border-t border-gray-100" },
                                { label: 'CPE:', value: component.cpeName, key: "cpeName", classes: "pr-1 flex-grow w-full cursor-help", title: "Common Platform Enumeration (CPE) format: Identifies IT software/hardware for security tracking." },
                            ];

                            return displayFields.map(field => (
                                <div className={`group-hover:bg-accent ${field.classes}`} key={`${component.id}-${field.key}`} title={field.title || undefined}>
                                    <span className={`flex-shrink-0 text-content-grey group-hover:bg-accent ${field.classes}`}>{field.label}</span>
                                    <span className={`font-normal flex-auto text-right group-hover:bg-accent ${field.classes}`}>{field.value}</span>
                                </div>
                            ));
                        })()}
                    </div>
                </div>
            )
        })}</>
    );
};
export default SurveyVilocifyInput;