import { useEffect, ChangeEvent } from 'react';
import { useRecoilState } from 'recoil';
import { defaultVilocifyState } from '@/state/survey';
import { VilocifyComponent } from '@/middleware/GeneratedClient';
import { Card, CardContent } from '@/components/shadcn-ui/card';
import { Input } from '@/components/shadcn-ui/input';
import { Label } from '@/components/shadcn-ui/label';
import { Button } from '@/components/shadcn-ui/button';
import { Icons } from './shadcn-ui/icons';
import SurveyVilocifyInput from './SurveyVilocifyInput';
import { hwAssetType } from '@/types/assets';

interface SurveyVilocifyListProps {
    currentTab: string;
    vilocifyComponents: VilocifyComponent[];
}

const SurveyVilocifyLists: React.FC<SurveyVilocifyListProps> = ({ currentTab, vilocifyComponents }) => {

    const [selectedVcfyComponentId, setSelectedVcfyComponentId] = useRecoilState(defaultVilocifyState);

    const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const typedValue = e.target.value.replace(/[^0-9]/g, '');
        setSelectedVcfyComponentId(prevState => ({...prevState, id: Number(typedValue)}));
    };

    useEffect(() => {
        const elementId = selectedVcfyComponentId.id;
        if (elementId) {
            const element = document.getElementById(elementId.toString());
            if (element) {
                const topPos = element.getBoundingClientRect().top;
                setSelectedVcfyComponentId(prevState => ({ ...prevState, top: topPos }));
            }
        }
    }, [selectedVcfyComponentId.id, setSelectedVcfyComponentId]);

    const matchedComponent = selectedVcfyComponentId ? vilocifyComponents.find((component) => component.id === selectedVcfyComponentId.id) : null;

    return (
        <>{currentTab === hwAssetType ? (
            <Card className="min-w-[15rem] h-auto min-h-[33vh] box-border border-none">
                <CardContent id="vilocifyOverflowDiv" className="py-0 px-2 text-sm w-full h-auto min-h-[33vh] max-h-[calc(100%)] box-border">
                    <div id="stickyHeaderDiv" className='sticky top-0 z-2 mb-3 py-5 px-4 bg-white shadow-md'>
                        <h3 className="mb-5 text-md font-semibold tracking-wide text-content-grey">
                            {'Assign Vilocify component manually or select from the list below:'}
                        </h3>
                        <div className='flex flex-row justify-between'>
                            <div className='flex flex-col gap-y-2 w-1/4'>
                                <Label htmlFor="DeviceNumber" className="shrink ml-5 text-content-grey/90">
                                    {'Add manually'}
                                </Label>
                                <div className='relative flex items-center w-full px-5'>
                                    <Input
                                        id="VilocifyId"
                                        className="flex-grow placeholder:italic placeholder:text-slate-400"
                                        placeholder="Vilocify Id..."
                                        autoCapitalize="none"
                                        autoCorrect="off"
                                        value={selectedVcfyComponentId.id !== null ? selectedVcfyComponentId.id : 0}
                                        onChange={onChangeHandler}
                                    />
                                    {selectedVcfyComponentId !== null && (
                                        <Button onClick={() => setSelectedVcfyComponentId({ id: 0, top: 0 })} variant="ghost" className='absolute top-1/2 right-5 transform -translate-y-1/2 p-1 rounded-md h-6'>
                                            <Icons.Close className='w-4 h-4' />
                                        </Button>
                                    )}
                                </div>
                            </div>
                            <div className='flex flex-auto w-1/2 bg-slate-50 mr-5 p-2 rounded-md text-xs tracking-wide text-content-grey'>
                                <span><Icons.Info className='mr-1 h-4 w-4 stroke-2 text-content-grey' /></span>
                                {matchedComponent ? (
                                    <span>{matchedComponent.componentName}{'  '}{`( ver ${matchedComponent.version} )`}</span>
                                ) : (
                                    <span className='italic'>{'not selected...'}</span>
                                )}

                            </div>
                        </div>
                    </div>
                    <SurveyVilocifyInput vilocifyComponents={vilocifyComponents} />
                    <div className="flex justify-between w-full mt-3 p-2 sticky bottom-0 z-1 bg-white border-t border-gray-100">
                        <Button variant="secondary" size="sm" className='bg-blue rounded text-white text-xs py-1 w-30 tracking-wide font-normal hover:bg-grey hover:text-blue focus:bg-white focus:text-blue'>
                            {'Assign'}
                        </Button>
                        <Button variant="secondary" size="sm" className='mx-auto rounded bg-[#00aa2d] text-white text-xs py-1 w-30 tracking-wide font-normal hover:bg-grey hover:text-blue focus:bg-white focus:text-white'>
                            {'All done go to Assets'}
                        </Button>
                        {/* <div className="rounded bg-slate-200/40 text-blue mt-2 px-2 py-1 h-6 text-xs"><a href="#" className='flex items-center'>{'Go to SW components'}<Icons.ExternalLink className="ml-2 h-3 w-3" /></a></div> */}
                        <Button variant="secondary" size="sm" className='bg-[#95a5a5] rounded text-white text-xs py-1 w-30 tracking-wide font-normal hover:bg-grey hover:text-red focus:bg-white focus:text-blue'>
                            {'Ignore'}
                        </Button>
                    </div>
                </CardContent>
            </Card>
        ) : (
            <div className="m-auto text-center text-gray-500 italic text-xs tracking-wide">No Software data.</div>
        )}</>
    );
};
export default SurveyVilocifyLists;