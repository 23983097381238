import React from 'react';
import {
	Card,
	CardHeader,
	CardTitle,
	CardContent,
} from '@/components/shadcn-ui/card';
import { ColumnDef } from '@tanstack/react-table';
import { DataTable } from '@/components/ui/DataTable';
import { cn } from '@/lib/styling-utils';
import { AssetUpdateData, SeverityItem } from '@/mocks/data/updatesTableData';

const severityClass = 'text-white py-1';

const columns: ColumnDef<AssetUpdateData>[] = [
	{
		accessorKey: 'severity',
		header: 'Severity',
		cell(props) {
			const severityItem = props.getValue() as SeverityItem[];
			const content = severityItem.map((item, index) => (
				<React.Fragment key={index}>
					<span className={cn(severityClass, item.className, "rounded-full w-3 h-3")}></span>
					<span className="ml-2">{item?.value}</span>
				</React.Fragment>
			));

			return (
				<div  className="flex border-r items-center justify-left">{content}</div>
			)
		},
	},
	{
		accessorKey: 'id',
		header: 'Age (days)',
		cell(props) {
			const ageItem = props.getValue() as AssetUpdateData[];
			return (
				<div className="flex border-r items-center justify-left">{<>{ageItem}</>}</div>
			);

		},
	},
	{
		accessorKey: 'description',
		header: 'Title',
	},
	{
		accessorKey: '',
		header: 'nr of assets',
	},
	{
		accessorKey: 'deleted',
		header: 'Score',
	},
	{
		accessorKey: 'cve',
		header: 'CVE',
		cell(props) {
			return (
				<a className='text-blue/80' href='#' target="_blank">{props.getValue() as string}</a>
			);
		},
	},
	{
		accessorKey: 'cwe',
		header: 'CWE',
		cell(props) {
			return (
				<a className='text-blue/80' href='#' target="_blank">{props.getValue() as string}</a>
			);
		},
	},
	{
		accessorKey: 'mitigatingFactor',
		header: 'Report',
		cell(props) {
			return (
				<a className='text-blue/80' href='#' target="_blank">{props.getValue() as string}</a>
			);
		},
	},
];


interface UpdatesTableProps {
	data: AssetUpdateData[],
	currentTab?: string
}

const UpdatesTable = ({ data, currentTab }: UpdatesTableProps) => {
	return (
		<Card className="flex-auto w-full">
			<CardHeader>
				<CardTitle className="text-content-grey">Vulnerabilities ({currentTab})</CardTitle>
			</CardHeader>
			<CardContent className="overflow-hidden p-6 pt-0 bg-sm">
				<DataTable type='compact' columns={columns} data={data} />
			</CardContent>
		</Card>
	);
};

export default UpdatesTable;
