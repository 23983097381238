import { useState } from 'react';
import TreeView, { INode } from 'react-accessible-treeview';
import { Play, TouchpadIcon } from 'lucide-react';
import {
	getPathToNode,
} from '@/lib/tree-utils';

import { useRecoilState } from 'recoil';
import { selectedPathAtom } from '@/state/hierarchy-tree';

import { ChevronsUpDown } from 'lucide-react';
import { Button } from '@/components/shadcn-ui/button';
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
} from '@/components/shadcn-ui/command';
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '@/components/shadcn-ui/popover';

export interface TreeProps {
	data: INode[],
	initialState?: {
		selected?: Array<string | number>;
		expanded?: Array<string | number>;
	};
}


export function Tree({ data, initialState }: TreeProps) {
	const [selectedPath, setSelectedPath] = useRecoilState(selectedPathAtom);
	const [open, setOpen] = useState(false);
	const [value, setValue] = useState('');

	function changeNode(element: INode) {
		const path = getPathToNode(element, data);
		setSelectedPath(path);
	}

	return (
		<>
			<Popover open={open} onOpenChange={setOpen}>
				<PopoverTrigger asChild>
					<Button
						variant="secondary"
						role="combobox"
						aria-expanded={open}
						className="w-[200px] justify-between mx-4"
						size='sm'>
						{value
							? data.find((node) => node.name === value)?.name
							: 'Search in tree...'}
							<ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
					</Button>
				</PopoverTrigger>
				<PopoverContent className="w-auto min-w-[200px] p-0 mx-4 overflow-y-scroll max-h-[75vh]">
					<Command>
						<CommandInput placeholder="Search..." />
						<CommandEmpty>Nothing found.</CommandEmpty>
						<CommandGroup>
							{data.map((node) => {
								let parentObject;
								let output = <span>{node.name}</span>;
								const nameOccurrences = data.reduce((count, item) => {
									if (item.name === node.name) {
										count += 1;
									}
									return count;
								}, 0);
								if (nameOccurrences > 1) {
									parentObject = data.find((item) => item.id === node.parent);
									if (parentObject) {
										output = (
											<span>
												{node.name}{' '}
												<span className="text-xs italic">
													({parentObject?.name})
												</span>
											</span>
										);
									}
								}
								let nodeValue = node.name;
								if (parentObject?.name){
									nodeValue = node.name+parentObject?.name;
								}
								return (
									<CommandItem
										key={node.id}
										onSelect={() => {
											setValue('');
											changeNode(node);
											setOpen(false);
										}}
										value={nodeValue}
									>
										{output}
									</CommandItem>
								);
							})}
						</CommandGroup>
					</Command>
				</PopoverContent>
			</Popover>

			<TreeView
				data={data}
				defaultExpandedIds={initialState?.expanded}
				defaultSelectedIds={initialState?.selected}
				className="basic pl-4"
				aria-label="basic example tree"
				selectedIds={
					selectedPath[selectedPath.length - 1]?.id
						? [selectedPath[selectedPath.length - 1]?.id]
						: undefined
				}
				expandedIds={selectedPath.map((item) => item.id)}
				nodeRenderer={({ element, getNodeProps, level, handleSelect }) => {
					const nodeProps = getNodeProps();
					return (
						<div {...nodeProps} style={{ paddingLeft: 20 * (level - 1) }}>
							{element.isBranch ? (
								nodeProps.className.indexOf('--expanded') !== -1 ? (
									<Play size={16} className="rotate-90" />
								) : (
									<Play size={16} />
								)
							) : level === 3 ? (
								<div className="p-1"></div>
							) : (
								<TouchpadIcon size={16} />
							)}
							<span className="pl-2">{element.name}</span>
						</div>
					);
				}}
				onNodeSelect={({ element }) => changeNode(element)}
			/>
		</>
	);
}