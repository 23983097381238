import { Button } from '@/components/shadcn-ui/button';
import WhiteContainer from '@/components/ui/WhiteContainer';
import { PropsWithChildren } from 'react';

interface ModalProps {
	isOpen: boolean;
	onClose: () => void;
	confirmActionLabel?: string;
	rejectActionLabel?: string;
}

const Modal: React.FC<PropsWithChildren<ModalProps>> = ({
	isOpen,
	onClose,
	children,
	confirmActionLabel = 'Save',
	rejectActionLabel = 'Cancel'
}) => {
	if (!isOpen) {
		return null;
	}

	return (
		<div className=" z-50 modal bg-black/50 fixed top-0 left-0 right-0 bottom-0">
			<div className="modal-content absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
				<WhiteContainer styles="flex flex-col w-1/4 overflow-y-auto pb-4 px-6 w-auto min-w-[500px] pb-[80px]">
					{children}
					<Button
						variant="secondary"
						size="sm"
						className="z-50 w-30 ml-0 mt-4 absolute bottom-4 rounded bg-blue text-white text-xs py-1 w-32 tracking-wide font-normal hover:bg-grey hover:text-blue focus:bg-white focus:text-white"
						onClick={onClose}
					>
						{confirmActionLabel}
					</Button>
					<Button
						onClick={ onClose }
						variant="secondary"
						size="sm"
						className="z-50 w-30 ml-0 mt-4 absolute bottom-4 rounded bg-red/80 text-white text-xs py-1 w-32 tracking-wide font-normal hover:bg-grey hover:text-blue focus:bg-white focus:text-white right-6"
					>
						{rejectActionLabel}
					</Button>
				</WhiteContainer>
			</div>
		</div>
	);
};

export default Modal;
