const Audits2: any = {
	"data": [
		{
			"id": 47,
			"auditer": "Gabriel van der Merwe",
			"auditDate": "2023-07-05",
			"location": {
				"id": 92,
				"installationName": "Sims, Lucero and Guerra",
				"installationNumber": 54640,
				"description": "De-engineered bottom-line projection",
				"locationType": {
					"id": 7,
					"name": "Engineer, manufacturing systems",
					"description": "drive seamless technologies"
				},
				"parentNode": 58337,
				"site": {
					"id": 7,
					"name": "Gutierrez-Sullivan",
					"description": "Reduced leadingedge encoding",
					"address": {
						"id": 11,
						"street": "8060 Cory Junctions",
						"zipCode": "07331",
						"city": "West Jenniferhaven",
						"country": "Liechtenstein",
						"longitude": "161.537678",
						"latitude": "-53.6656195"
					},
					"phone": "001-672-553-3738x96385",
					"locations": [
						"East Darlene",
						"Cardenasmouth",
						"Patelport"
					],
					"customer": {
						"id": 11,
						"name": "Alexander, Robinson and Olson",
						"description": "Enterprise-wide even-keeled task-force",
						"sites": [
							"Masonport",
							"Bradshawborough",
							"Bushtown"
						],
						"contactPersons": [
							{
								"id": 37,
								"name": "Michelle Rodriguez",
								"description": "productize intuitive relationships",
								"phone": "990.288.3206",
								"email": "vluna@may-smith.com",
								"function": "Scientist, biomedical",
								"reportsTo": "Joseph Hill",
								"customer": "Sanchez-Ballard"
							},
							{
								"id": 98,
								"name": "Brian Neal",
								"description": "evolve robust schemas",
								"phone": "930.172.3935",
								"email": "rgreene@anderson-barron.org",
								"function": "Art therapist",
								"reportsTo": "Eugene Martinez",
								"customer": "Kramer Group"
							},
							{
								"id": 88,
								"name": "Eric Erickson",
								"description": "engage web-enabled deliverables",
								"phone": "001-789-126-9614x1567",
								"email": "hamptongordon@short.net",
								"function": "Sales professional, IT",
								"reportsTo": "Suzanne Peters",
								"customer": "Davis and Sons"
							}
						]
					}
				},
				"audits": [
					"Upgradable 6th generation website",
					"Optional incremental open system",
					"Reactive disintermediate complexity"
				],
				"servers": [
					{
						"id": 1,
						"name": "Server01",
						"description": "Main production server",
						"location": "Data Center A",
						"operatingSystem": {
							"id": 1,
							"name": "Windows Server 2019",
							"description": "Windows-based server OS"
						},
						"softwareAsset": [
							{
								"id": 1,
								"moreInfo": "License valid",
								"function": "Database",
								"virtualOrPhysical": "Virtual",
								"runningOnPcName": "VirtualMachine01",
								"fcItemNr": "FC1234",
								"installSoftwareAvailableOnSite": "Yes",
								"slaOrSupportContract": "Standard 24/7",
								"manufacturerDocumentationAvailable": "Yes",
								"remarks": "No remarks",
								"mainSystemPart": "DB Server",
								"budgetPlantPart": "IT Budget",
								"location": "Data Center A",
								"serverId": 1,
								"server": "Server01",
								"softwareComponent": {
									"id": 1,
									"description": "Database software",
									"manufacturer": {
										"id": 1,
										"name": "Microsoft",
										"description": "Software company",
										"hardwareComponents": [
											"Server Chasis"
										],
										"softwareComponents": [
											"SQL Server"
										]
									},
									"version": "2019",
									"moreInfo": "Enterprise Edition",
									"productLifecycle": {
										"id": 1,
										"name": "Active",
										"description": "Currently supported"
									},
									"activeProductUpgradeAvailable": "No",
									"successorType": "N/A",
									"remarks": "Stable",
									"vilocifyComponent": {
										"id": 1,
										"active": true,
										"componentId": 1,
										"vendor": "Microsoft",
										"componentName": "SQL Server",
										"version": "2019",
										"url": "https://example.com",
										"securityUrl": "https://security.example.com",
										"eolReached": false,
										"eolDate": "2025-12-31T13:49:00.796Z",
										"cpeName": "cpe:/a:microsoft:sql_server:2019",
										"monitoredSince": "2021-05-17T13:49:00.796Z",
										"vilocifyNotifications": [
											{
												"id": 1,
												"notificationId": 1,
												"title": "Security Update",
												"publishDate": "2022-01-01T13:49:00.796Z",
												"lastUpdate": "2022-01-01T13:49:00.796Z",
												"priority": 1,
												"action": 0,
												"solutionStatus": "Resolved",
												"impact": "Low",
												"description": "Security update available.",
												"vendorAffectedComponents": "SQL Server",
												"assignedComponents": [
													"Server01"
												],
												"vendorAdvisories": [
													{
														"id": 1,
														"name": "Microsoft Advisory",
														"url": "https://advisory.example.com",
														"vendor": "Microsoft"
													}
												],
												"solutionDetails": "Apply update",
												"legalNotice": "N/A",
												"extendedDescription": "N/A",
												"vulnerabilities": [
													{
														"id": 1,
														"cve": "CVE-2022-1234",
														"cwe": "CWE-79",
														"description": "XSS Vulnerability",
														"mitigatingFactor": "None",
														"note": "Critical",
														"deleted": false
													}
												],
												"updateHistory": [
													{
														"id": 1,
														"description": "Security update applied",
														"date": "2022-01-02T13:49:00.796Z",
														"importantUpdate": true
													}
												],
												"notificationType": "Security"
											}
										]
									}
								},
								"equipmentType": {
									"id": 1,
									"name": "Server",
									"description": "Standard server equipment"
								}
							}
						]
					}
				],
				"softwareAssets": [
					{
						"id": 0,
						"moreInfo": "string",
						"function": "string",
						"virtualOrPhysical": "string",
						"runningOnPcName": "string",
						"fcItemNr": "string",
						"installSoftwareAvailableOnSite": "string",
						"slaOrSupportContract": "string",
						"manufacturerDocumentationAvailable": "string",
						"remarks": "string",
						"mainSystemPart": "string",
						"budgetPlantPart": "string",
						"location": "string",
						"serverId": 0,
						"server": "string",
						"softwareComponent": {
							"id": 0,
							"description": "string",
							"manufacturer": {
								"id": 0,
								"name": "string",
								"description": "string",
								"hardwareComponents": [
									"string"
								],
								"softwareComponents": [
									"string"
								]
							},
							"version": "string",
							"moreInfo": "string",
							"productLifecycle": {
								"id": 0,
								"name": "string",
								"description": "string"
							},
							"activeProductUpgradeAvailable": "string",
							"successorType": "string",
							"remarks": "string",
							"vilocifyComponent": {
								"id": 0,
								"active": true,
								"componentId": 0,
								"vendor": "string",
								"componentName": "string",
								"version": "string",
								"url": "string",
								"securityUrl": "string",
								"eolReached": true,
								"eolDate": "2023-08-29T13:49:00.796Z",
								"cpeName": "string",
								"monitoredSince": "2023-10-17T13:49:00.796Z",
								"vilocifyNotifications": [
									{
										"id": 0,
										"notificationId": 0,
										"title": "string",
										"publishDate": "2023-10-17T13:49:00.796Z",
										"lastUpdate": "2023-10-17T13:49:00.796Z",
										"priority": 0,
										"action": 0,
										"solutionStatus": "string",
										"impact": "string",
										"description": "string",
										"vendorAffectedComponents": "string",
										"assignedComponents": [
											"string"
										],
										"vendorAdvisories": [
											{
												"id": 0,
												"name": "string",
												"url": "string",
												"vendor": "string"
											}
										],
										"solutionDetails": "string",
										"legalNotice": "string",
										"extendedDescription": "string",
										"vulnerabilities": [
											{
												"id": 0,
												"cve": "string",
												"cwe": "string",
												"description": "string",
												"mitigatingFactor": "string",
												"note": "string",
												"deleted": true
											}
										],
										"updateHistory": [
											{
												"id": 0,
												"description": "string",
												"date": "2023-10-17T13:49:00.796Z",
												"importantUpdate": true
											}
										],
										"notificationType": "string"
									}
								]
							}
						},
						"equipmentType": {
							"id": 0,
							"name": "string",
							"description": "string"
						}
					}
				],
				"hardwareAssets": [
					{
						"id": 1,
						"additionalInfo": "Additional information about the asset.",
						"itemNrOrPcName": "Asset-1",
						"parentAsset": null,
						"firmwareVersion": "1.2.3",
						"macAddress": "00:1A:2B:3C:4D:1",
						"networkAddress": "192.168.0.1",
						"inUseOrSpare": "In use",
						"actualSoftwareBackupAvailable": "Yes",
						"slaOrSupportContract": "Standard",
						"manufacturerDocumentationAvailable": "Yes",
						"remarks": "No remarks.",
						"mainSystemPart": "Main System",
						"budgetPlantPart": "Budget Plant",
						"locationNodes": [
							"Location 1",
							"Location 2"
						],
						"hardwareComponent": {
							"id": 642,
							"deviceDescription": "Label printer Q-label",
							"manufacturer": {
								"id": 1,
								"name": "HP",
								"description": "Description of the manufacturer.",
								"hardwareComponents": [
									"Component 1",
									"Component 2"
								],
								"softwareComponents": [
									"Software 1",
									"Software 2"
								]
							},
							"typeNumber": "T630 2RC39EA",
							"deviceType": {
								"id": 1,
								"name": "Device type name",
								"description": "Description of the device type."
							},
							"additionalInfo": "Additional information about the hardware component.",
							"latestAvailableFirmwareVersion": "1.2.3",
							"productLifecycle": {
								"id": 1,
								"name": "Lifecycle 65 dfs",
								"description": "Description of the product lifecycle."
							},
							"endLifecyle": {
								"id": 1,
								"name": "End of Life",
								"description": "Description of the end of life."
							},
							"remarks": "Remarks about the hardware component.",
							"successorType": "Successor type here.",
							"vilocifyComponent": {
								"id": 543,
								"active": true,
								"componentId": 1,
								"vendor": "Vendor name",
								"componentName": "Asset Dependency Tracker: Manages and visualizes dependencies between different hardware and software assets, ensuring seamless interoperability and identifying potential points of failure in the operational chain.",
								"version": "1.2.3",
								"url": "http://example.com/component",
								"securityUrl": "http://example.com/security",
								"eolReached": false,
								"eolDate": "2024-10-16T16:05:10.350581",
								"cpeName": "cpe:/a:vendor_name:component_name:1.2.3",
								"monitoredSince": "2023-10-17T16:05:10.350599",
								"vilocifyNotifications": [
									{
										"id": 0,
										"notificationId": 3492,
										"title": "Security Update 0",
										"publishDate": "2023-10-16T16:05:10.350616",
										"lastUpdate": "2023-10-17T16:05:10.350834",
										"priority": 4,
										"action": 4,
										"solutionStatus": "In Progress",
										"impact": "High",
										"description": "This is a security update.",
										"vendorAffectedComponents": "Component A, Component B",
										"assignedComponents": [
											"Component A",
											"Component B"
										],
										"vendorAdvisories": [
											{
												"id": 785,
												"name": "Vendor Advisory Name",
												"url": "http://example.com/advisory",
												"vendor": "Vendor Name"
											}
										],
										"solutionDetails": "Update the component to the latest version.",
										"legalNotice": "This is a legal notice.",
										"extendedDescription": "Extended description of the update.",
										"vulnerabilities": [
											{
												"id": 2900,
												"cve": "CVE-2021-1234",
												"cwe": "CWE-79",
												"description": "Description of the vulnerability.",
												"mitigatingFactor": "None",
												"note": "Note about the vulnerability.",
												"deleted": false
											}
										],
										"updateHistory": [
											{
												"id": 0,
												"description": "Initial release.",
												"date": "2023-10-17T16:05:10.350853",
												"importantUpdate": true
											}
										],
										"notificationType": "Security"
									},
									{
										"id": 1,
										"notificationId": 7509,
										"title": "Security Update 1",
										"publishDate": "2023-10-08T16:05:10.350863",
										"lastUpdate": "2023-10-17T16:05:10.350869",
										"priority": 1,
										"action": 2,
										"solutionStatus": "In Progress",
										"impact": "High",
										"description": "This is a security update.",
										"vendorAffectedComponents": "Component A, Component B",
										"assignedComponents": [
											"Component A",
											"Component B"
										],
										"vendorAdvisories": [
											{
												"id": 315,
												"name": "Vendor Advisory Name",
												"url": "http://example.com/advisory",
												"vendor": "Vendor Name"
											}
										],
										"solutionDetails": "Update the component to the latest version.",
										"legalNotice": "This is a legal notice.",
										"extendedDescription": "Extended description of the update.",
										"vulnerabilities": [
											{
												"id": 4391,
												"cve": "CVE-2021-1234",
												"cwe": "CWE-79",
												"description": "Description of the vulnerability.",
												"mitigatingFactor": "None",
												"note": "Note about the vulnerability.",
												"deleted": false
											}
										],
										"updateHistory": [
											{
												"id": 1,
												"description": "Initial release.",
												"date": "2023-10-17T16:05:10.350882",
												"importantUpdate": true
											}
										],
										"notificationType": "Security"
									}
								]
							}
						},
						"vilocifyComponent": {
							"id": 1,
							"active": true,
							"componentId": 1,
							"vendor": "Vendor name",
							"componentName": "Version Control Monitor: Monitors and logs version changes in software and firmware of assets, ensuring that all components are operating on supported and secure versions to prevent compatibility issues.",
							"version": "1.2.3",
							"url": "http://example.com/component",
							"securityUrl": "http://example.com/security",
							"eolReached": false,
							"eolDate": "2024-10-16T16:05:10.350890",
							"cpeName": "cpe:/a:vendor_name:component_name:1.2.3",
							"monitoredSince": "2023-10-17T16:05:10.350894",
							"vilocifyNotifications": [
								{
									"id": 0,
									"notificationId": 9508,
									"title": "Security Update 0",
									"publishDate": "2023-09-29T16:05:10.350900",
									"lastUpdate": "2023-10-17T16:05:10.350905",
									"priority": 5,
									"action": 1,
									"solutionStatus": "Pending",
									"impact": "High",
									"description": "This is a security update.",
									"vendorAffectedComponents": "Component A, Component B",
									"assignedComponents": [
										"Component A",
										"Component B"
									],
									"vendorAdvisories": [
										{
											"id": 697,
											"name": "Vendor Advisory Name",
											"url": "http://example.com/advisory",
											"vendor": "Vendor Name"
										}
									],
									"solutionDetails": "Update the component to the latest version.",
									"legalNotice": "This is a legal notice.",
									"extendedDescription": "Extended description of the update.",
									"vulnerabilities": [
										{
											"id": 2403,
											"cve": "CVE-2021-1234",
											"cwe": "CWE-79",
											"description": "Description of the vulnerability.",
											"mitigatingFactor": "None",
											"note": "Note about the vulnerability.",
											"deleted": false
										}
									],
									"updateHistory": [
										{
											"id": 0,
											"description": "Initial release.",
											"date": "2023-10-17T16:05:10.350916",
											"importantUpdate": true
										}
									],
									"notificationType": "Security"
								},
								{
									"id": 1,
									"notificationId": 6032,
									"title": "Security Update 1",
									"publishDate": "2023-10-01T16:05:10.350922",
									"lastUpdate": "2023-10-17T16:05:10.350928",
									"priority": 1,
									"action": 3,
									"solutionStatus": "Completed",
									"impact": "High",
									"description": "This is a security update.",
									"vendorAffectedComponents": "Component A, Component B",
									"assignedComponents": [
										"Component A",
										"Component B"
									],
									"vendorAdvisories": [
										{
											"id": 498,
											"name": "Vendor Advisory Name",
											"url": "http://example.com/advisory",
											"vendor": "Vendor Name"
										}
									],
									"solutionDetails": "Update the component to the latest version.",
									"legalNotice": "This is a legal notice.",
									"extendedDescription": "Extended description of the update.",
									"vulnerabilities": [
										{
											"id": 5754,
											"cve": "CVE-2021-1234",
											"cwe": "CWE-79",
											"description": "Description of the vulnerability.",
											"mitigatingFactor": "None",
											"note": "Note about the vulnerability.",
											"deleted": false
										}
									],
									"updateHistory": [
										{
											"id": 1,
											"description": "Initial release.",
											"date": "2023-10-17T16:05:10.350938",
											"importantUpdate": true
										}
									],
									"notificationType": "Security"
								}
							]
						}
					},
					{
						"id": 32,
						"additionalInfo": "Additional information about the asset.",
						"itemNrOrPcName": "Asset-2",
						"parentAsset": null,
						"firmwareVersion": "1.2.3",
						"macAddress": "00:1A:2B:3C:4D:2",
						"networkAddress": "192.168.0.2",
						"inUseOrSpare": "In use",
						"actualSoftwareBackupAvailable": "Yes",
						"slaOrSupportContract": "Standard",
						"manufacturerDocumentationAvailable": "Yes",
						"remarks": "No remarks.",
						"mainSystemPart": "Main System",
						"budgetPlantPart": "Budget Plant",
						"locationNodes": [
							"Location 1",
							"Location 2"
						],
						"hardwareComponent": {
							"id": 1232,
							"deviceDescription": "Switch Uplink",
							"manufacturer": {
								"id": 2,
								"name": "Canon",
								"description": "Description of the manufacturer.",
								"hardwareComponents": [
									"Component 1",
									"Component 2"
								],
								"softwareComponents": [
									"Software 1",
									"Software 2"
								]
							},
							"typeNumber": "T640 3DS40FB",
							"deviceType": {
								"id": 2,
								"name": "Device type name",
								"description": "Description of the device type."
							},
							"additionalInfo": "Additional information about the hardware component.",
							"latestAvailableFirmwareVersion": "1.2.3",
							"productLifecycle": {
								"id": 2,
								"name": "Lifecycle name",
								"description": "Description of the product lifecycle."
							},
							"endLifecyle": {
								"id": 2,
								"name": "End of Life",
								"description": "Description of the end of life."
							},
							"remarks": "Remarks about the hardware component.",
							"successorType": "Successor type here.",
							"vilocifyComponent": {
								"id": 4252,
								"active": true,
								"componentId": 2,
								"vendor": "Vendor name",
								"componentName": "Scheduled Maintenance Planner: Organizes and schedules routine maintenance activities for hardware assets, minimizing downtime and ensuring optimal performance of manufacturing equipment.",
								"version": "1.2.3",
								"url": "http://example.com/component",
								"securityUrl": "http://example.com/security",
								"eolReached": true,
								"eolDate": "2023-08-29T16:05:10.350950",
								"cpeName": "cpe:/a:vendor_name:component_name:1.2.3",
								"monitoredSince": "2023-10-17T16:05:10.350954",
								"vilocifyNotifications": [
									{
										"id": 0,
										"notificationId": 4014,
										"title": "Security Update 0",
										"publishDate": "2023-09-19T16:05:10.350959",
										"lastUpdate": "2023-10-17T16:05:10.350964",
										"priority": 2,
										"action": 2,
										"solutionStatus": "Pending",
										"impact": "High",
										"description": "This is a security update.",
										"vendorAffectedComponents": "Component A, Component B",
										"assignedComponents": [
											"Component A",
											"Component B"
										],
										"vendorAdvisories": [
											{
												"id": 899,
												"name": "Vendor Advisory Name",
												"url": "http://example.com/advisory",
												"vendor": "Vendor Name"
											}
										],
										"solutionDetails": "Update the component to the latest version.",
										"legalNotice": "This is a legal notice.",
										"extendedDescription": "Extended description of the update.",
										"vulnerabilities": [
											{
												"id": 3196,
												"cve": "CVE-2021-1234",
												"cwe": "CWE-79",
												"description": "Description of the vulnerability.",
												"mitigatingFactor": "None",
												"note": "Note about the vulnerability.",
												"deleted": false
											}
										],
										"updateHistory": [
											{
												"id": 0,
												"description": "Initial release.",
												"date": "2023-10-17T16:05:10.350975",
												"importantUpdate": true
											}
										],
										"notificationType": "Security"
									},
									{
										"id": 1,
										"notificationId": 1592,
										"title": "Security Update 1",
										"publishDate": "2023-09-21T16:05:10.350981",
										"lastUpdate": "2023-10-17T16:05:10.350986",
										"priority": 5,
										"action": 3,
										"solutionStatus": "Completed",
										"impact": "High",
										"description": "This is a security update.",
										"vendorAffectedComponents": "Component A, Component B",
										"assignedComponents": [
											"Component A",
											"Component B"
										],
										"vendorAdvisories": [
											{
												"id": 886,
												"name": "Vendor Advisory Name",
												"url": "http://example.com/advisory",
												"vendor": "Vendor Name"
											}
										],
										"solutionDetails": "Update the component to the latest version.",
										"legalNotice": "This is a legal notice.",
										"extendedDescription": "Extended description of the update.",
										"vulnerabilities": [
											{
												"id": 1538,
												"cve": "CVE-2021-1234",
												"cwe": "CWE-79",
												"description": "Description of the vulnerability.",
												"mitigatingFactor": "None",
												"note": "Note about the vulnerability.",
												"deleted": false
											}
										],
										"updateHistory": [
											{
												"id": 1,
												"description": "Initial release.",
												"date": "2023-10-17T16:05:10.350997",
												"importantUpdate": true
											}
										],
										"notificationType": "Security"
									}
								]
							}
						},
						"vilocifyComponent": {
							"id": 2243,
							"active": true,
							"componentId": 2,
							"vendor": "Vendor name",
							"componentName": "Vulnerability Assessment Dashboard: Provides a comprehensive view of known vulnerabilities across software and hardware assets, facilitating timely remediation and ensuring adherence to security standards.",
							"version": "1.2.3",
							"url": "http://example.com/component",
							"securityUrl": "http://example.com/security",
							"eolReached": true,
							"eolDate": "2023-08-29T16:05:10.351004",
							"cpeName": "cpe:/a:vendor_name:component_name:1.2.3",
							"monitoredSince": "2023-10-17T16:05:10.351008",
							"vilocifyNotifications": [
								{
									"id": 0,
									"notificationId": 5177,
									"title": "Security Update 0",
									"publishDate": "2023-09-30T16:05:10.351013",
									"lastUpdate": "2023-10-17T16:05:10.351019",
									"priority": 3,
									"action": 4,
									"solutionStatus": "In Progress",
									"impact": "High",
									"description": "This is a security update.",
									"vendorAffectedComponents": "Component A, Component B",
									"assignedComponents": [
										"Component A",
										"Component B"
									],
									"vendorAdvisories": [
										{
											"id": 237,
											"name": "Vendor Advisory Name",
											"url": "http://example.com/advisory",
											"vendor": "Vendor Name"
										}
									],
									"solutionDetails": "Update the component to the latest version.",
									"legalNotice": "This is a legal notice.",
									"extendedDescription": "Extended description of the update.",
									"vulnerabilities": [
										{
											"id": 6578,
											"cve": "CVE-2021-1234",
											"cwe": "CWE-79",
											"description": "Description of the vulnerability.",
											"mitigatingFactor": "None",
											"note": "Note about the vulnerability.",
											"deleted": false
										}
									],
									"updateHistory": [
										{
											"id": 0,
											"description": "Initial release.",
											"date": "2023-10-17T16:05:10.351029",
											"importantUpdate": true
										}
									],
									"notificationType": "Security"
								},
								{
									"id": 1,
									"notificationId": 2359,
									"title": "Security Update 1",
									"publishDate": "2023-09-20T16:05:10.351036",
									"lastUpdate": "2023-10-17T16:05:10.351041",
									"priority": 2,
									"action": 3,
									"solutionStatus": "In Progress",
									"impact": "High",
									"description": "This is a security update.",
									"vendorAffectedComponents": "Component A, Component B",
									"assignedComponents": [
										"Component A",
										"Component B"
									],
									"vendorAdvisories": [
										{
											"id": 766,
											"name": "Vendor Advisory Name",
											"url": "http://example.com/advisory",
											"vendor": "Vendor Name"
										}
									],
									"solutionDetails": "Update the component to the latest version.",
									"legalNotice": "This is a legal notice.",
									"extendedDescription": "Extended description of the update.",
									"vulnerabilities": [
										{
											"id": 8050,
											"cve": "CVE-2021-1234",
											"cwe": "CWE-79",
											"description": "Description of the vulnerability.",
											"mitigatingFactor": "None",
											"note": "Note about the vulnerability.",
											"deleted": false
										}
									],
									"updateHistory": [
										{
											"id": 1,
											"description": "Initial release.",
											"date": "2023-10-17T16:05:10.351053",
											"importantUpdate": true
										}
									],
									"notificationType": "Security"
								}
							]
						}
					},
					{
						"id": 3,
						"additionalInfo": "Additional information about the asset.",
						"itemNrOrPcName": "Asset-3",
						"parentAsset": null,
						"firmwareVersion": "1.2.3",
						"macAddress": "00:1A:2B:3C:4D:3",
						"networkAddress": "192.168.0.3",
						"inUseOrSpare": "In use",
						"actualSoftwareBackupAvailable": "Yes",
						"slaOrSupportContract": "Standard",
						"manufacturerDocumentationAvailable": "Yes",
						"remarks": "No remarks.",
						"mainSystemPart": "Main System",
						"budgetPlantPart": "Budget Plant",
						"locationNodes": [
							"Location 1",
							"Location 2"
						],
						"hardwareComponent": {
							"id": 3,
							"deviceDescription": "Server ESX",
							"manufacturer": {
								"id": 3,
								"name": "Cisco",
								"description": "Description of the manufacturer.",
								"hardwareComponents": [
									"Component 1",
									"Component 2"
								],
								"softwareComponents": [
									"Software 1",
									"Software 2"
								]
							},
							"typeNumber": "BELMN01-I-SW017",
							"deviceType": {
								"id": 3,
								"name": "Device type name",
								"description": "Description of the device type."
							},
							"additionalInfo": "Additional information about the hardware component.",
							"latestAvailableFirmwareVersion": "1.2.3",
							"productLifecycle": {
								"id": 3,
								"name": "Lifecycle name",
								"description": "Description of the product lifecycle."
							},
							"endLifecyle": {
								"id": 3,
								"name": "End of Life",
								"description": "Description of the end of life."
							},
							"remarks": "Remarks about the hardware component.",
							"successorType": "Successor type here.",
							"vilocifyComponent": {
								"id": 3,
								"active": true,
								"componentId": 3,
								"vendor": "Vendor name",
								"componentName": "Compliance Verification Engine: Validates compliance with regulatory and organizational standards by continuously monitoring the configuration and operation of assets within the manufacturing environment.",
								"version": "1.2.3",
								"url": "http://example.com/component",
								"securityUrl": "http://example.com/security",
								"eolReached": false,
								"eolDate": "2024-10-16T16:05:10.351063",
								"cpeName": "cpe:/a:vendor_name:component_name:1.2.3",
								"monitoredSince": "2023-10-17T16:05:10.351068",
								"vilocifyNotifications": [
									{
										"id": 0,
										"notificationId": 9232,
										"title": "Security Update 0",
										"publishDate": "2023-10-13T16:05:10.351073",
										"lastUpdate": "2023-10-17T16:05:10.351079",
										"priority": 2,
										"action": 3,
										"solutionStatus": "Completed",
										"impact": "High",
										"description": "This is a security update.",
										"vendorAffectedComponents": "Component A, Component B",
										"assignedComponents": [
											"Component A",
											"Component B"
										],
										"vendorAdvisories": [
											{
												"id": 241,
												"name": "Vendor Advisory Name",
												"url": "http://example.com/advisory",
												"vendor": "Vendor Name"
											}
										],
										"solutionDetails": "Update the component to the latest version.",
										"legalNotice": "This is a legal notice.",
										"extendedDescription": "Extended description of the update.",
										"vulnerabilities": [
											{
												"id": 1152,
												"cve": "CVE-2021-1234",
												"cwe": "CWE-79",
												"description": "Description of the vulnerability.",
												"mitigatingFactor": "None",
												"note": "Note about the vulnerability.",
												"deleted": false
											}
										],
										"updateHistory": [
											{
												"id": 0,
												"description": "Initial release.",
												"date": "2023-10-17T16:05:10.351091",
												"importantUpdate": true
											}
										],
										"notificationType": "Security"
									},
									{
										"id": 1,
										"notificationId": 7517,
										"title": "Security Update 1",
										"publishDate": "2023-09-21T16:05:10.351097",
										"lastUpdate": "2023-10-17T16:05:10.351103",
										"priority": 1,
										"action": 2,
										"solutionStatus": "Pending",
										"impact": "High",
										"description": "This is a security update.",
										"vendorAffectedComponents": "Component A, Component B",
										"assignedComponents": [
											"Component A",
											"Component B"
										],
										"vendorAdvisories": [
											{
												"id": 288,
												"name": "Vendor Advisory Name",
												"url": "http://example.com/advisory",
												"vendor": "Vendor Name"
											}
										],
										"solutionDetails": "Update the component to the latest version.",
										"legalNotice": "This is a legal notice.",
										"extendedDescription": "Extended description of the update.",
										"vulnerabilities": [
											{
												"id": 4943,
												"cve": "CVE-2021-1234",
												"cwe": "CWE-79",
												"description": "Description of the vulnerability.",
												"mitigatingFactor": "None",
												"note": "Note about the vulnerability.",
												"deleted": false
											}
										],
										"updateHistory": [
											{
												"id": 1,
												"description": "Initial release.",
												"date": "2023-10-17T16:05:10.351114",
												"importantUpdate": true
											}
										],
										"notificationType": "Security"
									}
								]
							}
						},
						"vilocifyComponent": {
							"id": 3,
							"active": true,
							"componentId": 3,
							"vendor": "Vendor name",
							"componentName": "Software License Manager: Tracks and manages software licenses and subscriptions, ensuring legal compliance and optimizing software investment across the enterprise.",
							"version": "1.2.3",
							"url": "http://example.com/component",
							"securityUrl": "http://example.com/security",
							"eolReached": false,
							"eolDate": "2024-7-16T08:05:10.351121",
							"cpeName": "cpe:/a:vendor_name:component_name:1.2.3",
							"monitoredSince": "2023-10-17T16:05:10.351125",
							"vilocifyNotifications": [
								{
									"id": 0,
									"notificationId": 2282,
									"title": "Security Update 0",
									"publishDate": "2023-10-06T16:05:10.351131",
									"lastUpdate": "2023-10-17T16:05:10.351136",
									"priority": 4,
									"action": 2,
									"solutionStatus": "In Progress",
									"impact": "High",
									"description": "This is a security update.",
									"vendorAffectedComponents": "Component A, Component B",
									"assignedComponents": [
										"Component A",
										"Component B"
									],
									"vendorAdvisories": [
										{
											"id": 214,
											"name": "Vendor Advisory Name",
											"url": "http://example.com/advisory",
											"vendor": "Vendor Name"
										}
									],
									"solutionDetails": "Update the component to the latest version.",
									"legalNotice": "This is a legal notice.",
									"extendedDescription": "Extended description of the update.",
									"vulnerabilities": [
										{
											"id": 5239,
											"cve": "CVE-2021-1234",
											"cwe": "CWE-79",
											"description": "Description of the vulnerability.",
											"mitigatingFactor": "None",
											"note": "Note about the vulnerability.",
											"deleted": false
										}
									],
									"updateHistory": [
										{
											"id": 0,
											"description": "Initial release.",
											"date": "2023-10-17T16:05:10.351148",
											"importantUpdate": true
										}
									],
									"notificationType": "Security"
								},
								{
									"id": 1,
									"notificationId": 1619,
									"title": "Security Update 1",
									"publishDate": "2023-10-05T16:05:10.351155",
									"lastUpdate": "2023-10-17T16:05:10.351161",
									"priority": 2,
									"action": 3,
									"solutionStatus": "Pending",
									"impact": "High",
									"description": "This is a security update.",
									"vendorAffectedComponents": "Component A, Component B",
									"assignedComponents": [
										"Component A",
										"Component B"
									],
									"vendorAdvisories": [
										{
											"id": 678,
											"name": "Vendor Advisory Name",
											"url": "http://example.com/advisory",
											"vendor": "Vendor Name"
										}
									],
									"solutionDetails": "Update the component to the latest version.",
									"legalNotice": "This is a legal notice.",
									"extendedDescription": "Extended description of the update.",
									"vulnerabilities": [
										{
											"id": 5834,
											"cve": "CVE-2021-1234",
											"cwe": "CWE-79",
											"description": "Description of the vulnerability.",
											"mitigatingFactor": "None",
											"note": "Note about the vulnerability.",
											"deleted": false
										}
									],
									"updateHistory": [
										{
											"id": 1,
											"description": "Initial release.",
											"date": "2023-10-17T16:05:10.351173",
											"importantUpdate": true
										}
									],
									"notificationType": "Security"
								},
								{
									"id": 435,
									"notificationId": 139,
									"title": "Security Update 1",
									"publishDate": "2023-10-05T16:05:10.351155",
									"lastUpdate": "2023-10-17T16:05:10.351161",
									"priority": 2,
									"action": 3,
									"solutionStatus": "Pending",
									"impact": "High",
									"description": "This is a security update.",
									"vendorAffectedComponents": "Component A, Component B",
									"assignedComponents": [
										"Component A",
										"Component B"
									],
									"vendorAdvisories": [
										{
											"id": 678,
											"name": "Vendor Advisory Name",
											"url": "http://example.com/advisory",
											"vendor": "Vendor Name"
										}
									],
									"solutionDetails": "Update the component to the latest version.",
									"legalNotice": "This is a legal notice.",
									"extendedDescription": "Extended description of the update.",
									"vulnerabilities": [
										{
											"id": 5834,
											"cve": "CVE-2021-1234",
											"cwe": "CWE-79",
											"description": "Description of the vulnerability.",
											"mitigatingFactor": "None",
											"note": "Note about the vulnerability.",
											"deleted": false
										}
									],
									"updateHistory": [
										{
											"id": 1,
											"description": "Initial release.",
											"date": "2023-10-17T16:05:10.351173",
											"importantUpdate": true
										}
									],
									"notificationType": "Security"
								}
							]
						}
					},
					{
						"id": 4,
						"additionalInfo": "Additional information about the asset.",
						"itemNrOrPcName": "Asset-4",
						"parentAsset": null,
						"firmwareVersion": "1.2.3",
						"macAddress": "00:1A:2B:3C:4D:4",
						"networkAddress": "192.168.0.4",
						"inUseOrSpare": "In use",
						"actualSoftwareBackupAvailable": "Yes",
						"slaOrSupportContract": "Standard",
						"manufacturerDocumentationAvailable": "Yes",
						"remarks": "No remarks.",
						"mainSystemPart": "Main System",
						"budgetPlantPart": "Budget Plant",
						"locationNodes": [
							"Location 1",
							"Location 2"
						],
						"hardwareComponent": {
							"id": 4,
							"deviceDescription": "Server ESX",
							"manufacturer": {
								"id": 4,
								"name": "Manufacturer name",
								"description": "Description of the manufacturer.",
								"hardwareComponents": [
									"Component 1",
									"Component 2"
								],
								"softwareComponents": [
									"Software 1",
									"Software 2"
								]
							},
							"typeNumber": "Type-4",
							"deviceType": {
								"id": 4,
								"name": "Device type name",
								"description": "Description of the device type."
							},
							"additionalInfo": "Additional information about the hardware component.",
							"latestAvailableFirmwareVersion": "1.2.3",
							"productLifecycle": {
								"id": 4,
								"name": "Lifecycle name",
								"description": "Description of the product lifecycle."
							},
							"endLifecyle": {
								"id": 4,
								"name": "End of Life",
								"description": "Description of the end of life."
							},
							"remarks": "Remarks about the hardware component.",
							"successorType": "Successor type here.",
							"vilocifyComponent": {
								"id": 4,
								"active": true,
								"componentId": 4,
								"vendor": "Vendor name",
								"componentName": "Hardware Performance Analyzer: Monitors and analyzes the performance of hardware assets, identifying potential bottlenecks or degradation in performance to prevent unexpected breakdowns.",
								"version": "1.2.3",
								"url": "http://example.com/component",
								"securityUrl": "http://example.com/security",
								"eolReached": false,
								"eolDate": "2022-07-09T16:05:10.351183",
								"cpeName": "cpe:/a:vendor_name:component_name:1.2.3",
								"monitoredSince": "2023-10-17T16:05:10.351187",
								"vilocifyNotifications": [
									{
										"id": 0,
										"notificationId": 7335,
										"title": "Security Update 0",
										"publishDate": "2023-09-25T16:05:10.351193",
										"lastUpdate": "2023-10-17T16:05:10.351199",
										"priority": 4,
										"action": 3,
										"solutionStatus": "Completed",
										"impact": "High",
										"description": "This is a security update.",
										"vendorAffectedComponents": "Component A, Component B",
										"assignedComponents": [
											"Component A",
											"Component B"
										],
										"vendorAdvisories": [
											{
												"id": 632,
												"name": "Vendor Advisory Name",
												"url": "http://example.com/advisory",
												"vendor": "Vendor Name"
											}
										],
										"solutionDetails": "Update the component to the latest version.",
										"legalNotice": "This is a legal notice.",
										"extendedDescription": "Extended description of the update.",
										"vulnerabilities": [
											{
												"id": 4653,
												"cve": "CVE-2021-1234",
												"cwe": "CWE-79",
												"description": "Description of the vulnerability.",
												"mitigatingFactor": "None",
												"note": "Note about the vulnerability.",
												"deleted": false
											}
										],
										"updateHistory": [
											{
												"id": 0,
												"description": "Initial release.",
												"date": "2023-10-17T16:05:10.351211",
												"importantUpdate": true
											}
										],
										"notificationType": "Security"
									},
									{
										"id": 1,
										"notificationId": 1529,
										"title": "Security Update 1",
										"publishDate": "2023-10-03T16:05:10.351218",
										"lastUpdate": "2023-10-17T16:05:10.351223",
										"priority": 1,
										"action": 4,
										"solutionStatus": "Completed",
										"impact": "High",
										"description": "This is a security update.",
										"vendorAffectedComponents": "Component A, Component B",
										"assignedComponents": [
											"Component A",
											"Component B"
										],
										"vendorAdvisories": [
											{
												"id": 141,
												"name": "Vendor Advisory Name",
												"url": "http://example.com/advisory",
												"vendor": "Vendor Name"
											}
										],
										"solutionDetails": "Update the component to the latest version.",
										"legalNotice": "This is a legal notice.",
										"extendedDescription": "Extended description of the update.",
										"vulnerabilities": [
											{
												"id": 1502,
												"cve": "CVE-2021-1234",
												"cwe": "CWE-79",
												"description": "Description of the vulnerability.",
												"mitigatingFactor": "None",
												"note": "Note about the vulnerability.",
												"deleted": false
											}
										],
										"updateHistory": [
											{
												"id": 1,
												"description": "Initial release.",
												"date": "2023-10-17T16:05:10.351235",
												"importantUpdate": true
											}
										],
										"notificationType": "Security"
									}
								]
							}
						},
						"vilocifyComponent": {
							"id": 4,
							"active": true,
							"componentId": 4,
							"vendor": "Vendor name",
							"componentName": "Change Management Coordinator: Coordinates changes to asset configurations or software updates, ensuring a structured and well-documented process that minimizes operational risks.",
							"version": "1.2.3",
							"url": "http://example.com/component",
							"securityUrl": "http://example.com/security",
							"eolReached": false,
							"eolDate": "2024-10-16T16:05:10.351242",
							"cpeName": "cpe:/a:vendor_name:component_name:1.2.3",
							"monitoredSince": "2023-10-17T16:05:10.351246",
							"vilocifyNotifications": [
								{
									"id": 0,
									"notificationId": 6823,
									"title": "Security Update 0",
									"publishDate": "2023-10-02T16:05:10.351252",
									"lastUpdate": "2023-10-17T16:05:10.351258",
									"priority": 1,
									"action": 1,
									"solutionStatus": "Pending",
									"impact": "High",
									"description": "This is a security update.",
									"vendorAffectedComponents": "Component A, Component B",
									"assignedComponents": [
										"Component A",
										"Component B"
									],
									"vendorAdvisories": [
										{
											"id": 665,
											"name": "Vendor Advisory Name",
											"url": "http://example.com/advisory",
											"vendor": "Vendor Name"
										}
									],
									"solutionDetails": "Update the component to the latest version.",
									"legalNotice": "This is a legal notice.",
									"extendedDescription": "Extended description of the update.",
									"vulnerabilities": [
										{
											"id": 7951,
											"cve": "CVE-2021-1234",
											"cwe": "CWE-79",
											"description": "Description of the vulnerability.",
											"mitigatingFactor": "None",
											"note": "Note about the vulnerability.",
											"deleted": false
										}
									],
									"updateHistory": [
										{
											"id": 0,
											"description": "Initial release.",
											"date": "2023-10-17T16:05:10.351270",
											"importantUpdate": true
										}
									],
									"notificationType": "Security"
								},
								{
									"id": 1,
									"notificationId": 2028,
									"title": "Security Update 1",
									"publishDate": "2023-09-23T16:05:10.351277",
									"lastUpdate": "2023-10-17T16:05:10.351283",
									"priority": 4,
									"action": 4,
									"solutionStatus": "Pending",
									"impact": "High",
									"description": "This is a security update.",
									"vendorAffectedComponents": "Component A, Component B",
									"assignedComponents": [
										"Component A",
										"Component B"
									],
									"vendorAdvisories": [
										{
											"id": 255,
											"name": "Vendor Advisory Name",
											"url": "http://example.com/advisory",
											"vendor": "Vendor Name"
										}
									],
									"solutionDetails": "Update the component to the latest version.",
									"legalNotice": "This is a legal notice.",
									"extendedDescription": "Extended description of the update.",
									"vulnerabilities": [
										{
											"id": 3162,
											"cve": "CVE-2021-1234",
											"cwe": "CWE-79",
											"description": "Description of the vulnerability.",
											"mitigatingFactor": "None",
											"note": "Note about the vulnerability.",
											"deleted": false
										}
									],
									"updateHistory": [
										{
											"id": 1,
											"description": "Initial release.",
											"date": "2023-10-17T16:05:10.351295",
											"importantUpdate": true
										}
									],
									"notificationType": "Security"
								}
							]
						}
					},
					{
						"id": 5,
						"additionalInfo": "Additional information about the asset.",
						"itemNrOrPcName": "Asset-5",
						"parentAsset": null,
						"firmwareVersion": "1.2.3",
						"macAddress": "00:1A:2B:3C:4D:5",
						"networkAddress": "192.168.0.5",
						"inUseOrSpare": "In use",
						"actualSoftwareBackupAvailable": "Yes",
						"slaOrSupportContract": "Standard",
						"manufacturerDocumentationAvailable": "Yes",
						"remarks": "No remarks.",
						"mainSystemPart": "Main System",
						"budgetPlantPart": "Budget Plant",
						"locationNodes": [
							"Location 1",
							"Location 2"
						],
						"hardwareComponent": {
							"id": 5,
							"deviceDescription": "PLC Controller",
							"manufacturer": {
								"id": 5,
								"name": "Siemens",
								"description": "Description of the manufacturer.",
								"hardwareComponents": [
									"Component 1",
									"Component 2"
								],
								"softwareComponents": [
									"Software 1",
									"Software 2"
								]
							},
							"typeNumber": "LOGO 12/24RC",
							"deviceType": {
								"id": 5,
								"name": "Device type name",
								"description": "Description of the device type."
							},
							"additionalInfo": "Additional information about the hardware component.",
							"latestAvailableFirmwareVersion": "1.2.3",
							"productLifecycle": {
								"id": 5,
								"name": "Lifecycle name",
								"description": "Description of the product lifecycle."
							},
							"endLifecyle": {
								"id": 5,
								"name": "End of Life",
								"description": "Description of the end of life."
							},
							"remarks": "Remarks about the hardware component.",
							"successorType": "Successor type here.",
							"vilocifyComponent": {
								"id": 5,
								"active": true,
								"componentId": 5,
								"vendor": "Vendor name",
								"componentName": "Real-Time Alerting System: Provides real-time alerts for critical events such as system failures, security breaches, or maintenance requirements, enabling prompt response to mitigate issues.",
								"version": "1.2.3",
								"url": "http://example.com/component",
								"securityUrl": "http://example.com/security",
								"eolReached": true,
								"eolDate": "2021-03-07T16:05:10.351306",
								"cpeName": "cpe:/a:vendor_name:component_name:1.2.3",
								"monitoredSince": "2023-10-17T16:05:10.351311",
								"vilocifyNotifications": [
									{
										"id": 0,
										"notificationId": 4942,
										"title": "Security Update 0",
										"publishDate": "2023-09-21T16:05:10.351317",
										"lastUpdate": "2023-10-17T16:05:10.351323",
										"priority": 5,
										"action": 1,
										"solutionStatus": "In Progress",
										"impact": "High",
										"description": "This is a security update.",
										"vendorAffectedComponents": "Component A, Component B",
										"assignedComponents": [
											"Component A",
											"Component B"
										],
										"vendorAdvisories": [
											{
												"id": 374,
												"name": "Vendor Advisory Name",
												"url": "http://example.com/advisory",
												"vendor": "Vendor Name"
											}
										],
										"solutionDetails": "Update the component to the latest version.",
										"legalNotice": "This is a legal notice.",
										"extendedDescription": "Extended description of the update.",
										"vulnerabilities": [
											{
												"id": 9867,
												"cve": "CVE-2021-1234",
												"cwe": "CWE-79",
												"description": "Description of the vulnerability.",
												"mitigatingFactor": "None",
												"note": "Note about the vulnerability.",
												"deleted": false
											}
										],
										"updateHistory": [
											{
												"id": 0,
												"description": "Initial release.",
												"date": "2023-10-17T16:05:10.351336",
												"importantUpdate": true
											}
										],
										"notificationType": "Security"
									},
									{
										"id": 1,
										"notificationId": 1602,
										"title": "Security Update 1",
										"publishDate": "2023-10-04T16:05:10.351342",
										"lastUpdate": "2023-10-17T16:05:10.351348",
										"priority": 5,
										"action": 4,
										"solutionStatus": "In Progress",
										"impact": "High",
										"description": "This is a security update.",
										"vendorAffectedComponents": "Component A, Component B",
										"assignedComponents": [
											"Component A",
											"Component B"
										],
										"vendorAdvisories": [
											{
												"id": 833,
												"name": "Vendor Advisory Name",
												"url": "http://example.com/advisory",
												"vendor": "Vendor Name"
											}
										],
										"solutionDetails": "Update the component to the latest version.",
										"legalNotice": "This is a legal notice.",
										"extendedDescription": "Extended description of the update.",
										"vulnerabilities": [
											{
												"id": 4850,
												"cve": "CVE-2021-1234",
												"cwe": "CWE-79",
												"description": "Description of the vulnerability.",
												"mitigatingFactor": "None",
												"note": "Note about the vulnerability.",
												"deleted": false
											}
										],
										"updateHistory": [
											{
												"id": 1,
												"description": "Initial release.",
												"date": "2023-10-17T16:05:10.351360",
												"importantUpdate": true
											}
										],
										"notificationType": "Security"
									}
								]
							}
						},
						"vilocifyComponent": {
							"id": 5,
							"active": true,
							"componentId": 5,
							"vendor": "Vendor name",
							"componentName": "Asset Lifecycle Documentation Repository: Centralizes documentation related to the lifecycle of assets, including manuals, maintenance records, and upgrade histories, facilitating easy access and historical tracking.",
							"version": "1.2.3",
							"url": "http://example.com/component",
							"securityUrl": "http://example.com/security",
							"eolReached": false,
							"eolDate": "2024-10-16T16:05:10.351366",
							"cpeName": "cpe:/a:vendor_name:component_name:1.2.3",
							"monitoredSince": "2023-10-17T16:05:10.351371",
							"vilocifyNotifications": [
								{
									"id": 0,
									"notificationId": 4569,
									"title": "Security Update 0",
									"publishDate": "2023-10-16T16:05:10.351376",
									"lastUpdate": "2023-10-17T16:05:10.351382",
									"priority": 2,
									"action": 2,
									"solutionStatus": "Completed",
									"impact": "High",
									"description": "This is a security update.",
									"vendorAffectedComponents": "Component A, Component B",
									"assignedComponents": [
										"Component A",
										"Component B"
									],
									"vendorAdvisories": [
										{
											"id": 878,
											"name": "Vendor Advisory Name",
											"url": "http://example.com/advisory",
											"vendor": "Vendor Name"
										}
									],
									"solutionDetails": "Update the component to the latest version.",
									"legalNotice": "This is a legal notice.",
									"extendedDescription": "Extended description of the update.",
									"vulnerabilities": [
										{
											"id": 3005,
											"cve": "CVE-2021-1234",
											"cwe": "CWE-79",
											"description": "Description of the vulnerability.",
											"mitigatingFactor": "None",
											"note": "Note about the vulnerability.",
											"deleted": false
										}
									],
									"updateHistory": [
										{
											"id": 0,
											"description": "Initial release.",
											"date": "2023-10-17T16:05:10.351393",
											"importantUpdate": true
										}
									],
									"notificationType": "Security"
								},
								{
									"id": 1,
									"notificationId": 6663,
									"title": "Security Update 1",
									"publishDate": "2023-10-05T16:05:10.351399",
									"lastUpdate": "2023-10-17T16:05:10.351405",
									"priority": 4,
									"action": 3,
									"solutionStatus": "Pending",
									"impact": "High",
									"description": "This is a security update.",
									"vendorAffectedComponents": "Component A, Component B",
									"assignedComponents": [
										"Component A",
										"Component B"
									],
									"vendorAdvisories": [
										{
											"id": 285,
											"name": "Vendor Advisory Name",
											"url": "http://example.com/advisory",
											"vendor": "Vendor Name"
										}
									],
									"solutionDetails": "Update the component to the latest version.",
									"legalNotice": "This is a legal notice.",
									"extendedDescription": "Extended description of the update.",
									"vulnerabilities": [
										{
											"id": 8841,
											"cve": "CVE-2021-1234",
											"cwe": "CWE-79",
											"description": "Description of the vulnerability.",
											"mitigatingFactor": "None",
											"note": "Note about the vulnerability.",
											"deleted": false
										}
									],
									"updateHistory": [
										{
											"id": 1,
											"description": "Initial release.",
											"date": "2023-10-17T16:05:10.351417",
											"importantUpdate": true
										}
									],
									"notificationType": "Security"
								}
							]
						}
					},
					{
						"id": 6,
						"additionalInfo": "Additional information about the asset.",
						"itemNrOrPcName": "Asset-6",
						"parentAsset": null,
						"firmwareVersion": "1.2.3",
						"macAddress": "00:1A:2B:3C:4D:6",
						"networkAddress": "192.168.0.6",
						"inUseOrSpare": "In use",
						"actualSoftwareBackupAvailable": "Yes",
						"slaOrSupportContract": "Standard",
						"manufacturerDocumentationAvailable": "Yes",
						"remarks": "No remarks.",
						"mainSystemPart": "Main System",
						"budgetPlantPart": "Budget Plant",
						"locationNodes": [
							"Location 1",
							"Location 2"
						],
						"hardwareComponent": {
							"id": 6,
							"deviceDescription": "Laptop",
							"manufacturer": {
								"id": 6,
								"name": "Dell",
								"description": "Description of the manufacturer.",
								"hardwareComponents": [
									"Component 1",
									"Component 2"
								],
								"softwareComponents": [
									"Software 1",
									"Software 2"
								]
							},
							"typeNumber": "XPS 15",
							"deviceType": {
								"id": 6,
								"name": "Device type name",
								"description": "Description of the device type."
							},
							"additionalInfo": "Additional information about the hardware component.",
							"latestAvailableFirmwareVersion": "1.2.3",
							"productLifecycle": {
								"id": 6,
								"name": "Lifecycle name",
								"description": "Description of the product lifecycle."
							},
							"endLifecyle": {
								"id": 6,
								"name": "End of Life",
								"description": "Description of the end of life."
							},
							"remarks": "Remarks about the hardware component.",
							"successorType": "Successor type here.",
							"vilocifyComponent": {
								"id": 6,
								"active": true,
								"componentId": 6,
								"vendor": "Vendor name",
								"componentName": "Resource Allocation Optimizer: Analyzes and optimizes the allocation of resources such as manpower, machinery, and materials, ensuring efficient utilization and minimizing operational costs.",
								"version": "1.2.3",
								"url": "http://example.com/component",
								"securityUrl": "http://example.com/security",
								"eolReached": false,
								"eolDate": "2024-10-16T16:05:10.351428",
								"cpeName": "cpe:/a:vendor_name:component_name:1.2.3",
								"monitoredSince": "2023-10-17T16:05:10.351433",
								"vilocifyNotifications": [
									{
										"id": 0,
										"notificationId": 9010,
										"title": "Security Update 0",
										"publishDate": "2023-10-17T16:05:10.351438",
										"lastUpdate": "2023-10-17T16:05:10.351444",
										"priority": 1,
										"action": 1,
										"solutionStatus": "Pending",
										"impact": "High",
										"description": "This is a security update.",
										"vendorAffectedComponents": "Component A, Component B",
										"assignedComponents": [
											"Component A",
											"Component B"
										],
										"vendorAdvisories": [
											{
												"id": 499,
												"name": "Vendor Advisory Name",
												"url": "http://example.com/advisory",
												"vendor": "Vendor Name"
											}
										],
										"solutionDetails": "Update the component to the latest version.",
										"legalNotice": "This is a legal notice.",
										"extendedDescription": "Extended description of the update.",
										"vulnerabilities": [
											{
												"id": 8863,
												"cve": "CVE-2021-1234",
												"cwe": "CWE-79",
												"description": "Description of the vulnerability.",
												"mitigatingFactor": "None",
												"note": "Note about the vulnerability.",
												"deleted": false
											}
										],
										"updateHistory": [
											{
												"id": 0,
												"description": "Initial release.",
												"date": "2023-10-17T16:05:10.351456",
												"importantUpdate": true
											}
										],
										"notificationType": "Security"
									},
									{
										"id": 1,
										"notificationId": 2769,
										"title": "Security Update 1",
										"publishDate": "2023-10-11T16:05:10.351463",
										"lastUpdate": "2023-10-17T16:05:10.351469",
										"priority": 1,
										"action": 3,
										"solutionStatus": "Pending",
										"impact": "High",
										"description": "This is a security update.",
										"vendorAffectedComponents": "Component A, Component B",
										"assignedComponents": [
											"Component A",
											"Component B"
										],
										"vendorAdvisories": [
											{
												"id": 554,
												"name": "Vendor Advisory Name",
												"url": "http://example.com/advisory",
												"vendor": "Vendor Name"
											}
										],
										"solutionDetails": "Update the component to the latest version.",
										"legalNotice": "This is a legal notice.",
										"extendedDescription": "Extended description of the update.",
										"vulnerabilities": [
											{
												"id": 1752,
												"cve": "CVE-2021-1234",
												"cwe": "CWE-79",
												"description": "Description of the vulnerability.",
												"mitigatingFactor": "None",
												"note": "Note about the vulnerability.",
												"deleted": false
											}
										],
										"updateHistory": [
											{
												"id": 1,
												"description": "Initial release.",
												"date": "2023-10-17T16:05:10.351481",
												"importantUpdate": true
											}
										],
										"notificationType": "Security"
									}
								]
							}
						},
						"vilocifyComponent": {
							"id": 6,
							"active": true,
							"componentId": 6,
							"vendor": "Vendor name",
							"componentName": "Energy Consumption Analyzer: Monitors and evaluates the energy consumption of various assets, identifying opportunities for energy savings and supporting sustainability initiatives.",
							"version": "1.2.3",
							"url": "http://example.com/component",
							"securityUrl": "http://example.com/security",
							"eolReached": false,
							"eolDate": "2024-10-16T16:05:10.351488",
							"cpeName": "cpe:/a:vendor_name:component_name:1.2.3",
							"monitoredSince": "2023-10-17T16:05:10.351492",
							"vilocifyNotifications": [
								{
									"id": 0,
									"notificationId": 1819,
									"title": "Security Update 0",
									"publishDate": "2023-10-09T16:05:10.351497",
									"lastUpdate": "2023-10-17T16:05:10.351503",
									"priority": 3,
									"action": 2,
									"solutionStatus": "Completed",
									"impact": "High",
									"description": "This is a security update.",
									"vendorAffectedComponents": "Component A, Component B",
									"assignedComponents": [
										"Component A",
										"Component B"
									],
									"vendorAdvisories": [
										{
											"id": 569,
											"name": "Vendor Advisory Name",
											"url": "http://example.com/advisory",
											"vendor": "Vendor Name"
										}
									],
									"solutionDetails": "Update the component to the latest version.",
									"legalNotice": "This is a legal notice.",
									"extendedDescription": "Extended description of the update.",
									"vulnerabilities": [
										{
											"id": 8234,
											"cve": "CVE-2021-1234",
											"cwe": "CWE-79",
											"description": "Description of the vulnerability.",
											"mitigatingFactor": "None",
											"note": "Note about the vulnerability.",
											"deleted": false
										}
									],
									"updateHistory": [
										{
											"id": 0,
											"description": "Initial release.",
											"date": "2023-10-17T16:05:10.351515",
											"importantUpdate": true
										}
									],
									"notificationType": "Security"
								},
								{
									"id": 1,
									"notificationId": 2420,
									"title": "Security Update 1",
									"publishDate": "2023-10-12T16:05:10.351521",
									"lastUpdate": "2023-10-17T16:05:10.351527",
									"priority": 4,
									"action": 1,
									"solutionStatus": "Completed",
									"impact": "High",
									"description": "This is a security update.",
									"vendorAffectedComponents": "Component A, Component B",
									"assignedComponents": [
										"Component A",
										"Component B"
									],
									"vendorAdvisories": [
										{
											"id": 192,
											"name": "Vendor Advisory Name",
											"url": "http://example.com/advisory",
											"vendor": "Vendor Name"
										}
									],
									"solutionDetails": "Update the component to the latest version.",
									"legalNotice": "This is a legal notice.",
									"extendedDescription": "Extended description of the update.",
									"vulnerabilities": [
										{
											"id": 1209,
											"cve": "CVE-2021-1234",
											"cwe": "CWE-79",
											"description": "Description of the vulnerability.",
											"mitigatingFactor": "None",
											"note": "Note about the vulnerability.",
											"deleted": false
										}
									],
									"updateHistory": [
										{
											"id": 1,
											"description": "Initial release.",
											"date": "2023-10-17T16:05:10.351539",
											"importantUpdate": true
										}
									],
									"notificationType": "Security"
								}
							]
						}
					},
					{
						"id": 7,
						"additionalInfo": "Additional information about the asset.",
						"itemNrOrPcName": "Asset-7",
						"parentAsset": null,
						"firmwareVersion": "1.2.3",
						"macAddress": "00:1A:2B:3C:4D:7",
						"networkAddress": "192.168.0.7",
						"inUseOrSpare": "In use",
						"actualSoftwareBackupAvailable": "Yes",
						"slaOrSupportContract": "Standard",
						"manufacturerDocumentationAvailable": "Yes",
						"remarks": "No remarks.",
						"mainSystemPart": "Main System",
						"budgetPlantPart": "Budget Plant",
						"locationNodes": [
							"Location 1",
							"Location 2"
						],
						"hardwareComponent": {
							"id": 7,
							"deviceDescription": "Laptop",
							"manufacturer": {
								"id": 7,
								"name": "Apple",
								"description": "Description of the manufacturer.",
								"hardwareComponents": [
									"Component 1",
									"Component 2"
								],
								"softwareComponents": [
									"Software 1",
									"Software 2"
								]
							},
							"typeNumber": "MacBook Pro",
							"deviceType": {
								"id": 7,
								"name": "Device type name",
								"description": "Description of the device type."
							},
							"additionalInfo": "Additional information about the hardware component.",
							"latestAvailableFirmwareVersion": "1.2.3",
							"productLifecycle": {
								"id": 7,
								"name": "Lifecycle name",
								"description": "Description of the product lifecycle."
							},
							"endLifecyle": {
								"id": 7,
								"name": "End of Life",
								"description": "Description of the end of life."
							},
							"remarks": "Remarks about the hardware component.",
							"successorType": "Successor type here.",
							"vilocifyComponent": {
								"id": 7,
								"active": true,
								"componentId": 7,
								"vendor": "Vendor name",
								"componentName": "Production Quality Assessor: Continuously assesses the quality of production by monitoring key parameters and analyzing data to ensure adherence to quality standards and identify areas for improvement.",
								"version": "1.2.3",
								"url": "http://example.com/component",
								"securityUrl": "http://example.com/security",
								"eolReached": false,
								"eolDate": "2024-10-16T16:05:10.351549",
								"cpeName": "cpe:/a:vendor_name:component_name:1.2.3",
								"monitoredSince": "2023-10-17T16:05:10.351553",
								"vilocifyNotifications": [
									{
										"id": 0,
										"notificationId": 3038,
										"title": "Security Update 0",
										"publishDate": "2023-09-26T16:05:10.351558",
										"lastUpdate": "2023-10-17T16:05:10.351564",
										"priority": 5,
										"action": 1,
										"solutionStatus": "Pending",
										"impact": "High",
										"description": "This is a security update.",
										"vendorAffectedComponents": "Component A, Component B",
										"assignedComponents": [
											"Component A",
											"Component B"
										],
										"vendorAdvisories": [
											{
												"id": 293,
												"name": "Vendor Advisory Name",
												"url": "http://example.com/advisory",
												"vendor": "Vendor Name"
											}
										],
										"solutionDetails": "Update the component to the latest version.",
										"legalNotice": "This is a legal notice.",
										"extendedDescription": "Extended description of the update.",
										"vulnerabilities": [
											{
												"id": 6858,
												"cve": "CVE-2021-1234",
												"cwe": "CWE-79",
												"description": "Description of the vulnerability.",
												"mitigatingFactor": "None",
												"note": "Note about the vulnerability.",
												"deleted": false
											}
										],
										"updateHistory": [
											{
												"id": 0,
												"description": "Initial release.",
												"date": "2023-10-17T16:05:10.351575",
												"importantUpdate": true
											}
										],
										"notificationType": "Security"
									},
									{
										"id": 1,
										"notificationId": 5038,
										"title": "Security Update 1",
										"publishDate": "2023-09-26T16:05:10.351582",
										"lastUpdate": "2023-10-17T16:05:10.351588",
										"priority": 3,
										"action": 1,
										"solutionStatus": "In Progress",
										"impact": "High",
										"description": "This is a security update.",
										"vendorAffectedComponents": "Component A, Component B",
										"assignedComponents": [
											"Component A",
											"Component B"
										],
										"vendorAdvisories": [
											{
												"id": 894,
												"name": "Vendor Advisory Name",
												"url": "http://example.com/advisory",
												"vendor": "Vendor Name"
											}
										],
										"solutionDetails": "Update the component to the latest version.",
										"legalNotice": "This is a legal notice.",
										"extendedDescription": "Extended description of the update.",
										"vulnerabilities": [
											{
												"id": 4094,
												"cve": "CVE-2021-1234",
												"cwe": "CWE-79",
												"description": "Description of the vulnerability.",
												"mitigatingFactor": "None",
												"note": "Note about the vulnerability.",
												"deleted": false
											}
										],
										"updateHistory": [
											{
												"id": 1,
												"description": "Initial release.",
												"date": "2023-10-17T16:05:10.351599",
												"importantUpdate": true
											}
										],
										"notificationType": "Security"
									}
								]
							}
						},
						"vilocifyComponent": {
							"id": 7,
							"active": true,
							"componentId": 7,
							"vendor": "Vendor name",
							"componentName": "Incident Response Orchestrator: Coordinates the response to incidents such as equipment failures or security breaches, ensuring prompt resolution and minimizing impact on operations.",
							"version": "1.2.3",
							"url": "http://example.com/component",
							"securityUrl": "http://example.com/security",
							"eolReached": false,
							"eolDate": "2024-10-16T16:05:10.351606",
							"cpeName": "cpe:/a:vendor_name:component_name:1.2.3",
							"monitoredSince": "2023-10-17T16:05:10.351610",
							"vilocifyNotifications": [
								{
									"id": 0,
									"notificationId": 7460,
									"title": "Security Update 0",
									"publishDate": "2023-09-25T16:05:10.351615",
									"lastUpdate": "2023-10-17T16:05:10.351621",
									"priority": 4,
									"action": 1,
									"solutionStatus": "In Progress",
									"impact": "High",
									"description": "This is a security update.",
									"vendorAffectedComponents": "Component A, Component B",
									"assignedComponents": [
										"Component A",
										"Component B"
									],
									"vendorAdvisories": [
										{
											"id": 922,
											"name": "Vendor Advisory Name",
											"url": "http://example.com/advisory",
											"vendor": "Vendor Name"
										}
									],
									"solutionDetails": "Update the component to the latest version.",
									"legalNotice": "This is a legal notice.",
									"extendedDescription": "Extended description of the update.",
									"vulnerabilities": [
										{
											"id": 2680,
											"cve": "CVE-2021-1234",
											"cwe": "CWE-79",
											"description": "Description of the vulnerability.",
											"mitigatingFactor": "None",
											"note": "Note about the vulnerability.",
											"deleted": false
										}
									],
									"updateHistory": [
										{
											"id": 0,
											"description": "Initial release.",
											"date": "2023-10-17T16:05:10.351632",
											"importantUpdate": true
										}
									],
									"notificationType": "Security"
								},
								{
									"id": 1,
									"notificationId": 6754,
									"title": "Security Update 1",
									"publishDate": "2023-10-07T16:05:10.351638",
									"lastUpdate": "2023-10-17T16:05:10.351643",
									"priority": 3,
									"action": 1,
									"solutionStatus": "In Progress",
									"impact": "High",
									"description": "This is a security update.",
									"vendorAffectedComponents": "Component A, Component B",
									"assignedComponents": [
										"Component A",
										"Component B"
									],
									"vendorAdvisories": [
										{
											"id": 685,
											"name": "Vendor Advisory Name",
											"url": "http://example.com/advisory",
											"vendor": "Vendor Name"
										}
									],
									"solutionDetails": "Update the component to the latest version.",
									"legalNotice": "This is a legal notice.",
									"extendedDescription": "Extended description of the update.",
									"vulnerabilities": [
										{
											"id": 3590,
											"cve": "CVE-2021-1234",
											"cwe": "CWE-79",
											"description": "Description of the vulnerability.",
											"mitigatingFactor": "None",
											"note": "Note about the vulnerability.",
											"deleted": false
										}
									],
									"updateHistory": [
										{
											"id": 1,
											"description": "Initial release.",
											"date": "2023-10-17T16:05:10.351654",
											"importantUpdate": true
										}
									],
									"notificationType": "Security"
								}
							]
						}
					},
					{
						"id": 8,
						"additionalInfo": "Additional information about the asset.",
						"itemNrOrPcName": "Asset-8",
						"parentAsset": null,
						"firmwareVersion": "1.2.3",
						"macAddress": "00:1A:2B:3C:4D:8",
						"networkAddress": "192.168.0.8",
						"inUseOrSpare": "In use",
						"actualSoftwareBackupAvailable": "Yes",
						"slaOrSupportContract": "Standard",
						"manufacturerDocumentationAvailable": "Yes",
						"remarks": "No remarks.",
						"mainSystemPart": "Main System",
						"budgetPlantPart": "Budget Plant",
						"locationNodes": [
							"Location 1",
							"Location 2"
						],
						"hardwareComponent": {
							"id": 8,
							"deviceDescription": "Device description here.",
							"manufacturer": {
								"id": 8,
								"name": "Manufacturer name",
								"description": "Description of the manufacturer.",
								"hardwareComponents": [
									"Component 1",
									"Component 2"
								],
								"softwareComponents": [
									"Software 1",
									"Software 2"
								]
							},
							"typeNumber": "Type-8",
							"deviceType": {
								"id": 8,
								"name": "Device type name",
								"description": "Description of the device type."
							},
							"additionalInfo": "Additional information about the hardware component.",
							"latestAvailableFirmwareVersion": "1.2.3",
							"productLifecycle": {
								"id": 8,
								"name": "Lifecycle name",
								"description": "Description of the product lifecycle."
							},
							"endLifecyle": {
								"id": 8,
								"name": "End of Life",
								"description": "Description of the end of life."
							},
							"remarks": "Remarks about the hardware component.",
							"successorType": "Successor type here.",
							"vilocifyComponent": {
								"id": 8,
								"active": true,
								"componentId": 8,
								"vendor": "Vendor name",
								"componentName": "Operational Risk Profiler: Identifies and evaluates operational risks, enabling informed decision-making and the implementation of risk mitigation strategies.",
								"version": "1.2.3",
								"url": "http://example.com/component",
								"securityUrl": "http://example.com/security",
								"eolReached": false,
								"eolDate": "2024-10-16T16:05:10.351663",
								"cpeName": "cpe:/a:vendor_name:component_name:1.2.3",
								"monitoredSince": "2023-10-17T16:05:10.351667",
								"vilocifyNotifications": [
									{
										"id": 0,
										"notificationId": 3343,
										"title": "Security Update 0",
										"publishDate": "2023-09-24T16:05:10.351672",
										"lastUpdate": "2023-10-17T16:05:10.351677",
										"priority": 4,
										"action": 2,
										"solutionStatus": "In Progress",
										"impact": "High",
										"description": "This is a security update.",
										"vendorAffectedComponents": "Component A, Component B",
										"assignedComponents": [
											"Component A",
											"Component B"
										],
										"vendorAdvisories": [
											{
												"id": 179,
												"name": "Vendor Advisory Name",
												"url": "http://example.com/advisory",
												"vendor": "Vendor Name"
											}
										],
										"solutionDetails": "Update the component to the latest version.",
										"legalNotice": "This is a legal notice.",
										"extendedDescription": "Extended description of the update.",
										"vulnerabilities": [
											{
												"id": 2031,
												"cve": "CVE-2021-1234",
												"cwe": "CWE-79",
												"description": "Description of the vulnerability.",
												"mitigatingFactor": "None",
												"note": "Note about the vulnerability.",
												"deleted": false
											}
										],
										"updateHistory": [
											{
												"id": 0,
												"description": "Initial release.",
												"date": "2023-10-17T16:05:10.351688",
												"importantUpdate": true
											}
										],
										"notificationType": "Security"
									},
									{
										"id": 1,
										"notificationId": 5698,
										"title": "Security Update 1",
										"publishDate": "2023-10-07T16:05:10.351694",
										"lastUpdate": "2023-10-17T16:05:10.351700",
										"priority": 2,
										"action": 4,
										"solutionStatus": "Completed",
										"impact": "High",
										"description": "This is a security update.",
										"vendorAffectedComponents": "Component A, Component B",
										"assignedComponents": [
											"Component A",
											"Component B"
										],
										"vendorAdvisories": [
											{
												"id": 572,
												"name": "Vendor Advisory Name",
												"url": "http://example.com/advisory",
												"vendor": "Vendor Name"
											}
										],
										"solutionDetails": "Update the component to the latest version.",
										"legalNotice": "This is a legal notice.",
										"extendedDescription": "Extended description of the update.",
										"vulnerabilities": [
											{
												"id": 1685,
												"cve": "CVE-2021-1234",
												"cwe": "CWE-79",
												"description": "Description of the vulnerability.",
												"mitigatingFactor": "None",
												"note": "Note about the vulnerability.",
												"deleted": false
											}
										],
										"updateHistory": [
											{
												"id": 1,
												"description": "Initial release.",
												"date": "2023-10-17T16:05:10.351710",
												"importantUpdate": true
											}
										],
										"notificationType": "Security"
									}
								]
							}
						},
						"vilocifyComponent": {
							"id": 8,
							"active": true,
							"componentId": 8,
							"vendor": "Vendor name",
							"componentName": "Supply Chain Integration Suite: Facilitates the integration and coordination of supply chain processes, ensuring timely procurement, assets management, and delivery of materials.",
							"version": "1.2.3",
							"url": "http://example.com/component",
							"securityUrl": "http://example.com/security",
							"eolReached": false,
							"eolDate": "2024-10-16T16:05:10.351716",
							"cpeName": "cpe:/a:vendor_name:component_name:1.2.3",
							"monitoredSince": "2023-10-17T16:05:10.351720",
							"vilocifyNotifications": [
								{
									"id": 0,
									"notificationId": 5587,
									"title": "Security Update 0",
									"publishDate": "2023-09-29T16:05:10.351726",
									"lastUpdate": "2023-10-17T16:05:10.351731",
									"priority": 1,
									"action": 3,
									"solutionStatus": "In Progress",
									"impact": "High",
									"description": "This is a security update.",
									"vendorAffectedComponents": "Component A, Component B",
									"assignedComponents": [
										"Component A",
										"Component B"
									],
									"vendorAdvisories": [
										{
											"id": 651,
											"name": "Vendor Advisory Name",
											"url": "http://example.com/advisory",
											"vendor": "Vendor Name"
										}
									],
									"solutionDetails": "Update the component to the latest version.",
									"legalNotice": "This is a legal notice.",
									"extendedDescription": "Extended description of the update.",
									"vulnerabilities": [
										{
											"id": 2213,
											"cve": "CVE-2021-1234",
											"cwe": "CWE-79",
											"description": "Description of the vulnerability.",
											"mitigatingFactor": "None",
											"note": "Note about the vulnerability.",
											"deleted": false
										}
									],
									"updateHistory": [
										{
											"id": 0,
											"description": "Initial release.",
											"date": "2023-10-17T16:05:10.351742",
											"importantUpdate": true
										}
									],
									"notificationType": "Security"
								},
								{
									"id": 1,
									"notificationId": 1597,
									"title": "Security Update 1",
									"publishDate": "2023-09-26T16:05:10.351748",
									"lastUpdate": "2023-10-17T16:05:10.351753",
									"priority": 1,
									"action": 2,
									"solutionStatus": "Pending",
									"impact": "High",
									"description": "This is a security update.",
									"vendorAffectedComponents": "Component A, Component B",
									"assignedComponents": [
										"Component A",
										"Component B"
									],
									"vendorAdvisories": [
										{
											"id": 908,
											"name": "Vendor Advisory Name",
											"url": "http://example.com/advisory",
											"vendor": "Vendor Name"
										}
									],
									"solutionDetails": "Update the component to the latest version.",
									"legalNotice": "This is a legal notice.",
									"extendedDescription": "Extended description of the update.",
									"vulnerabilities": [
										{
											"id": 1350,
											"cve": "CVE-2021-1234",
											"cwe": "CWE-79",
											"description": "Description of the vulnerability.",
											"mitigatingFactor": "None",
											"note": "Note about the vulnerability.",
											"deleted": false
										}
									],
									"updateHistory": [
										{
											"id": 1,
											"description": "Initial release.",
											"date": "2023-10-17T16:05:10.351765",
											"importantUpdate": true
										}
									],
									"notificationType": "Security"
								}
							]
						}
					},
					{
						"id": 9,
						"additionalInfo": "Additional information about the asset.",
						"itemNrOrPcName": "Asset-9",
						"parentAsset": null,
						"firmwareVersion": "1.2.3",
						"macAddress": "00:1A:2B:3C:4D:9",
						"networkAddress": "192.168.0.9",
						"inUseOrSpare": "In use",
						"actualSoftwareBackupAvailable": "Yes",
						"slaOrSupportContract": "Standard",
						"manufacturerDocumentationAvailable": "Yes",
						"remarks": "No remarks.",
						"mainSystemPart": "Main System",
						"budgetPlantPart": "Budget Plant",
						"locationNodes": [
							"Location 1",
							"Location 2"
						],
						"hardwareComponent": {
							"id": 9,
							"deviceDescription": "Device description here.",
							"manufacturer": {
								"id": 9,
								"name": "Manufacturer name",
								"description": "Description of the manufacturer.",
								"hardwareComponents": [
									"Component 1",
									"Component 2"
								],
								"softwareComponents": [
									"Software 1",
									"Software 2"
								]
							},
							"typeNumber": "Type-9",
							"deviceType": {
								"id": 9,
								"name": "Device type name",
								"description": "Description of the device type."
							},
							"additionalInfo": "Additional information about the hardware component.",
							"latestAvailableFirmwareVersion": "1.2.3",
							"productLifecycle": {
								"id": 9,
								"name": "Lifecycle name",
								"description": "Description of the product lifecycle."
							},
							"endLifecyle": {
								"id": 9,
								"name": "End of Life",
								"description": "Description of the end of life."
							},
							"remarks": "Remarks about the hardware component.",
							"successorType": "Successor type here.",
							"vilocifyComponent": {
								"id": 9,
								"active": true,
								"componentId": 9,
								"vendor": "Vendor name",
								"componentName": "Predictive Maintenance Predictor: Utilizes predictive analytics to forecast maintenance needs, enabling proactive maintenance scheduling and reducing unexpected downtime.",
								"version": "1.2.3",
								"url": "http://example.com/component",
								"securityUrl": "http://example.com/security",
								"eolReached": false,
								"eolDate": "2024-10-16T16:05:10.351775",
								"cpeName": "cpe:/a:vendor_name:component_name:1.2.3",
								"monitoredSince": "2023-10-17T16:05:10.351779",
								"vilocifyNotifications": [
									{
										"id": 0,
										"notificationId": 6203,
										"title": "Security Update 0",
										"publishDate": "2023-09-28T16:05:10.351784",
										"lastUpdate": "2023-10-17T16:05:10.351790",
										"priority": 4,
										"action": 4,
										"solutionStatus": "In Progress",
										"impact": "High",
										"description": "This is a security update.",
										"vendorAffectedComponents": "Component A, Component B",
										"assignedComponents": [
											"Component A",
											"Component B"
										],
										"vendorAdvisories": [
											{
												"id": 609,
												"name": "Vendor Advisory Name",
												"url": "http://example.com/advisory",
												"vendor": "Vendor Name"
											}
										],
										"solutionDetails": "Update the component to the latest version.",
										"legalNotice": "This is a legal notice.",
										"extendedDescription": "Extended description of the update.",
										"vulnerabilities": [
											{
												"id": 6200,
												"cve": "CVE-2021-1234",
												"cwe": "CWE-79",
												"description": "Description of the vulnerability.",
												"mitigatingFactor": "None",
												"note": "Note about the vulnerability.",
												"deleted": false
											}
										],
										"updateHistory": [
											{
												"id": 0,
												"description": "Initial release.",
												"date": "2023-10-17T16:05:10.351800",
												"importantUpdate": true
											}
										],
										"notificationType": "Security"
									},
									{
										"id": 1,
										"notificationId": 5923,
										"title": "Security Update 1",
										"publishDate": "2023-10-09T16:05:10.351807",
										"lastUpdate": "2023-10-17T16:05:10.351814",
										"priority": 2,
										"action": 1,
										"solutionStatus": "In Progress",
										"impact": "High",
										"description": "This is a security update.",
										"vendorAffectedComponents": "Component A, Component B",
										"assignedComponents": [
											"Component A",
											"Component B"
										],
										"vendorAdvisories": [
											{
												"id": 905,
												"name": "Vendor Advisory Name",
												"url": "http://example.com/advisory",
												"vendor": "Vendor Name"
											}
										],
										"solutionDetails": "Update the component to the latest version.",
										"legalNotice": "This is a legal notice.",
										"extendedDescription": "Extended description of the update.",
										"vulnerabilities": [
											{
												"id": 8119,
												"cve": "CVE-2021-1234",
												"cwe": "CWE-79",
												"description": "Description of the vulnerability.",
												"mitigatingFactor": "None",
												"note": "Note about the vulnerability.",
												"deleted": false
											}
										],
										"updateHistory": [
											{
												"id": 1,
												"description": "Initial release.",
												"date": "2023-10-17T16:05:10.351824",
												"importantUpdate": true
											}
										],
										"notificationType": "Security"
									}
								]
							}
						},
						"vilocifyComponent": {
							"id": 9,
							"active": true,
							"componentId": 9,
							"vendor": "Vendor name",
							"componentName": "Vilocify component name",
							"version": "1.2.3",
							"url": "http://example.com/component",
							"securityUrl": "http://example.com/security",
							"eolReached": false,
							"eolDate": "2024-10-16T16:05:10.351830",
							"cpeName": "cpe:/a:vendor_name:component_name:1.2.3",
							"monitoredSince": "2023-10-17T16:05:10.351834",
							"vilocifyNotifications": [
								{
									"id": 0,
									"notificationId": 6330,
									"title": "Security Update 0",
									"publishDate": "2023-09-26T16:05:10.351839",
									"lastUpdate": "2023-10-17T16:05:10.351845",
									"priority": 2,
									"action": 4,
									"solutionStatus": "Completed",
									"impact": "High",
									"description": "This is a security update.",
									"vendorAffectedComponents": "Component A, Component B",
									"assignedComponents": [
										"Component A",
										"Component B"
									],
									"vendorAdvisories": [
										{
											"id": 864,
											"name": "Vendor Advisory Name",
											"url": "http://example.com/advisory",
											"vendor": "Vendor Name"
										}
									],
									"solutionDetails": "Update the component to the latest version.",
									"legalNotice": "This is a legal notice.",
									"extendedDescription": "Extended description of the update.",
									"vulnerabilities": [
										{
											"id": 9084,
											"cve": "CVE-2021-1234",
											"cwe": "CWE-79",
											"description": "Description of the vulnerability.",
											"mitigatingFactor": "None",
											"note": "Note about the vulnerability.",
											"deleted": false
										}
									],
									"updateHistory": [
										{
											"id": 0,
											"description": "Initial release.",
											"date": "2023-10-17T16:05:10.351856",
											"importantUpdate": true
										}
									],
									"notificationType": "Security"
								},
								{
									"id": 1,
									"notificationId": 6276,
									"title": "Security Update 1",
									"publishDate": "2023-09-27T16:05:10.351862",
									"lastUpdate": "2023-10-17T16:05:10.351867",
									"priority": 1,
									"action": 2,
									"solutionStatus": "Completed",
									"impact": "High",
									"description": "This is a security update.",
									"vendorAffectedComponents": "Component A, Component B",
									"assignedComponents": [
										"Component A",
										"Component B"
									],
									"vendorAdvisories": [
										{
											"id": 636,
											"name": "Vendor Advisory Name",
											"url": "http://example.com/advisory",
											"vendor": "Vendor Name"
										}
									],
									"solutionDetails": "Update the component to the latest version.",
									"legalNotice": "This is a legal notice.",
									"extendedDescription": "Extended description of the update.",
									"vulnerabilities": [
										{
											"id": 9082,
											"cve": "CVE-2021-1234",
											"cwe": "CWE-79",
											"description": "Description of the vulnerability.",
											"mitigatingFactor": "None",
											"note": "Note about the vulnerability.",
											"deleted": false
										}
									],
									"updateHistory": [
										{
											"id": 1,
											"description": "Initial release.",
											"date": "2023-10-17T16:05:10.351878",
											"importantUpdate": true
										}
									],
									"notificationType": "Security"
								}
							]
						}
					},
					{
						"id": 10,
						"additionalInfo": "Additional information about the asset.",
						"itemNrOrPcName": "Asset-10",
						"parentAsset": null,
						"firmwareVersion": "1.2.3",
						"macAddress": "00:1A:2B:3C:4D:10",
						"networkAddress": "192.168.0.10",
						"inUseOrSpare": "In use",
						"actualSoftwareBackupAvailable": "Yes",
						"slaOrSupportContract": "Standard",
						"manufacturerDocumentationAvailable": "Yes",
						"remarks": "No remarks.",
						"mainSystemPart": "Main System",
						"budgetPlantPart": "Budget Plant",
						"locationNodes": [
							"Location 1",
							"Location 2"
						],
						"hardwareComponent": {
							"id": 10,
							"deviceDescription": "Device description here.",
							"manufacturer": {
								"id": 10,
								"name": "Manufacturer name",
								"description": "Description of the manufacturer.",
								"hardwareComponents": [
									"Component 1",
									"Component 2"
								],
								"softwareComponents": [
									"Software 1",
									"Software 2"
								]
							},
							"typeNumber": "Type-10",
							"deviceType": {
								"id": 10,
								"name": "Device type name",
								"description": "Description of the device type."
							},
							"additionalInfo": "Additional information about the hardware component.",
							"latestAvailableFirmwareVersion": "1.2.3",
							"productLifecycle": {
								"id": 10,
								"name": "Lifecycle name",
								"description": "Description of the product lifecycle."
							},
							"endLifecyle": {
								"id": 10,
								"name": "End of Life",
								"description": "Description of the end of life."
							},
							"remarks": "Remarks about the hardware component.",
							"successorType": "Successor type here.",
							"vilocifyComponent": {
								"id": 10,
								"active": true,
								"componentId": 10,
								"vendor": "Vendor name",
								"componentName": "Vilocify component name",
								"version": "1.2.3",
								"url": "http://example.com/component",
								"securityUrl": "http://example.com/security",
								"eolReached": false,
								"eolDate": "2024-10-16T16:05:10.351887",
								"cpeName": "cpe:/a:vendor_name:component_name:1.2.3",
								"monitoredSince": "2023-10-17T16:05:10.351891",
								"vilocifyNotifications": [
									{
										"id": 0,
										"notificationId": 9842,
										"title": "Security Update 0",
										"publishDate": "2023-09-28T16:05:10.351896",
										"lastUpdate": "2023-10-17T16:05:10.351902",
										"priority": 2,
										"action": 2,
										"solutionStatus": "In Progress",
										"impact": "High",
										"description": "This is a security update.",
										"vendorAffectedComponents": "Component A, Component B",
										"assignedComponents": [
											"Component A",
											"Component B"
										],
										"vendorAdvisories": [
											{
												"id": 674,
												"name": "Vendor Advisory Name",
												"url": "http://example.com/advisory",
												"vendor": "Vendor Name"
											}
										],
										"solutionDetails": "Update the component to the latest version.",
										"legalNotice": "This is a legal notice.",
										"extendedDescription": "Extended description of the update.",
										"vulnerabilities": [
											{
												"id": 6681,
												"cve": "CVE-2021-1234",
												"cwe": "CWE-79",
												"description": "Description of the vulnerability.",
												"mitigatingFactor": "None",
												"note": "Note about the vulnerability.",
												"deleted": false
											}
										],
										"updateHistory": [
											{
												"id": 0,
												"description": "Initial release.",
												"date": "2023-10-17T16:05:10.351913",
												"importantUpdate": true
											}
										],
										"notificationType": "Security"
									},
									{
										"id": 1,
										"notificationId": 7445,
										"title": "Security Update 1",
										"publishDate": "2023-10-17T16:05:10.351918",
										"lastUpdate": "2023-10-17T16:05:10.351924",
										"priority": 1,
										"action": 2,
										"solutionStatus": "Completed",
										"impact": "High",
										"description": "This is a security update.",
										"vendorAffectedComponents": "Component A, Component B",
										"assignedComponents": [
											"Component A",
											"Component B"
										],
										"vendorAdvisories": [
											{
												"id": 273,
												"name": "Vendor Advisory Name",
												"url": "http://example.com/advisory",
												"vendor": "Vendor Name"
											}
										],
										"solutionDetails": "Update the component to the latest version.",
										"legalNotice": "This is a legal notice.",
										"extendedDescription": "Extended description of the update.",
										"vulnerabilities": [
											{
												"id": 5301,
												"cve": "CVE-2021-1234",
												"cwe": "CWE-79",
												"description": "Description of the vulnerability.",
												"mitigatingFactor": "None",
												"note": "Note about the vulnerability.",
												"deleted": false
											}
										],
										"updateHistory": [
											{
												"id": 1,
												"description": "Initial release.",
												"date": "2023-10-17T16:05:10.351934",
												"importantUpdate": true
											}
										],
										"notificationType": "Security"
									}
								]
							}
						},
						"vilocifyComponent": {
							"id": 10,
							"active": true,
							"componentId": 10,
							"vendor": "Vendor name",
							"componentName": "Vilocify component name",
							"version": "1.2.3",
							"url": "http://example.com/component",
							"securityUrl": "http://example.com/security",
							"eolReached": false,
							"eolDate": "2024-10-16T16:05:10.351940",
							"cpeName": "cpe:/a:vendor_name:component_name:1.2.3",
							"monitoredSince": "2023-10-17T16:05:10.351944",
							"vilocifyNotifications": [
								{
									"id": 0,
									"notificationId": 4468,
									"title": "Security Update 0",
									"publishDate": "2023-10-10T16:05:10.351950",
									"lastUpdate": "2023-10-17T16:05:10.351955",
									"priority": 4,
									"action": 3,
									"solutionStatus": "In Progress",
									"impact": "High",
									"description": "This is a security update.",
									"vendorAffectedComponents": "Component A, Component B",
									"assignedComponents": [
										"Component A",
										"Component B"
									],
									"vendorAdvisories": [
										{
											"id": 314,
											"name": "Vendor Advisory Name",
											"url": "http://example.com/advisory",
											"vendor": "Vendor Name"
										}
									],
									"solutionDetails": "Update the component to the latest version.",
									"legalNotice": "This is a legal notice.",
									"extendedDescription": "Extended description of the update.",
									"vulnerabilities": [
										{
											"id": 1712,
											"cve": "CVE-2021-1234",
											"cwe": "CWE-79",
											"description": "Description of the vulnerability.",
											"mitigatingFactor": "None",
											"note": "Note about the vulnerability.",
											"deleted": false
										}
									],
									"updateHistory": [
										{
											"id": 0,
											"description": "Initial release.",
											"date": "2023-10-17T16:05:10.351966",
											"importantUpdate": true
										}
									],
									"notificationType": "Security"
								},
								{
									"id": 1,
									"notificationId": 3236,
									"title": "Security Update 1",
									"publishDate": "2023-09-25T16:05:10.351972",
									"lastUpdate": "2023-10-17T16:05:10.351977",
									"priority": 5,
									"action": 1,
									"solutionStatus": "Completed",
									"impact": "High",
									"description": "This is a security update.",
									"vendorAffectedComponents": "Component A, Component B",
									"assignedComponents": [
										"Component A",
										"Component B"
									],
									"vendorAdvisories": [
										{
											"id": 566,
											"name": "Vendor Advisory Name",
											"url": "http://example.com/advisory",
											"vendor": "Vendor Name"
										}
									],
									"solutionDetails": "Update the component to the latest version.",
									"legalNotice": "This is a legal notice.",
									"extendedDescription": "Extended description of the update.",
									"vulnerabilities": [
										{
											"id": 9668,
											"cve": "CVE-2021-1234",
											"cwe": "CWE-79",
											"description": "Description of the vulnerability.",
											"mitigatingFactor": "None",
											"note": "Note about the vulnerability.",
											"deleted": false
										}
									],
									"updateHistory": [
										{
											"id": 1,
											"description": "Initial release.",
											"date": "2023-10-17T16:05:10.351988",
											"importantUpdate": true
										}
									],
									"notificationType": "Security"
								}
							]
						}
					}
				]
			}
		}
	],
	"count": 1,
	"success": true,
	"message": "Successfully retreived assets."
}

export default Audits2;