"use client"

import React, { PropsWithChildren } from "react"
import { Icons } from '@/components/shadcn-ui/icons';
import { Button } from "../shadcn-ui/button"
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@radix-ui/react-collapsible"

interface CollapsibleDemoProps {}

const CollapsibleDemo: React.FC<PropsWithChildren<CollapsibleDemoProps>> = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(true);

  return (
    <Collapsible 
      open={isOpen}
      onOpenChange={setIsOpen}
      className={`py-4 scroll-m-20 text-2xl tracking-tight h-full w-auto overflow-y-auto space-y-2 transition-all duration-300 transform border-r-[1rem] border-dark-blue bg-${isOpen ? 'slate-200' : 'dark-blue'}`}>
      <div className="flex float-right items-center justify-between">
        <CollapsibleTrigger asChild>
          <Button className={`w-9 p-0 z-10 text-slate-600 ${isOpen ? '' : 'bg-accent text-accent-foreground'}`} variant="ghost">
          {isOpen ? <Icons.ChevronLeft className="h-5 w-5" /> : <Icons.ChevronRight className="h-5 w-5" />}
            <span className="sr-only">Toggle</span>
          </Button>
        </CollapsibleTrigger>
      </div>
      <CollapsibleContent className={`space-y-2 transition-all duration-300 transform ${isOpen ? 'translate-x-0 w-80' : 'translate-x-full w-0'}`}>
      {children} 
      </CollapsibleContent>
    </Collapsible>
  )
}

export default CollapsibleDemo;