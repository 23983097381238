import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { selectedCompanyIdSelector } from '@/state/hierarchy-tree';
import { filtersAtom } from '@/state/assets';
import { 
	getComplexFilter, 
	getFilter
} from '@/lib/filter-utils';
import { ComplexFilter } from '@/types/assets';


function useGetAllFilterItems () {
	const [filters, setFilters] = useRecoilState( filtersAtom );
	const companyId = useRecoilValue( selectedCompanyIdSelector );

	useEffect(() => {
		async function getMostFilterItems() {
			const deviceTypeFilters = await getFilter('deviceType');
			const equipmentTypeFilters = await getFilter('equipmentType');
			const lifecycleFilters = await getFilter('lifecycleStatus');
			const manufacturerFilters:ComplexFilter = await getComplexFilter('manufacturer');

			setFilters((prevState) => ({
				...prevState,
				deviceTypes: deviceTypeFilters,
				hwManufacturers: manufacturerFilters.hardware,
				swManufacturers: manufacturerFilters.software,
				usage: prevState.usage,
				lifecycleStatus: lifecycleFilters,
				equipmentType: equipmentTypeFilters,
			}));
		}

		getMostFilterItems();
		
	}, [
		setFilters
	]);

	useEffect( () => {
		const fetchMspFilters = async () => {
			const mspFilters:ComplexFilter = await getComplexFilter('msp', companyId);
			setFilters((prevState) => ({
				...prevState,
				hwMsps: mspFilters.hardware,
				swMsps: mspFilters.software,
			}));
		}
		if ( companyId ) {
			fetchMspFilters()
		}
	}, [companyId, setFilters] );

	return filters;
}

export default useGetAllFilterItems;