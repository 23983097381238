import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelectedPathText } from '@/lib/useSelectedPathText';
import { LeftSidebar } from '@/components/LeftSidebar';
import Modal from '@/components/ui/Modal';
import { Label } from '@/components/shadcn-ui/label';
import { Input } from '@/components/shadcn-ui/input';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/shadcn-ui/select';
import { Button } from '@/components/shadcn-ui/button';
import WhiteContainer from '@/components/ui/WhiteContainer';
import IngestionItem from '@/components/ui/IngestionItem';
import audits from '@/mocks/data/audits';
import SurveysList from './SurveysList';

interface AuditType {
	id?: string;
}

const AuditsList = () => {
	const { auditId } = useParams();
	const [isCreateAuditModalOpen, setIsCreateAuditModalOpen] = useState(false);
	const openModal = () => {
		setIsCreateAuditModalOpen(true);
	};
	const closeModal = () => {
		setIsCreateAuditModalOpen(false);
	};
	const [auditorsName, setAuditorsName] = useState('Gabriel van der Merwe');
	const [installation, setInstallation] = useState('ICT');
	const [downloadVersion, setDownloadVersion] = useState('000');

	const auditData = audits.data;
	let auditContent = (
		<div className="mt-2 text-content-grey">
			<p>No audits to display</p>
		</div>
	);
	if (auditData.length > 0) {
		auditContent = auditData.map((audit: AuditType) => {
			const auditIdObj = audit.id ? audit.id : '';
			return (
				<IngestionItem
					key={audit.id}
					itemId={auditIdObj}
					itemIdFromURL={auditId}
					itemLabel={`Audit ${auditIdObj}`}
					itemIsLink={true}
					linkType="external"
					linkLabel="Download tools"
					linkTarget="#"
					timeStamp={Date.now()} //To be modified
				/>
			);
		});
	}

	const selectedPathTitle = useSelectedPathText();

	return (
		<>
			<Modal
				isOpen={isCreateAuditModalOpen}
				onClose={closeModal}
			>
				<h3 className="my-3 pb-7 text-lg font-semibold tracking-wide text-content-grey">{'New audit for Gabriel van der Merwe on installation name'}</h3>
				<Label className="mb-2" htmlFor="auditName">
					Audit name
				</Label>
				<Input id="auditName" className="border-2 mb-4" />

				<Label className="mb-2">Auditor`s name</Label>
				<Select value={auditorsName} onValueChange={(value) => {setAuditorsName(value)}}>
					<SelectTrigger className="h-10 w-full mb-4">
						<SelectValue />
					</SelectTrigger>
					<SelectContent>
						<SelectItem key={'0'} value={`Gabriel van der Merwe`}>
							{'Gabriel van der Merwe'}
						</SelectItem>
						<SelectItem key={'1'} value={`001`}>
							{'001'}
						</SelectItem>
						<SelectItem key={'2'} value={`002`}>
							{'002'}
						</SelectItem>
						<SelectItem key={'3'} value={`003`}>
							{'003'}
						</SelectItem>
					</SelectContent>
				</Select>

				<Label className="mb-2">Installations</Label>
				<Select value={installation} onValueChange={(value) => {setInstallation(value)}}>
					<SelectTrigger className="h-10 w-full mb-4">
						<SelectValue />
					</SelectTrigger>
					<SelectContent>
						<SelectItem key={'0'} value={`ICT`}>
							{'ICT'}
						</SelectItem>
						<SelectItem key={'1'} value={`001`}>
							{'001'}
						</SelectItem>
						<SelectItem key={'2'} value={`002`}>
							{'002'}
						</SelectItem>
						<SelectItem key={'3'} value={`003`}>
							{'003'}
						</SelectItem>
					</SelectContent>
				</Select>

				<Label className="mb-2">Download version</Label>
				<Select value={downloadVersion} onValueChange={(value) => {setDownloadVersion(value)}}>
					<SelectTrigger className="h-10 w-full mb-4">
						<SelectValue />
					</SelectTrigger>
					<SelectContent>
						<SelectItem key={'0'} value={`000`}>
							{'000'}
						</SelectItem>
						<SelectItem key={'1'} value={`001`}>
							{'001'}
						</SelectItem>
						<SelectItem key={'2'} value={`002`}>
							{'002'}
						</SelectItem>
						<SelectItem key={'3'} value={`003`}>
							{'003'}
						</SelectItem>
					</SelectContent>
				</Select>
			</Modal>	
			<div className="flex flex-auto flex-row min-h-0 pr-4">
				<LeftSidebar />
				<div className="flex flex-1 bg-slate-200 p-4 h-full">
					<div className="max-h-[calc(100%-2rem)] w-full py-4">
						<h1 className="mb-6 text-blue capitalize text-2xl tracking-tight">{selectedPathTitle && `Ingestion / ${selectedPathTitle}`}</h1>
						<WhiteContainer styles="flex flex-row space-x-6 h-full box-border p-7">
							<WhiteContainer styles="flex flex-col w-1/3 overflow-y-auto h-auto h-auto min-h-[33vh] max-h-[calc(100%-.4rem)] box-border p-6 relative">
								<h2 className="sticky top-0 z-1 bg-white mt-1 mb-3 pb-7 text-lg font-semibold tracking-wide text-content-grey">{'Audits'}</h2>
								<div className="overflow-y-auto max-h-[80%]">
									{auditContent}
								</div>
								<Button
									variant="secondary"
									size="sm"
									className="w-30 ml-0 mt-4 absolute bottom-4 rounded bg-blue text-white text-xs py-1 w-30 tracking-wide font-normal hover:bg-grey hover:text-blue focus:bg-white focus:text-white"
									onClick={openModal}
								>
									{'Create audit'}
								</Button>
							</WhiteContainer>
							<SurveysList />
						</WhiteContainer>
					</div>
				</div>
			</div>
		</>
	);
}
export default AuditsList;