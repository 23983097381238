import React from 'react';
import ReactDOM from 'react-dom/client';
import '@/styles/globals.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {
	PublicClientApplication,
	EventType,
	EventMessage,
	AuthenticationResult,
} from "@azure/msal-browser";
import { msalConfig } from "@/middleware/authConfig";

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
	// Account selection logic is app dependent. Adjust as needed for different use cases.
	const accounts = msalInstance.getAllAccounts();
	console.log( 'accounts', accounts );
	if (accounts.length > 0) {
		msalInstance.setActiveAccount(accounts[0]);
	}

	msalInstance.addEventCallback((event: EventMessage) => {
		switch(event.eventType) {
			case EventType.LOGIN_SUCCESS:
			case EventType.SSO_SILENT_SUCCESS:
				if ( event.payload ) {
					const payload = event.payload as AuthenticationResult;
					const account = payload.account;
					msalInstance.setActiveAccount(account);
					console.log( 'event callback payload', payload );			
				}
			break;
		}
	});

	root.render(
	  	<React.StrictMode>
			<App pca={msalInstance} />
		</React.StrictMode>
	);
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
