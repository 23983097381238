import { Outlet } from 'react-router-dom';
import DashboardHeader from '@/components/DashboardHeader';
import { LeftSidebar } from '@/components/LeftSidebar';

const DashboardRoot = () => {
	return (
		<div className="bg-slate-200 h-screen flex flex-col overflow-hidden">
			<DashboardHeader />
			<div className="flex flex-auto flex-row min-h-0 pr-4">
					<LeftSidebar />
				<div className="flex flex-1 bg-slate-200 p-4 h-full overflow-hidden">
					<Outlet />
				</div>
			</div>
		</div>
	);
};

export default DashboardRoot;
