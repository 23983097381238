import { Card, CardContent } from '@/components/shadcn-ui/card';
import { ColumnDef } from '@tanstack/react-table';
import { DataTable } from '@/components/ui/DataTable';
import { HardwareAsset, HardwareAssetStructure, SoftwareAsset, SoftwareAssetStructure } from '@/middleware/GeneratedClient';
import { hwAssetType } from '@/types/assets';

const columnsHW: ColumnDef<HardwareAsset>[] = [
	{
		accessorKey: 'hardwareComponentStructure.deviceDescription',
		header: 'Component description',
	},
	{
		accessorKey: 'hardwareComponentStructure.manufacturerStructure.name',
		header: 'Manufacturer',
	},
	{
		accessorKey: 'hardwareComponentStructure.typeNumber',
		header: 'Type number',
	},
	{
		accessorKey: 'hardwareComponentStructure.deviceTypeStructure.name',
		header: 'Device type',
	},
	{
		accessorKey: 'hardwareComponentStructure.productLifeCycleStatusStructure.name',
		header: 'Lifecycle status',
	},
	{
		accessorKey: 'inUseOrSpare',
		header: 'Usage',
	},
	{
		accessorKey: 'mainSystemPart',
		header: 'MSP',
	},
];

const columnsSW: ColumnDef<SoftwareAsset>[] = [
	{
		accessorKey: 'softwareComponentStructure.description',
		header: 'Component description',
	},
	{
		accessorKey: 'softwareComponentStructure.manufacturer.name',
		header: 'Manufacturer',
	},
	{
		accessorKey: 'softwareComponentStructure.version',
		header: 'Version',
	},
	{
		accessorKey: 'equipmentTypeStructure.name',
		header: 'Equipment type',
	},
	{
		accessorKey: 'server.operatingSystem',
		header: 'Operating system',
	},	
	{
		accessorKey: 'runningOnPcName',
		header: 'Running On PC Name',
	},
	{
		accessorKey: 'softwareComponentStructure.productLifeCycle.name',
		header: 'Lifecycle status',
	},
	{
		accessorKey: 'mainSystemPart',
		header: 'MSP',
	},
];

interface AssetsTableProps {
	data: HardwareAssetStructure[]|SoftwareAssetStructure[]
	className?: string;
	type: string;
}

const AssetsTable = ({data, className, type} : AssetsTableProps ) => {
	return (
		<Card className="flex flex-col flex-grow overflow-hidden rounded-l-[0px] border-t-0 border-l-0 relative">
			<h2 className="p-5 pb-2 text-lg font-semibold tracking-wide text-content-grey">{'Assets'}</h2>
			<CardContent className={`flex flex-col flex-1 p-6 pr-3 pt-0 text-sm overflow-hidden ${className}`}>
				<DataTable columns={type === hwAssetType ? columnsHW : columnsSW} data={data} />
			</CardContent>
		</Card>
	);
};
export default AssetsTable;