import { LogOut, Settings, User } from 'lucide-react';

import {
	Avatar,
	AvatarFallback,
	AvatarImage,
} from '@/components/shadcn-ui/avatar';
import { Button } from '@/components/shadcn-ui/button';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuShortcut,
	DropdownMenuTrigger,
} from '@/components/shadcn-ui/dropdown-menu';
import { Icons } from '@/components/shadcn-ui/icons';
import { useNavigate } from 'react-router';
import { useResetRecoilState } from 'recoil';
import { selectedPathAtom } from '@/state/hierarchy-tree';
import auth from '@/middleware/auth';
import { useMsal } from '@azure/msal-react';

export function UserNav() {
	const navigate = useNavigate();
	const resetSelectedPath = useResetRecoilState(selectedPathAtom);	
	const { accounts } = useMsal();
	const username = accounts.length > 0 ?  (accounts[0].name ?? '') : '';
	
	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button
					variant="link"
					className="relative flex flex-col hover:text-grey"
				>
					<Avatar className="h-8 w-8 py-4 mt-4">
						<AvatarImage src="/avatars/01.png" alt="@shadcn" />
						<AvatarFallback>
							<Icons.User
								strokeWidth={1}
								size={36}
								height={32}
								color="#7DBDE8"
								fill="#7DBDE8"
							/>
						</AvatarFallback>
					</Avatar>
					<p className="text-xs text-grey font-thin transition-colors">
						ICT Group
					</p>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent className="w-56" align="end" forceMount>
				<DropdownMenuLabel className="font-normal">
					<div className="flex flex-col space-y-1">
						<p className="text-xs leading-none text-muted-foreground">
							{ username }
						</p>
					</div>
				</DropdownMenuLabel>
				<DropdownMenuSeparator />
				<DropdownMenuGroup>
					<DropdownMenuItem>
						<User className="mr-2 h-4 w-4" />
						<span>Profile</span>
						<DropdownMenuShortcut>⇧⌘P</DropdownMenuShortcut>
					</DropdownMenuItem>
					<DropdownMenuItem>
						<Settings className="mr-2 h-4 w-4" />
						<span>Settings</span>
						<DropdownMenuShortcut>⌘S</DropdownMenuShortcut>
					</DropdownMenuItem>
				</DropdownMenuGroup>
				<DropdownMenuSeparator />
				<DropdownMenuItem onClick={ () => {
					navigate('/ingestion/audit/47');
				}}>
					<span>Ingestion</span>
				</DropdownMenuItem>
				<DropdownMenuSeparator />
				<DropdownMenuItem onClick={ () => {
					resetSelectedPath();
					auth.signOut();
				}}>
					<LogOut className="mr-2 h-4 w-4" />
					<span>Log out</span>
					<DropdownMenuShortcut>⇧⌘Q</DropdownMenuShortcut>
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	);
}
